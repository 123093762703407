import i18next from "i18next";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import GeoserverBVSynthesis from "./geoserverBVSynthesis";

interface IDetailContainer {
  shownItem: any;
}

const GeoserverBVDetailContainer = (props: IDetailContainer) => {

  const { shownItem } = props;
  const { t } = useTranslation();
  const itemId = shownItem.id || 0;

  const handleSelect = (eventKey: string, test: any) => {
    ReactGA.event({
      action: `Voir onglet: ${eventKey} pour l'objet ${itemId} `,
      category: "Search",
    });
  };

  return (<>
    <div className={"siteIndustriel"}>
      <div className={"gaia-detail-result--header siteIndustriel-header"}>
        <h1 className="gaia-detail-result--header--primary-title primary-title">
          {/*{shownItem.id.replace(/_/g, " ")} - {shownItem.Nom_Rejet}*/}
          {shownItem.BvName}
        </h1>
        <h3 className="gaia-detail-result--header--secondary-title secondary-title">
          {t(`report:geoserverBV.sector`)} : {shownItem.Secteur}
        </h3>
      </div>
      <Tab.Container id="gaia-category" defaultActiveKey="synthesis">
        <Nav className="sz-aside__list gaia-detail-result--nav" onSelect={handleSelect}>
          <Nav.Item key={0} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"synthesis"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.synthesis`)}</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane key={0} eventKey={"synthesis"}>
            <GeoserverBVSynthesis shownItem={shownItem} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </>
  );
};

export default GeoserverBVDetailContainer;
