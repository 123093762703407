import React, { ReactElement, useState } from "react";
import { Accordion, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SzIcon } from "react-theme-components";
import DataNotReady from "../../Utils/messages/dataNotReady";
import "../detail.scss";

const SiteIndustrielSamplingPoint = (props: { shownItem: any, connections: any }): ReactElement => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("connection-0");

  if (props.connections) {
    return (
      <>
        {props.connections &&
          <Accordion defaultActiveKey={activeKey}>
            {props.connections.map((element: any, key: any) => {
              return (
                <>
                  <Card className="container-bordered">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={"connection-" + key}
                      onClick={() =>
                        activeKey === "connection-" + key ? setActiveKey("") : setActiveKey("connection-" + key)
                      }
                    >
                      {t("report:siteIndustriel.connectionID")} : {element.NOMEXPLO ? element.NOMEXPLO : ""}
                      {activeKey === "connection-" + key ?
                        <SzIcon variant="bold" icon="arrow-up-1" /> :
                        <SzIcon variant="bold" icon="arrow-down-1" />
                      }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={"connection-" + key}>

                      <Card.Body>
                        <Table size="sm">
                          <tbody>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.samplingPointAdress")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {element.ADRESSE ? element.ADRESSE : "Branchement sous maîtrise d’ouvrage tierce"}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.connectionNumber")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {element.NOMEXPLO ? element.NOMEXPLO : "Aucun branchement associé dans le SIG"}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.conformity.label")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {props.shownItem.conformity
                                    ? t(`report:siteIndustriel.conformity.${props.shownItem.conformity}`)
                                    : "La conformité n’est pas établie"}

                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              );
            })
            }
          </Accordion>
        }
      </>
    );
  }

  return <DataNotReady />;
};

export default SiteIndustrielSamplingPoint;
