import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./controlButtons.scss";

interface IControlButton {
  isDisable: boolean;
  onSubmit?: any;
  onReset?: (event: any) => void;
}
const ControlButton = (props: IControlButton) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="text-center gaia-control-buttons">
        <Col>
          <Button
            disabled={props.isDisable}
            type={"submit"}
            className={`gaia-control-button ${props.isDisable ? "disabled" : ""}`}>
            {t("filter")}
          </Button>
        </Col>
        <Col>
          <Button
            disabled={props.isDisable}
            type={"reset"}
            className={`gaia-control-button ${props.isDisable ? "disabled" : ""}`}>
            {t("reset")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ControlButton;
