import ReactGA from "react-ga";
import { config } from "../config";
import { API_ROUTES } from "../config/routes";
import {
  IFavFilter,
  IGeoserverBVFavFilter,
  IGeoserverBVFilter,
  IParcelFilter,
  IPluFilter,
  IProjectFavFilter,
  IProjectFilter,
  IReportFilter,
  ISignalFavFilter,
  ISignalFilter,
  ISiteIndustrielFilter,
} from "../store/redux/types";
import Request from "./Request";

export class Report {

  public static maxElasticResult: number = 5000;

  public static getGeoserverBVListPromise(colors: string[]) {
    let filterString: string = "";
    if (colors) {
      colors.forEach((color: string, index: any) => {
        if (index === 0) {
          filterString += `?colors[]=${color}`;
        } else {
          filterString += `&colors[]=${color}`;
        }
      });
    }
    const url = `${API_ROUTES.GEOSERVER_GET_BVS}${filterString}`;
    ReactGA.event({ action: `Geoserver BV: ${colors}`, category: "Geoserver" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, colors }));
  }

  public static getFilteredGeoserverBVListPromise(
    filters: IReportFilter,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    const url = `${API_ROUTES.GEOSERVER_GET_BVS}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Filtered Geoserver BV: ${filters}`, category: "BV Parcel" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getBvParcelListPromise(filters: any) {
    const filterString = this.getParcelFiltersString(filters);
    const url = `${API_ROUTES.GEOSERVER_GET_BVS}?${filterString}`;
    ReactGA.event({ action: `BV Parcel: ${filters}`, category: "BV Parcel" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, filters }));
  }

  public static getReportListPromise(
    filters: IReportFilter,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    const url = `${API_ROUTES.REPORT}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Report: ${filters}, ${itemsPerPage}, ${currentPage}`, category: "Report" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getProjectListPromise(
    filters: any,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    const url = `${API_ROUTES.PROJECT}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Report: ${filters}, ${itemsPerPage}, ${currentPage}`, category: "Peoject" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getSignalListPromise(
    filters: any,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    const url = `${API_ROUTES.SIGNAL}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Report: ${filters}, ${itemsPerPage}, ${currentPage}`, category: "Signal" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getSiteIndustrielListPromise(
    filters: any,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    const url = `${API_ROUTES.SITE_INDUS}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Report: ${filters}, ${itemsPerPage}, ${currentPage}`, category: "Site industriel" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getParcelListPromise(
    filters: any,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getParcelFiltersString(filters);
    const url = `${API_ROUTES.PARCEL}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Report: ${filters}, ${itemsPerPage}, ${currentPage}`, category: "Parcel" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getPluListPromise(
    filters: any,
    sortField: string,
    sortDirection: string,
    itemsPerPage?: number,
    currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getPluFiltersString(filters);
    const url = `${API_ROUTES.PLU}${queryString}${filterString}&sortField=${sortField}&sortDirection=${sortDirection}`;
    ReactGA.event({ action: `Report: ${filters}, ${itemsPerPage}, ${currentPage}`, category: "Plu" });
    ReactGA.pageview(url);
    return Request.get(url, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static getProject(projectId: string) {
    const url = `/project/${projectId}`;
    return Request.get(url, true)
      .then((response: { data: any }) => response.data);
  }

  public static getSignal(signalId: string) {
    const url = `/signal/${signalId}`;
    return Request.get(url, true)
      .then((response: { data: any }) => response.data);
  }

  public static getSiteIndustriel(siteIndustrielId: string) {
    const url = `/siteIndustriel/${siteIndustrielId}`;
    return Request.get(url, true)
      .then((response: { data: any }) => response.data);
  }

  public static getGeoserverBV(geoserverBVId: string) {
    const url = `/geoserverBV/${geoserverBVId}`;
    return Request.get(url, true)
      .then((response: { data: any }) => response.data);
  }

  public static getParcel(parcelId: string) {
    const url = `/parcel/${parcelId}`;
    return Request.get(url, true)
      .then((response: { data: any }) => response.data);
  }

  public static getPlu(pluId: string) {
    const url = `/plu/${pluId}`;
    return Request.get(url, true)
      .then((response: { data: any }) => response.data);
  }

  public static getReportAddressAutocomplete(address: string) {
    const url = `${API_ROUTES.REPORT_ADDRESS_AUTOCOMPLETE}?term=${address}`;
    return Request.get(url, true)
      .then((res: any) => res.data);
  }

  public static getProjectAddressAutocomplete(address: string) {
    const url = `${API_ROUTES.PROJECT_ADDRESS_AUTOCOMPLETE}?term=${address}`;
    return Request.get(url, true)
      .then((res: any) => res.data);
  }

  public static getSignalAddressAutocomplete(address: string) {
    const url = `${API_ROUTES.SIGNAL_ADDRESS_AUTOCOMPLETE}?term=${address}`;
    return Request.get(url, true)
      .then((res: any) => res.data);
  }

  public static getSiteIndustrielAddressAutocomplete(address: string) {
    const url = `${API_ROUTES.SITE_INDUS_ADDRESS_AUTOCOMPLETE}?term=${address}`;
    return Request.get(url, true)
      .then((res: any) => res.data);
  }

  public static getCSVExportURL(filters: IReportFilter, currentPage: number, itemsPerPage?: number) {
    itemsPerPage = "undefined" === typeof itemsPerPage ? 10 : itemsPerPage;
    const token = localStorage.getItem("tokenGaiaVision");
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    return `${config.apiUrl}${API_ROUTES.REPORT}${queryString}${filterString}&format=csv&token=${token}`;
  }

  public static getProjectCSVExportURL(filters: IProjectFilter, currentPage: number, itemsPerPage?: number) {
    itemsPerPage = "undefined" === typeof itemsPerPage ? 10 : itemsPerPage;
    const token = localStorage.getItem("tokenGaiaVision");
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    return `${config.apiUrl}${API_ROUTES.PROJECT}${queryString}${filterString}&format=csv&token=${token}`;
  }

  public static getSignalCSVExportURL(filters: ISignalFilter, currentPage: number, itemsPerPage?: number) {
    itemsPerPage = "undefined" === typeof itemsPerPage ? 10 : itemsPerPage;
    const token = localStorage.getItem("tokenGaiaVision");
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    return `${config.apiUrl}${API_ROUTES.SIGNAL}${queryString}${filterString}&format=csv&token=${token}`;
  }

  public static getSiteIndusCSVExportURL(filters: ISiteIndustrielFilter, currentPage: number, itemsPerPage?: number) {
    itemsPerPage = "undefined" === typeof itemsPerPage ? 10 : itemsPerPage;
    const token = localStorage.getItem("tokenGaiaVision");
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    return `${config.apiUrl}${API_ROUTES.SITE_INDUS}${queryString}${filterString}&format=csv&token=${token}`;
  }

  public static getGeoserverBVCSVExportURL(filters: IGeoserverBVFilter, currentPage: number, itemsPerPage?: number) {
    itemsPerPage = "undefined" === typeof itemsPerPage ? 10 : itemsPerPage;
    const token = localStorage.getItem("tokenGaiaVision");
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const filterString = this.getFiltersString(filters);
    return `${config.apiUrl}${API_ROUTES.GEOSERVER_GET_BVS}${queryString}${filterString}&format=csv&token=${token}`;
  }

  public static getFavFilters(email: string) {
    const url = `${API_ROUTES.FAV_FILTERS}${encodeURI(email)}`;
    ReactGA.event({ action: `Get favorite filters: ${email}`, category: "Report" });
    ReactGA.pageview(url);
    return Request.get(url, false, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static postFavFilter(filter: IFavFilter) {
    const url = `${API_ROUTES.FAV_FILTERS}`;
    ReactGA.event({ action: `Add favorite filters: ${filter}`, category: "Report filter" });
    ReactGA.pageview(url);
    return Request.post(url, filter);
  }

  public static updateFavFilter(email: string, newFav: number, deleted: number[]) {
    return Request.post(`${API_ROUTES.FAV_FILTERS_UPDATE}${email}`, { newFav, deleted });
  }

  public static getProjectFavFilters(email: string) {
    const url = `${API_ROUTES.PROJECT_FAV_FILTERS}${encodeURI(email)}`;
    ReactGA.event({ action: `Get favorite filters: ${email}`, category: "Project" });
    ReactGA.pageview(url);
    return Request.get(url, false, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static postProjectFavFilter(filter: IProjectFavFilter) {
    const url = `${API_ROUTES.PROJECT_FAV_FILTERS}`;
    ReactGA.event({ action: `Add favorite filters: ${filter}`, category: "Project filter" });
    ReactGA.pageview(url);
    return Request.post(url, filter);
  }

  public static updateProjectFavFilter(email: string, newFav: number, deleted: number[]) {
    return Request.post(`${API_ROUTES.PROJECT_FAV_FILTERS_UPDATE}${email}`, { newFav, deleted });
  }

  public static getSignalFavFilters(email: string) {
    const url = `${API_ROUTES.SIGNAL_FAV_FILTERS}${encodeURI(email)}`;
    ReactGA.event({ action: `Get favorite filters: ${email}`, category: "Signal" });
    ReactGA.pageview(url);
    return Request.get(url, false, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static postSignalFavFilter(filter: ISignalFavFilter) {
    const url = `${API_ROUTES.SIGNAL_FAV_FILTERS}`;
    ReactGA.event({ action: `Add favorite filters: ${filter}`, category: "Signal filter" });
    ReactGA.pageview(url);
    return Request.post(url, filter);
  }

  public static updateSignalFavFilter(email: string, newFav: number, deleted: number[]) {
    return Request.post(`${API_ROUTES.SIGNAL_FAV_FILTERS_UPDATE}${email}`, { newFav, deleted });
  }

  public static getSiteIndusFavFilters(email: string) {
    const url = `${API_ROUTES.SITE_INDUS_FAV_FILTERS}${encodeURI(email)}`;
    ReactGA.event({ action: `Get favorite filters: ${email}`, category: "Site industriel" });
    ReactGA.pageview(url);
    return Request.get(url, false, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static postSiteIndusFavFilter(filter: ISignalFavFilter) {
    const url = `${API_ROUTES.SITE_INDUS_FAV_FILTERS}`;
    ReactGA.event({ action: `Add favorite filters: ${filter}`, category: "Site industriel filter" });
    ReactGA.pageview(url);
    return Request.post(url, filter);
  }

  public static updateSiteIndusFavFilter(email: string, newFav: number, deleted: number[]) {
    return Request.post(`${API_ROUTES.SITE_INDUS_FAV_FILTERS_UPDATE}${email}`, { newFav, deleted });
  }

  public static getGeoserverBVFavFilters(email: string) {
    const url = `${API_ROUTES.GEOSERVER_BV_FAV_FILTERS}${encodeURI(email)}`;
    ReactGA.event({ action: `Get favorite filters: ${email}`, category: "GEOSERVER  BV" });
    ReactGA.pageview(url);
    return Request.get(url, false, false)
      .then((res: any) => ({ data: res.data, headers: res.headers }));
  }

  public static postGeoserverBVFavFilter(filter: IGeoserverBVFavFilter) {
    const url = `${API_ROUTES.GEOSERVER_BV_FAV_FILTERS}`;
    ReactGA.event({ action: `Add favorite filters: ${filter}`, category: "GEOSERVER  BV filter" });
    ReactGA.pageview(url);
    return Request.post(url, filter);
  }

  public static updateGeoserverBVFavFilter(email: string, newFav: number, deleted: number[]) {
    return Request.post(`${API_ROUTES.GEOSERVER_BV_FAV_FILTERS_UPDATE}${email}`, { newFav, deleted });
  }

  private static queryStringFromParams(itemsPerPage?: number, currentPage?: number): string {
    itemsPerPage = "undefined" !== typeof itemsPerPage ? itemsPerPage : -1;
    currentPage = "undefined" !== typeof currentPage ? currentPage : 0;

    const parameters = [
      `${itemsPerPage !== -1 ? "itemsPerPage=" + itemsPerPage : ""}`,
      `${currentPage !== 0 ? "currentPage=" + currentPage : ""}`,
    ];
    return "?" + parameters.reduce((accumulator: string, parameter: string) => {
      if (!accumulator || accumulator.length === 0) {
        return parameter;
      }
      return `${accumulator}&${parameter}`;
    });
  }

  private static getFiltersString(filters: IReportFilter) {
    let filterString: string = "";
    Object.entries(filters).forEach((item: any) => {
      if (Array.isArray(item[1])) {
        item[1].forEach((subItem: string) => {
          filterString += `&${item[0]}[]=${subItem}`;
        });
      } else {
        if (item[1] instanceof Date) {
          item[1] = item[1].toISOString();
        }
        if (item[1]) {
          filterString += `&${item[0]}=${item[1]}`;
        }
      }
    });
    return filterString;
  }

  private static getParcelFiltersString(filters: IParcelFilter) {
    let filterString: string = "";
    Object.entries(filters).forEach((item: any) => {
      if (item[1].length > 0) {
        item[1].forEach((subItem: any) => {
          let key = item[0];
          if ("rate" === key) { key = "parcels"; }
          filterString += `&${key}[]=${subItem}`;
        });
      }
    });
    return filterString;
  }

  private static getPluFiltersString(filters: IPluFilter) {
    let filterString: string = "";
    Object.entries(filters).forEach((item: any) => {
      if (item[1].length > 0) {
        item[1].forEach((subItem: any) => {
          const key = item[0];
          const value = subItem.replace("-", " ");
          filterString += `&${key}[]=${value}`;
        });
      }
    });
    return filterString;
  }
}
