import React, { useEffect, useState } from "react";
import "./toolbar.scss";

interface IToolBar {
    scale: number;
    onZoomIn: any;
    onZoomOut: any;
}

function Toolbar(props: IToolBar) {
  const [scale, setScale] = useState(0);

  useEffect(() => {
    setScale(props.scale);
  }, [props]);

  const zoomIn = (e: any) => {
    if (props.onZoomIn) {
      props.onZoomIn(e);
    }
  };
  const zoomOut = (e: any) => {
    if (props.onZoomOut) {
      props.onZoomOut(e);
    }
  };

  return (
    <div className="Toolbar">
      <button className="ZoomIn" onClick={zoomOut}>
        -
      </button>
      <button className="ZoomOut" onClick={zoomIn}>
        +
      </button>
      <div className="ZoomPercent">{(scale * 100).toFixed(1)}%</div>
    </div>
  );
}

export default Toolbar;
