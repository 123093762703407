import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzDropDown, SzHeader, SzIcon } from "react-theme-components";
import { bindActionCreators } from "redux";
import logo from "../../assets/img/logo-gaia-vision-blue-pastel.svg";
import userIcon from "../../assets/img/user-icon.png";
import { history } from "../../store/index";
import { UserActions } from "../../store/redux/actions";
import { menuItems } from "../Container";
import SearchForm from "../Forms/Search";
import "./header.scss";

interface IHeadersProps {
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  connected: boolean;
  email: string;
  logout: any;
  menuIsOpen: boolean;
  currentTab?: keyof typeof menuItems;
}

function Header(props: IHeadersProps) {
  const { connected, logout } = props;
  const { t } = useTranslation();

  const menuOpt = [
    {
      content: props.email,
    },
    {
      content: "PROFIL",
      handleClick: () => history.push("/profil"),
      icon: "people-man-1",
    },
    {
      content: "DÉCONNEXION",
      handleClick: () => logout(),
      icon: "logout-2",
    },
  ];

  const logoLink = (
    <a className="gaia-header__brand px-3" href="#!">
      <img src={logo} alt="Logo Suez" height={40} />
    </a>
  );

  const headerContent = () => {
    return (
      <div className={"gaia-header__search-form"}>
        <SearchForm />
      </div>
    );
  };

  const userMenuIcon = (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 100, hide: 200 }}
      overlay={
        <Tooltip id="tooltip">
          {t("account")}
        </Tooltip>
      }
    >
      <img src={userIcon} alt={"user icon"} height={30} />
    </OverlayTrigger>
  );

  const userMenu = (
    <SzDropDown image={userMenuIcon} alignRight>
      {menuOpt.map((item: any, index: number) => {
        return (
          <a
            href={item.link || "#"}
            className="border-bottom font-weight-bold justify-content-space-around d-flex align-items-center w-auto p-2 m-2"
            key={index}
            onClick={item.handleClick}
          >
            {item.icon && (
              <SzIcon
                icon={item.icon}
                variant="line"
                className="normal mr-2 align-self-center text-right"
              />
            )}
            {item.content}
          </a>
        );
      })}
    </SzDropDown>
  );

  if (!connected) {
    return (
      <>
        <SzHeader className="gaia-header d-flex align-items-stretch">
          {logoLink}
          <h1 className="gaia-header__brand-title px-3">GAIA Vision</h1>
        </SzHeader>
      </>
    );
  } else {
    return (
      <>
        <SzHeader bg="dark" className="gaia-header d-flex align-items-stretch">
          {logoLink}
          <h1 className="gaia-header__brand-title px-2">GAIA Vision</h1>
          {headerContent()}
          <div
            className="gaia-header signin d-flex align-items-center ml-auto
                  justify-content-around text-uppercase font-weight-bold"
          >
            {userMenu}
          </div>
        </SzHeader>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    email: state.user.email,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(UserActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
