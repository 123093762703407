import classnames from "classnames";
import React, { ReactNode, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SzSideMenu } from "react-theme-components";
import { config } from "../../config";
import { IError, IUser } from "../../store/redux/types";
import Header from "../Header";
import Loading from "../Utils/loading";
import ErrorAuthenticationMessage from "../Utils/messages/errorAuthenticationMessage";
import ErrorMessage from "../Utils/messages/errorMessage";
import PdfViewer from "../Utils/pdfViewer";
import "./container.scss";

interface IContainerProps {
  connected: boolean;
  children: ReactNode;
  error: IError;
  showResultDetail: boolean;
  showSearchResult: boolean;
  showSmartshape: boolean;
  loading: boolean;
  user: IUser;
}

export enum menuItems {
  home = "home",
  dashboard = "dashboard",
  indicator = "indicator",
  mapping = "mapping",
  report = "report",
  history = "history",
  favorite = "favorite",
  profil = "profil",
}

function Container(props: IContainerProps) {
  if (config.analyticsKey) {
    ReactGA.initialize(config.analyticsKey);
  }
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, changeTab] = useState(menuItems.home);
  const { children, connected, error, loading } = props;
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [documentLength, setDocumentLength] = useState(0);
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);

  const showPDFViewer = () => {
    setTitlePDF("Aide et documentation_Manuel utilisateur.pdf");
    setUriPDF(getDownloadUrl(props.user.userManualFileDownload));
    setPDFViewerIsActive(!PDFViewerIsActive);
    ReactGA.event({ action: `Pdf viewer: Aide et documentation_Manuel utilisateur.pdf`, category: "PDF" });
  };

  const getDownloadUrl = (downloadUrl: string) => {
    const token = localStorage.getItem("tokenGaiaVision");
    return downloadUrl + "?token=" + token;
  };
  const asideClasses = classnames("gaia-aside float-left  sz-aside-exit-done", {
    connected,
  });
  const containerClasses = classnames("gaia-container", {
    connected,
    isOpen: connected && isOpen,
  });

  useEffect(() => {
    switch (window.location.pathname) {
      case "/dashboard":
        changeTab(menuItems.dashboard);
        break;
      case "/history":
        changeTab(menuItems.history);
        break;
      case "/favorite":
        changeTab(menuItems.favorite);
        break;
      case "/report":
        changeTab(menuItems.report);
        break;
      case "/indicator":
        changeTab(menuItems.indicator);
        break;
      case "/":
      default:
        changeTab(menuItems.home);
        break;
    }
  }, [window.location.pathname]);

  const menu = [
    {
      className: "material-icons",
      icon: "search",
      link: "/",
      name: menuItems.mapping,
      title: t(menuItems.mapping),
    },
    {
      className: "material-icons",
      icon: "local_printshop",
      link: "/report",
      name: menuItems.report,
      title: t(menuItems.report),
    },
    {
      className: "material-icons",
      icon: "history",
      link: "/history",
      name: menuItems.history,
      title: t(menuItems.history),
    },
    // {
    //   className: "material-icons",
    //   icon: "star",
    //   link: "/favorite",
    //   name: menuItems.favorite,
    //   title: t(menuItems.favorite),
    // },
  ];

  const getOverlayTooltip = (itemTitle: string, menuIsOpen: boolean) => {
    if (!menuIsOpen) {
      return (
        <Tooltip id="tooltip-right">
          {itemTitle}
        </Tooltip>
      );
    }
    return <div />;
  };

  return (
    <>
      {(error.message && !error.jwtCheck) && <ErrorMessage show={true} />}
      {(error.message && error.jwtCheck) && <ErrorAuthenticationMessage show={true} />}
      <div className="gaia-header-container">
        <Header handleOnClick={() => setIsOpen(!isOpen)} menuIsOpen={isOpen} currentTab={currentTab} />
      </div>
      {connected && (
        <>
          {PDFViewerIsActive && (
            <PdfViewer
              title={titlePDF}
              uri={uriPDF}
              visible={PDFViewerIsActive}
              handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
              documentLength={documentLength ? documentLength : 0}
            />
          )}
          {loading && <Loading />}
          <SzSideMenu className={asideClasses} isOpen={isOpen}>
            <ul className="sz-aside__list list-unstyled m-0">
              {menu.map((item, key) => {
                return (
                  <OverlayTrigger
                    key={key}
                    placement="right"
                    delay={{ show: 100, hide: 200 }}
                    overlay={getOverlayTooltip(item.title, isOpen)}
                  >
                    <li key={key} className="d-flex align-items-center" onClick={() => changeTab(item.name)}>
                      <NavLink
                        activeClassName="active"
                        className="sz-aside__item d-flex align-items-center
                      font-weight-bold text-uppercase text-decoration-none py-2"
                        exact={true}
                        to={item.link}
                      >
                        <i className={`sz-aside__item--icon ${item.className}`}>{item.icon}</i>
                        <span className={"sz-aside__item--title"}>{item.title}</span>
                      </NavLink>
                    </li>
                  </OverlayTrigger>
                );
              })}
            </ul>
            <div className={"gaia-vision--version-container"}>
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 200 }}
                overlay={
                  <Tooltip id="tooltip-right">
                    {t("account:doc")}
                  </Tooltip>}
              >
                <i
                  className={`sz-aside__item--icon material-icons clickable`}
                  onClick={() => showPDFViewer()}
                >
                  help_outline
                </i>
              </OverlayTrigger>
            </div>
            <div className={"gaia-vision--version-container"}>
              <OverlayTrigger
                placement="right"
                delay={{ show: 100, hide: 200 }}
                overlay={
                  <Tooltip id="tooltip-right">
                    {t("version_control")}
                  </Tooltip>}
              >
                <span>{config.version}</span>
              </OverlayTrigger>
            </div>
          </SzSideMenu>
        </>
      )}
      <div className="gaia-main-container">
        <div className="no-padding container-content">
          <div className={containerClasses}>{children}</div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    error: state.error,
    loading: state.search.flag.loading,
    showResultDetail: state.search.flag.showResultDetail,
    showSearchResult: state.search.flag.showSearchResult,
    showSmartshape: state.search.flag.showSmartshape,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Container);
