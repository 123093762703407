import React, { useEffect, useState } from "react";
import { Trans, useTranslation  } from "react-i18next";
import { connect } from "react-redux";
import { SzModal } from "react-theme-components";
import { bindActionCreators } from "redux";
import { ErrorActions, SearchActions } from "../../../store/redux/actions";
import "./errorMessage.scss";

const ErrorMessage = (props: any) => {
  const { t } = useTranslation();
  const [show, isShow] = useState<any>();
  const { resetErrorMessage, initSearch } = props;

  useEffect(() => {
    isShow(props.show);
  }, [props]);

  const handleClose = () => {
    isShow(!show);
    resetErrorMessage();
    initSearch();
  };
  return (
    <>
      <SzModal title={t("error.title")} show={show} handleClose={handleClose}>
        <Trans i18nKey="error.text">
          Error, please contact administrator
        </Trans>
      </SzModal>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...ErrorActions, ...SearchActions}, dispatch),
});

export default connect(null, mapDispatchToProps)(ErrorMessage);
