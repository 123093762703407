import i18next from "i18next";
import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon } from "react-theme-components";
import { bindActionCreators } from "redux";
import { icons } from "../../../config";
import { SearchActions } from "../../../store/redux/actions";
import DataNotReady from "../../Utils/messages/dataNotReady";
import PdfViewer from "../../Utils/pdfViewer";
import "../detail.scss";

interface IDocumentsProps {
  documents: Array<{
    date: string,
    stream_name: string;
  }>;
  connectionDocuments: Array<{
    date: string,
    stream_name: string;
  }>;
}

const SiteIndustrielDocuments = (props: IDocumentsProps) => {

  console.log('props.documents',props.documents)
  console.log('props.connectionDocuments',props.connectionDocuments)


  const { t } = useTranslation();
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [documentLength, setDocumentLength] = useState(0);
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);
  const [documentOpen, setDocumentOpen] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState("");

  const showPDFViewer = (document: any) => {
    setTitlePDF(getDocumentTitle(document));
    setUriPDF(getDownloadUrl(document["gaia:downloadUrl"]));
    setPDFViewerIsActive(!PDFViewerIsActive);
    setDocumentLength(document["cmis:contentStreamLength"]);
    ReactGA.event({ action: `Pdf viewer: ${getDocumentTitle(document)}`, category: "PDF" });
  };

  const isPDF = (uri: string, mimeType: string): boolean => {
    return (
      !!getDownloadUrl(uri) &&
      mimeType === "application/pdf"
    );
  };
  const getDownloadUrl = (downloadUrl: string) => {
    const token = localStorage.getItem("tokenGaiaVision");
    return downloadUrl + "?token=" + token;
  };

  const handleClick = (document: any) => {
    if (isPDF(document["gaia:downloadUrl"], document["cmis:contentStreamMimeType"])) {
      showPDFViewer(document);
    } else {
      window.open(getDownloadUrl(document["gaia:downloadUrl"]), "_blank");
    }
  };

  const getFileIcon = (filename: string) => {
    const parts = filename.split(".");
    switch (parts[parts.length - 1]) {
      case "pdf":
        return icons["icon-pdf"];
      case "doc":
      case "docx":
        return icons["icon-doc"];
      case "mp4":
        return icons["icon-itv"];
      default:
        return icons["icon-file"];
    }
  };

  if (!props.documents || props.documents.length === 0) {
    return (<DataNotReady />);
  }

  const siteIndusDocument: {
    visit: any,
    regulation: any,
    couriers: any,
    control: any,
    selfMonitoring: any,
  } = {
    visit: [],
    regulation: [],
    couriers: [],
    control: [],
    selfMonitoring: [],
  };
  siteIndusDocument.visit = props.documents.filter((item: any) =>
    "Visites -- Bilans" === item["gv:typeDocumentIndustriel"],
  );
  siteIndusDocument.regulation = props.documents.filter((item: any) =>
    "Réglementation" === item["gv:typeDocumentIndustriel"],
  );
  siteIndusDocument.couriers = props.documents.filter((item: any) =>
    "Courriers" === item["gv:typeDocumentIndustriel"],
  );
  siteIndusDocument.control = props.documents.filter((item: any) =>
    "Contrôles inopinés" === item["gv:typeDocumentIndustriel"],
  );
  siteIndusDocument.selfMonitoring = props.documents.filter((item: any) =>
    "Autosurveillance" === item["gv:typeDocumentIndustriel"],
  );
  return (
    <>
      {PDFViewerIsActive && (
        <PdfViewer
          title={titlePDF}
          uri={uriPDF}
          visible={PDFViewerIsActive}
          handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
          documentLength={documentLength ? documentLength : 0}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Card className="container-bordered">
          <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setDocumentOpen(!documentOpen)}>
            {t("report:siteIndustriel.documents")}
            {documentOpen ?
              <SzIcon variant="bold" icon="arrow-up-1" /> :
              <SzIcon variant="bold" icon="arrow-down-1" />
            }
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {props.documents.length === 0 &&
                <DataNotReady />
              }
              {props.documents.length > 0 &&
                <>
                  {Object.entries(siteIndusDocument).map((value: any[], key: any) => {
                    return (
                      <Accordion defaultActiveKey={activeKey}>
                        <Card className="container-bordered">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={`${value[0]}`}
                            onClick={() => activeKey === value[0] ? setActiveKey("") : setActiveKey(value[0])}
                          >
                            {t(`report:siteIndustriel.documentType.${value[0]}`)}
                            {activeKey === value[0] ?
                              <SzIcon variant="bold" icon="arrow-up-1" /> :
                              <SzIcon variant="bold" icon="arrow-down-1" />
                            }
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={`${value[0]}`}>
                            <Card.Body as={Row}>
                              {value[1].length === 0 &&
                                <DataNotReady />
                              }
                              {value[1].map((doc: any, docKey: any) => {
                                return (
                                  <Col
                                    onClick={() => handleClick(doc)}
                                    sm="3"
                                    className="text-center gaia-detail-result--documents-container--doc"
                                  >
                                    <img
                                      className="gaia-detail-result--documents-container--doc-icon"
                                      alt=""
                                      src={getFileIcon(getDocumentTitle(doc))}
                                    />
                                    <span className="gaia-detail-result--documents-container--doc-title">
                                      {getDocumentTitle(doc)}
                                    </span>
                                  </Col>
                                );
                              })
                              }
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    );
                  })
                  }
                </>
              }
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export const getDocumentTitle =
  (document: { "cmis:name"?: string, "cmis:contentStreamFileName"?: string, "cm:title"?: string }): string => {
    if (document["cmis:name"] && document["cmis:name"].length > 0) {
      return document["cmis:name"];
    } else if (document["cmis:contentStreamFileName"] && document["cmis:contentStreamFileName"].length > 0) {
      return document["cmis:contentStreamFileName"];
    } else if (document["cm:title"] && document["cm:title"].length > 0) {
      return document["cm:title"];
    }
    return i18next.t("no-title-document");
  };

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(SearchActions, dispatch),
});

export default connect(null, mapDispatchToProps)(SiteIndustrielDocuments);
