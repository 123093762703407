import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IProject } from "../../../store/redux/types";
import "../detail.scss";

const ProjectPermitSynthesis = (props: { shownItem: IProject }): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Table size="sm">
        <tbody>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.detail.pcDate")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {new Date(props.shownItem.pcDate).toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.detail.pcBylaw")} :
            </td>
            <td className="gaia-td-align-left cell-width">

            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.detail.pcAuthorization")} :
            </td>
            <td className="gaia-td-align-left cell-width">

            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.detail.pcNumber")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.pcNumber}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ProjectPermitSynthesis;
