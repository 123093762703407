import React, {useState} from "react";
import {Accordion, Card, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {SzIcon} from "react-theme-components";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

interface IControl {
    "control:control_type": string;
    "control:dateOfVisit": string;
    "control:status_id": string;
    "control:status_date": string;
    "control:result": string;
    "control:controlStatus_id": string;
    "control:raccordementEuEp": string;
    "control:raccordementEpEu": string;
}

interface IIntervention {
    "libelleActe": string;
    "libelleFamilleIntervention": string;
    "libelleIntervention": string;
    "numeroIntervention": string;
    "dateFinIntervention": string;
    "numeroActeMetier": string;
    "dateDebutIntervention": string;
    "codeIntervention": string;
    "libelleEtatIntervention": string;
    "libelleFamilleActe": string;
    "applicationSource": string;
    "systemeExterne": string;
}

interface ISiteIndustriel {
    "branchements": any;
    "visites": any;
    "conformity": string;
    "ID_CD92": string;
}

interface ICurage {
    "vicr:id_curage": string;
    "vicr:baseprod": string;
    "vicr:dateclas": string;
    "vicr:masse": string;
}

interface INote {
    "vicr:etat": string;
    "vicr:note": string;
    "vicr:datecons": string;
    "vicr:ident": string;
}

const History = (
    props: {
        objectType: string;
        controls?: IControl[],
        interventions?: IIntervention[],
        siteIndustriels?: ISiteIndustriel[],
        curages?: ICurage[],
        notes?: INote[],
    },
) => {

    function compareControls(a: IControl, b: IControl) {
        return new Date(b["control:status_date"]).getTime() - new Date(a["control:status_date"]).getTime();
    }

    function compareInterventions(a: IIntervention, b: IIntervention) {
        return new Date(b.dateFinIntervention).getTime() - new Date(a.dateFinIntervention).getTime();
    }

    function compareCurages(a: ICurage, b: ICurage) {
        return new Date(b["vicr:dateclas"]).getTime() - new Date(a["vicr:dateclas"]).getTime();
    }

    function propertyDefaultMajor(a: IControl) {
        let defaultMajor: string | null = "Aucun";

        if (a["control:raccordementEuEp"] === "1") {
            defaultMajor = "EU dans EP";
        }

        if (a["control:raccordementEpEu"] === "1") {
            defaultMajor = "EP dans EU";
        }

        if (a["control:raccordementEuEp"] === "1" && a["control:raccordementEpEu"] === "1") {
            defaultMajor = "EU dans EP et EP dans EU";
        }

        return defaultMajor;
    }

    const getConformityStatus = (a: IControl) => {
        switch (a["control:control_type"]) {
            case "CONFORMITE":
                return t(`asset:conformity.${a["control:controlStatus_id"]}`);
                break;

            default:
                if (a["control:result"] && a["control:result"] === "1") {
                    return t(`asset:conformity.conforme`);
                } else {
                    return t(`asset:conformity.non-conforme`);
                }
                break;
        }
    };

    const [controlOpen, setControlOpen] = useState<boolean>(true);
    const [controlListOpen, setControlListOpen] = useState<boolean>(true);
    const [interventionOpen, setInterventionOpen] = useState<boolean>(true);
    const [interventionListOpen, setInterventionListOpen] = useState(-1);
    const [siteOpen, setSiteOpen] = useState<boolean>(true);
    const [siteListOpen, setSiteListOpen] = useState<boolean>(true);
    const {t} = useTranslation();
    const [curageOpen, setCurageOpen] = useState<boolean>(true);
    const [noteOpen, setNoteOpen] = useState<boolean>(true);

    const orderedControls: IControl[] = props.controls ? props.controls.sort(compareControls) : [];
    const orderedInterventions: IIntervention[] = props.interventions ?
        props.interventions.sort(compareInterventions) :
        [];

    const orderedCurges: ICurage[] = props.curages ?
        props.curages.sort(compareCurages) :
        [];
    let index = -1

    const objectTypeToSetCurageHistory: string[] = [
        "asset:troncon_de_reseau_-_collecteur",
        "asset:troncon_de_reseau_-_galerie",
        "asset:troncon_de_reseau_-_raccordement",
        "asset:raccordement_de_bouche_d_engouffrement",
        "asset:bouche_d_engouffrement",
        "asset:conduite_de_branchement",
        "asset:regard_de_branchement",
        "asset:chambre_a_sable",
        "asset:regard_de_visite",
        "asset:bassin_a_ciel_ouvert",
        "asset:deversoir_d_orage",
        "asset:barrage_a_poutrelles",
        "asset:seuil_fixe",
        "Vanne_non_asservie"
    ];

    const objectTypeToSetItvHistory: string[] = [
        "asset:troncon_de_reseau_-_collecteur",
        "asset:troncon_de_reseau_-_galerie",
        "asset:troncon_de_reseau_-_raccordement",
        "asset:regard_de_visite",
        "asset:conduite_de_branchement",
        "asset:bouche_d_engouffrement",
        "asset:deversoir_d_orage",
        "asset:barrage_a_poutrelles",
        "asset:seuil_fixe",
        "Vanne_non_asservie"
    ];
    if (props.controls || props.interventions || props.siteIndustriels || props.curages) {
        return (
            <>
                {props.controls &&
                    <Accordion defaultActiveKey="0">
                        <Card className="container-bordered">
                            <Accordion.Toggle as={Card.Header} eventKey="0"
                                              onClick={() => setControlOpen(!controlOpen)}>
                                {t("asset:history.lastVisit")}
                                {controlOpen ?
                                    <SzIcon variant="bold" icon="arrow-up-1"/> :
                                    <SzIcon variant="bold" icon="arrow-down-1"/>
                                }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    {orderedControls.length === 0 &&
                                        <DataNotReady/>
                                    }
                                    {orderedControls.length > 0 &&
                                        <Table size="sm">
                                            <tbody>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">{t("asset:history.lastRevisionDate")} :</td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {new Date(orderedControls[0]["control:dateOfVisit"]).toLocaleDateString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">{t("asset:history.lastRevisionType")} :</td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {orderedControls[0]["control:control_type"]}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">{t("asset:history.conformity")} :</td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {getConformityStatus(orderedControls[0])}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {t("asset:history.propertyDefaultMajor")} :
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {propertyDefaultMajor(orderedControls[0]) ?
                                                        propertyDefaultMajor(orderedControls[0]) :
                                                        t("no-data")
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">{t("asset:history.report")} :</td>
                                                <td className="gaia-td-align-left cell-width"><i
                                                    className="mdi mdi-file-outline"/></td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    }
                                    {orderedControls.length > 1 &&
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey="0"
                                                    onClick={() => setControlListOpen(!controlListOpen)}
                                                >
                                                    {t("asset:history.lastVisitList")}
                                                    {controlListOpen ?
                                                        <SzIcon variant="bold" icon="arrow-up-1"/> :
                                                        <SzIcon variant="bold" icon="arrow-down-1"/>
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <Table size="sm">
                                                            <thead>
                                                            <tr>
                                                                <th>{t("asset:history.revisionDate")}</th>
                                                                <th>{t("asset:history.revisionType")}</th>
                                                                <th>{t("asset:history.conformity")}</th>
                                                                <th>{t("asset:history.report")}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                orderedControls.map((e: IControl, key: number) => {
                                                                    if (0 !== key) {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td className="gaia-td-align-left">
                                                                                    {new Date(e["control:dateOfVisit"]).toLocaleDateString()}
                                                                                </td>
                                                                                <td className="gaia-td-align-left">{e["control:control_type"]}</td>
                                                                                <td className="gaia-td-align-left">
                                                                                    {getConformityStatus(e)}
                                                                                </td>
                                                                                <td className="gaia-td-align-right">
                                                                                    <i className="mdi mdi-file-outline"/>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return <></>;
                                                                })
                                                            }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }
                {props.interventions &&
                    <Accordion className="container-bordered" defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1"
                                              onClick={() => setInterventionOpen(!interventionOpen)}>
                                {t("asset:history.interventionList")}
                                {interventionOpen ?
                                    <SzIcon variant="bold" icon="arrow-up-1"/> :
                                    <SzIcon variant="bold" icon="arrow-down-1"/>
                                }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {orderedInterventions.length === 0 &&
                                        <DataNotReady/>
                                    }
                                    {orderedInterventions.length > 0 &&
                                        <Table size="sm">
                                            <tbody>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {t("asset:intervention.lastMaintenanceDate")} :
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {new Date(orderedInterventions[0].dateFinIntervention).toLocaleDateString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {t("asset:intervention.interventionId")} :
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {orderedInterventions[0].numeroIntervention}
                                                </td>
                                            </tr>
                                            {!objectTypeToSetItvHistory.includes(props.objectType) && !objectTypeToSetCurageHistory.includes(props.objectType) ? (
                                                <>
                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.applicationSource")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {orderedInterventions[0].applicationSource}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.systemeExterne")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {orderedInterventions[0].systemeExterne}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.interventionFamily")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {orderedInterventions[0].libelleFamilleIntervention ?
                                                                (orderedInterventions[0].libelleFamilleIntervention.includes("Erreur") ?
                                                                        t("no-data") :
                                                                        orderedInterventions[0].libelleFamilleIntervention
                                                                ) :
                                                                t("no-data")
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.interventionType")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {orderedInterventions[0].libelleIntervention ?
                                                                (orderedInterventions[0].libelleIntervention.includes("Erreur") ?
                                                                        t("no-data") :
                                                                        orderedInterventions[0].libelleIntervention
                                                                ) :
                                                                t("no-data")
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.interventionCode")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {orderedInterventions[0].codeIntervention}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.intervenant")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {t("no-data")}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="gaia-td-align-right cell-width">
                                                            {t("asset:intervention.actFamilyType")} :
                                                        </td>
                                                        <td className="gaia-td-align-left cell-width">
                                                            {orderedInterventions[0].libelleFamilleActe.includes("Erreur") ?
                                                                t("no-data") :
                                                                orderedInterventions[0].libelleFamilleActe
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                            ) : null}
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {t("asset:intervention.interventionDate")} :
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {new Date(orderedInterventions[0].dateDebutIntervention).toLocaleDateString()}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {t("asset:intervention.interventionState")} :
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {orderedInterventions[0].libelleEtatIntervention ?
                                                        (orderedInterventions[0].libelleEtatIntervention.includes("Erreur") ?
                                                                t("no-data") :
                                                                orderedInterventions[0].libelleEtatIntervention
                                                        ) :
                                                        t("no-data")
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {t("asset:intervention.actType")} :
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {orderedInterventions[0].libelleActe.includes("Erreur") ?
                                                        t("no-data") :
                                                        orderedInterventions[0].libelleActe
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="gaia-td-align-right cell-width">
                                                    {
                                                        orderedInterventions[0].numeroActeMetier &&
                                                        (
                                                            (orderedInterventions[0].numeroActeMetier.toLowerCase().includes("a-curage") ||
                                                                orderedInterventions[0].numeroActeMetier.toLowerCase().includes("curage"))
                                                                ? t("asset:intervention.IdCura")
                                                                : (orderedInterventions[0].numeroActeMetier.toLowerCase().includes("itv") ||
                                                                    orderedInterventions[0].numeroActeMetier.toLowerCase().includes("a-insptv"))
                                                                    ? t("asset:intervention.IdINSP")
                                                                    : t("asset:intervention.actNumber")
                                                        )
                                                    }
                                                </td>
                                                <td className="gaia-td-align-left cell-width">
                                                    {orderedInterventions[0].numeroActeMetier}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    }
                                    {
                                        orderedInterventions.length > 1 &&
                                        <Accordion defaultActiveKey="0">
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={`${index}`}
                                                    onClick={() => interventionListOpen === index ? setInterventionListOpen(-1) : setInterventionListOpen(index)}

                                                >
                                                    {t("asset:intervention.lastInterventionList")}
                                                    {interventionListOpen == index ?
                                                        <SzIcon variant="bold" icon="arrow-down-1"/> :
                                                        <SzIcon variant="bold" icon="arrow-up-1"/>
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${index}`}>
                                                    <Card.Body>
                                                        <Table size="sm" className="table-overflow">
                                                            <thead>
                                                            <tr>
                                                                <th>{t("asset:intervention.endInterventionDate")}</th>
                                                                { ! objectTypeToSetItvHistory.includes(props.objectType) && !objectTypeToSetCurageHistory.includes(props.objectType) ? (
                                                                    <>
                                                                        <th>{t("asset:intervention.interventionFamily")}</th>
                                                                        <th>{t("asset:intervention.interventionType")}</th>
                                                                        <th>{t("asset:intervention.applicationSource")}</th>
                                                                        <th>{t("asset:intervention.systemeExterne")}</th>
                                                                        <th>{t("asset:intervention.interventionCode")}</th>
                                                                    </>
                                                                ) : null}
                                                                <th>{t("asset:intervention.interventionId")}</th>
                                                                <th>{t("asset:intervention.actType")} </th>
                                                                <th>Identifiant</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                orderedInterventions.map((e: IIntervention, key: number) => {
                                                                    if (0 !== key) {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td className="gaia-td-align-left">
                                                                                    {new Date(e.dateFinIntervention).toLocaleDateString()}
                                                                                </td>
                                                                                { !objectTypeToSetItvHistory.includes(props.objectType) && !objectTypeToSetCurageHistory.includes(props.objectType) ? (
                                                                                    <>
                                                                                        <td className="gaia-td-align-left">
                                                                                            {e.libelleFamilleIntervention ?
                                                                                                (e.libelleFamilleIntervention.includes("Erreur") ?
                                                                                                        t("no-data") :
                                                                                                        e.libelleFamilleIntervention
                                                                                                ) :
                                                                                                t("no-data")
                                                                                            }
                                                                                        </td>
                                                                                        <td className="gaia-td-align-left">
                                                                                            {e.libelleIntervention ?
                                                                                                (e.libelleIntervention.includes("Erreur") ?
                                                                                                        t("no-data") :
                                                                                                        e.libelleIntervention
                                                                                                ) :
                                                                                                t("no-data")
                                                                                            }
                                                                                        </td>

                                                                                        <td className="gaia-td-align-right">
                                                                                            {e.applicationSource}
                                                                                        </td>
                                                                                        <td className="gaia-td-align-right">
                                                                                            {e.systemeExterne}
                                                                                        </td>
                                                                                        <td className="gaia-td-align-right">
                                                                                            {e.codeIntervention}
                                                                                        </td>
                                                                                    </>
                                                                                ) : null}
                                                                                <td className="gaia-td-align-right">
                                                                                    {e.numeroIntervention}
                                                                                </td>
                                                                                <td className="gaia-td-align-right">
                                                                                {e.libelleActe.includes("Erreur") ?
                                                                                    t("no-data") :
                                                                                    e.libelleActe
                                                                                }
                                                                                </td>
                                                                                <td className="gaia-td-align-right">
                                                                                    {e.numeroActeMetier}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                    return <></>;
                                                                })
                                                            }
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }
                {props.siteIndustriels && props.siteIndustriels.map((e: ISiteIndustriel, key: number) => {
                    return (
                        <Accordion defaultActiveKey="0">
                            <Card className="container-bordered">
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setSiteOpen(!siteOpen)}>
                                    {t("asset:siteIndustriel.lastVisit")} {e.ID_CD92}
                                    {siteOpen ?
                                        <SzIcon variant="bold" icon="arrow-up-1"/> :
                                        <SzIcon variant="bold" icon="arrow-down-1"/>
                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        {e.visites.length === 0 &&
                                            <DataNotReady/>
                                        }
                                        {e.visites.length > 0 &&
                                            <Table size="sm">
                                                <tbody>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">{t("asset:siteIndustriel.visitDate")} :</td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {new Date(e.visites[0].DATE_DEBUT).toLocaleDateString()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">{t("asset:siteIndustriel.sampling")} :</td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {e.visites[0].PRELEVEMENT === "O" ? "Oui" : "Non"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">{t("asset:siteIndustriel.conform")} :</td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {e.conformity}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">
                                                        {t("asset:siteIndustriel.samplingType")} :
                                                    </td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {e.visites[0].NATURE}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">
                                                        {t("asset:siteIndustriel.analyseNumber")} :
                                                    </td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {e.visites[0].NB_ANALYSE}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">
                                                        {t("asset:siteIndustriel.nonConfomityNumber")} :
                                                    </td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {e.visites[0].NB_NC}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="gaia-td-align-right cell-width">
                                                        {t("asset:siteIndustriel.samplingNumber")} :
                                                    </td>
                                                    <td className="gaia-td-align-left cell-width">
                                                        {e.visites[0].NB_PRELEVEMENT}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        }
                                        {e.visites.length > 1 &&
                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey="0"
                                                        onClick={() => setSiteListOpen(!siteListOpen)}
                                                    >
                                                        {t("asset:siteIndustriel.lastVisitList")} {e.ID_CD92}
                                                        {siteListOpen ?
                                                            <SzIcon variant="bold" icon="arrow-up-1"/> :
                                                            <SzIcon variant="bold" icon="arrow-down-1"/>
                                                        }
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <Table size="sm">
                                                                <thead>
                                                                <tr>
                                                                    <th>{t("asset:siteIndustriel.visitDate")}</th>
                                                                    <th>{t("asset:siteIndustriel.conform")}</th>
                                                                    <th>{t("asset:siteIndustriel.samplingType")}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    e.visites.map((v: any, visitKey: number) => {
                                                                        if (0 !== visitKey) {
                                                                            return (
                                                                                <tr key={visitKey}>
                                                                                    <td className="gaia-td-align-left">
                                                                                        {new Date(v.DATE_DEBUT).toLocaleDateString()}
                                                                                    </td>
                                                                                    <td className="gaia-td-align-left">{e.conformity}</td>
                                                                                    <td className="gaia-td-align-left">
                                                                                        {v.NATURE}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                        return <></>;
                                                                    })
                                                                }
                                                                </tbody>
                                                            </Table>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    );
                })}
                {props.curages && props.curages.length > 0 &&
                    <Accordion className="container-bordered" defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setCurageOpen(!curageOpen)}>

                                {
                                    props.objectType == "asset:chambre_a_sable" ?
                                        t("asset:history.curageList") :
                                        t("asset:history.curageListCompagne")
                                }

                                {curageOpen ?
                                    <SzIcon variant="bold" icon="arrow-up-1"/> :
                                    <SzIcon variant="bold" icon="arrow-down-1"/>
                                }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    {orderedCurges.length === 0 &&
                                        <DataNotReady/>
                                    }

                                    {orderedCurges.length > 0 &&
                                        <Table size="sm" className="table-overflow">
                                            <thead>
                                            <tr>
                                                <th>
                                                    {
                                                        props.objectType == "asset:chambre_a_sable" ?
                                                            t("asset:curage.idCurage") :
                                                            t("asset:curage.idCurageCompagne")
                                                    }
                                                </th>
                                                <th>{t("asset:curage.baseProd")}</th>
                                                <th>{t("asset:curage.DateClas")}</th>
                                                <th>
                                                    {
                                                        props.objectType == "asset:chambre_a_sable" ?
                                                            t("asset:curage.masse") :
                                                            t("asset:curage.masseCompagne")
                                                    }
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                orderedCurges.map((e: ICurage, key: number) => {
                                                    //if (0 !== key) {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="gaia-td-align-left">
                                                                {e["vicr:id_curage"] ?
                                                                    (e["vicr:id_curage"].includes("Erreur") ?
                                                                            t("no-data") :
                                                                            e["vicr:id_curage"]
                                                                    ) : '-'
                                                                }
                                                            </td>
                                                            <div className="fav-filters_cog-wheel">
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{show: 100, hide: 200}}
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            {e["vicr:baseprod"] === "ASN" ? t("Asnières") :
                                                                                e["vicr:baseprod"] === "CLM" ? t("Clamart") :
                                                                                    e["vicr:baseprod"] === "SRN" ? t("Suresnes") : ""}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                <span>
                                                    <td className="gaia-td-align-left">
                                                        {e["vicr:baseprod"] ?
                                                            (e["vicr:baseprod"].includes("Erreur") ?
                                                                    t("no-data") :
                                                                    e["vicr:baseprod"]
                                                            ) : '-'
                                                        }
                                                    </td>
                                                </span>
                                                                </OverlayTrigger>
                                                            </div>

                                                            <td className="gaia-td-align-left">
                                                                {new Date(e["vicr:dateclas"]).toLocaleDateString()}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {e["vicr:masse"] ?
                                                                    (e["vicr:masse"].includes("Erreur") ?
                                                                            t("no-data") :
                                                                            Number(e["vicr:masse"]) / 1000
                                                                    ) : '0'
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                    //}
                                                    //return <></>;
                                                })
                                            }
                                            </tbody>
                                        </Table>
                                    }
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }

                {props.notes && props.notes.length > 0 &&
                    <Accordion className="container-bordered" defaultActiveKey="1">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setNoteOpen(!noteOpen)}>
                                {t("asset:note.note")}
                                {noteOpen ?
                                    <SzIcon variant="bold" icon="arrow-up-1"/> :
                                    <SzIcon variant="bold" icon="arrow-down-1"/>
                                }
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <Table size="sm" className="table-overflow">
                                        <tbody>
                                        <tr>
                                            <td className="gaia-td-align-right cell-width">
                                                {t("asset:note.note")} :
                                            </td>
                                            <td className="gaia-td-align-left cell-width">
                                                {(() => {
                                                    const noteValue = props.notes[0]['vicr:note'];
                                                    let noteText = '';

                                                    switch (noteValue) {
                                                        case '0':
                                                            noteText = '0 (Non noté car non accessible)';
                                                            break;
                                                        case '1':
                                                            noteText = '1 (Très bon état)';
                                                            break;
                                                        case '2':
                                                            noteText = '2 (Bon état)';
                                                            break;
                                                        case '3':
                                                            noteText = '3 (Mauvais état)';
                                                            break;
                                                        case '4':
                                                            noteText = '4 (Très mauvais état)';
                                                            break;
                                                        default:
                                                            noteText = '5 (Valeur inconnue)';
                                                            break;
                                                    }

                                                    return noteText;
                                                })()}
                                            </td>

                                        </tr>
                                        <tr>
                                            <td className="gaia-td-align-right cell-width">
                                                {t("asset:note.datecons")} :
                                            </td>
                                            <td className="gaia-td-align-left cell-width">
                                                {new Date(props.notes[0]['vicr:datecons']).toLocaleDateString()}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }
            </>
        );
    }

    return <DataNotReady/>;
};

export default History;
