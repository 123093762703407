import i18next from "i18next";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { icons } from "../../../config";
import { SearchActions } from "../../../store/redux/actions";
import DataNotReady from "../../Utils/messages/dataNotReady";
import PdfViewer from "../../Utils/pdfViewer";
import "../detail.scss";

interface IAttachmentsProps {
  photoFile: any;
  stoppedFile: any;
  otherAttachments: any[];
}

const ProjectDocuments = (props: IAttachmentsProps) => {
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);

  const showPDFViewer = (document: any) => {
    setTitlePDF(getDocumentTitle(document));
    setUriPDF(getDownloadUrl(document));
    setPDFViewerIsActive(!PDFViewerIsActive);
    ReactGA.event({ action: `Pdf viewer: ${getDocumentTitle(document)}`, category: "PDF" });
  };

  const isPDF = (document: any, ext: string): boolean => {
    return (
      !!getDownloadUrl(document) &&
      ext === "pdf"
    );
  };

  const getDownloadUrl = (document: any) => {
    const filename = document.originalFileName;
    const token = localStorage.getItem("tokenGaiaVision");
    return document.downloadUrl + "?filename=" + filename + "&token=" + token;
  };

  const handleClick = (document: any) => {
    if (isPDF(document, getExt(document))) {
      showPDFViewer(document);
    } else {
      window.open(getDownloadUrl(document), "_blank");
    }
  };

  const getExt = (document: any) => {
    return document.originalFileName.split(".").pop();
  };

  const getFileIcon = (ext: string) => {
    switch (ext) {
      case "pdf":
        return icons["icon-pdf"];
      case "doc":
      case "docx":
        return icons["icon-doc"];
      case "mp4":
        return icons["icon-itv"];
      default:
        return icons["icon-file"];
    }
  };

  if ((!props.otherAttachments || props.otherAttachments.length === 0) && !props.photoFile && !props.stoppedFile) {
    return (<DataNotReady />);
  }

  return (
    <>
      {PDFViewerIsActive && (
        <PdfViewer
          title={titlePDF}
          uri={uriPDF}
          visible={PDFViewerIsActive}
          handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
          documentLength={0}
        />
      )}
      <Row className={"gaia-detail-result--documents-container"}>
        {props.photoFile && (
          <Col
            onClick={() => handleClick(props.photoFile)}
            sm="3"
            className="text-center gaia-detail-result--documents-container--doc"
          >
            <img
              className="gaia-detail-result--documents-container--doc-icon"
              alt=""
              src={getFileIcon(getExt(props.photoFile))}
            />
            <span className="gaia-detail-result--documents-container--doc-title">
              {getDocumentTitle(props.photoFile)}
            </span>
          </Col>
        )}
        {props.stoppedFile && (
          <Col
            onClick={() => handleClick(props.stoppedFile)}
            sm="3"
            className="text-center gaia-detail-result--documents-container--doc"
          >
            <img
              className="gaia-detail-result--documents-container--doc-icon"
              alt=""
              src={getFileIcon(getExt(props.stoppedFile))}
            />
            <span className="gaia-detail-result--documents-container--doc-title">
              {getDocumentTitle(props.stoppedFile)}
            </span>
          </Col>
        )}
        {props.otherAttachments.map((doc: any) => {
          return (
            <Col
              onClick={() => handleClick(doc)}
              sm="3"
              className="text-center gaia-detail-result--documents-container--doc"
            >
              <img
                className="gaia-detail-result--documents-container--doc-icon"
                alt=""
                src={getFileIcon(getExt(doc))}
              />
              <span className="gaia-detail-result--documents-container--doc-title">{getDocumentTitle(doc)}</span>
            </Col>
          );
        })}

      </Row>
    </>
  );
};

export const getDocumentTitle =
  (document: { "originalFileName"?: string }): string => {
    if (document.originalFileName && document.originalFileName.length > 0) {
      return document.originalFileName;
    }
    return i18next.t("no-title-document");
  };

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(SearchActions, dispatch),
});

export default connect(null, mapDispatchToProps)(ProjectDocuments);
