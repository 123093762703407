import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import "./detail.scss";

interface IDetailsTable {
  values: string[][];
}

const detailsTable = (props: IDetailsTable): ReactElement => {
  return (
    <>
      <Table size="sm">
        <tbody>
        {props.values.map((property, key) => {
          return (
            <tr key={key}>
              <td className="gaia-td-align-right cell-width">
                {property[0]} :
              </td>
              <td className="gaia-td-align-left cell-width">
                {property[1]}
              </td>
            </tr>
          );
        })}
        </tbody>
      </Table>
    </>
  );
};

export default detailsTable;
