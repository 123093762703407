import React, {ReactElement, useState} from "react";
import {Accordion, Card, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import "../detail.scss";
import {SzIcon} from "react-theme-components";

const SiteIndustrielSynthesis = (props: { shownItem: any }): ReactElement => {
    const {t} = useTranslation();
    const [DetailIndustrielOpen, setDetailIndustrielOpen] = useState<boolean>(true);
    const [SuiviIndustrielOpen, setSuiviIndustrielOpen] = useState<boolean>(true);

    return (
        <>

            <Accordion defaultActiveKey="0">
                <Card className="container-bordered">
                    <Accordion.Toggle as={Card.Header} eventKey="0"
                                      onClick={() => setDetailIndustrielOpen(!DetailIndustrielOpen)}>
                        {t(`report:siteIndustriel.synthesis.Details`)}
                        {DetailIndustrielOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1"/> :
                            <SzIcon variant="bold" icon="arrow-down-1"/>
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table size="sm">
                                <tbody>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t("report:siteIndustriel.address")} :
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.ADRESSE}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t("report:siteIndustriel.idCD92")} :
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.ID_CD92}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t("report:siteIndustriel.domain")} :
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.domaine.length > 0 ? props.shownItem.domaine[0].ACTIVITE_CD92 : t("no-data")}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>


            <Accordion defaultActiveKey="0">
                <Card className="container-bordered">
                    <Accordion.Toggle as={Card.Header} eventKey="0"
                                      onClick={() => setSuiviIndustrielOpen(!SuiviIndustrielOpen)}>
                        {t(`report:siteIndustriel.synthesis.Suivi`)}
                        {SuiviIndustrielOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1"/> :
                            <SzIcon variant="bold" icon="arrow-down-1"/>
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table size="sm">
                                <tbody>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t(`report:siteIndustriel.synthesis.SuiviDetail.charge`)} :
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.CHARGE_SUIVI ?? t("no-data")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t(`report:siteIndustriel.synthesis.SuiviDetail.DateDebut`)}:
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.DATE_DEBUT ? new Date(props.shownItem.DATE_DEBUT).toLocaleDateString() : t("no-data")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t(`report:siteIndustriel.synthesis.SuiviDetail.DateFin`)} :
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.DATE_FIN_SUIVI ? new Date(props.shownItem.DATE_FIN_SUIVI).toLocaleDateString() : t("no-data")}

                                    </td>
                                </tr>
                                <tr>
                                    <td className="gaia-td-align-right cell-width">
                                        {t(`report:siteIndustriel.synthesis.SuiviDetail.Cause`)} :
                                    </td>
                                    <td className="gaia-td-align-left cell-width">
                                        {props.shownItem.CAUSE_FIN_SUIVI ?? t("no-data")}

                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>


        </>
    );
};

export default SiteIndustrielSynthesis;
