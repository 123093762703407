import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SearchActions } from "../../../store/redux/actions";
import { IFilter, ISearch } from "../../../store/redux/types";
import Suggestions, { suggestionType } from "./suggestions";
import "./suggestions.scss";

interface ISuggestions {
  getSuggestionHistory: (itemsPerPage?: number, currentPage?: number) => void;
  getSearch: (search: ISearch, filters?: IFilter[]) => any;
  getSigObjectByIdent: (ident: string) => any;
  quantity: number;
  search: ISearch;
  searchHistory: Array<{ keywords: string, date: Date }>;
  hide: () => void;
}

const HistorySuggestion = (props: ISuggestions): ReactElement => {

  const {t} = useTranslation();

  const [suggestions, setSuggestions] = useState<Array<{keywords: string, date: Date}>>([]);

  useEffect(() => {
    props.getSuggestionHistory(props.quantity, 1);
  }, []);

  useEffect(() => {
    setSuggestions(props.searchHistory);
  }, [props.searchHistory]);

  return <Suggestions
    title={t("recentSearches")}
    type={suggestionType.HISTORY}
    hide={props.hide}
    loading={false}
    icon={"search"}
    suggestions={suggestions}
  />;
};

const mapStateToProps = (state: { search: ISearch }) => {
  return {
    search: state.search,
    searchHistory: state.search.history.suggestionSearches,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...SearchActions}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HistorySuggestion);
