import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import breadcrumbReducer from "./breadcrumb";
import errorReducer from "./errors";
import filtersReducers from "./filters";
import reportReducer from "./report";
import searchReducer from "./search";
import userReducer from "./user";

const rootReducer = (history: History) =>
  combineReducers({
    breadcrumb: breadcrumbReducer,
    error: errorReducer,
    filters: filtersReducers,
    report: reportReducer,
    router: connectRouter(history),
    search: searchReducer,
    user: userReducer,
  });

export default rootReducer;
