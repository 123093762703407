import React from "react";
import { useTranslation } from "react-i18next";
import "./errorMessage.scss";

const NoResults = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="no-suggestions text-primary">
            <em>{t("no-results-found")}</em>
     </div>
    </>
  );
};

export default NoResults;
