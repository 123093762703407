import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ReportActions } from "../../../store/redux/actions";
import { IReportData } from "../../../store/redux/types";

function PluListHeader(props: any) {
  const { t } = useTranslation();

  return (
    <>
      <div className={"report--menu--tabs_list--header_buttons_noflex"}>
        <Button
          onClick={props.resetAction}
          disabled={props.areFiltersEmpty}
          className={`report--menu--tabs_list--button ${props.areFiltersEmpty ? "disabled" : ""}`}
        >
          {t("report:reset")}
        </Button>
        <Button
          onClick={props.filterAction}
          disabled={props.areFiltersEmpty}
          className={`report--menu--tabs_list--button ${props.areFiltersEmpty ? "disabled" : ""}`}
        >
          {t("report:filter")}
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    pluCurrentPage: state.report.pluCurrentPage,
    pluFilters: state.report.pluFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PluListHeader);
