import i18next from "i18next";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import Documents from "./documents";
import ProjectDocuments from "./projectDocuments";
import ProjectPermitSynthesis from "./projectPermitSynthesis";
import ProjectSynthesis from "./projectSynthesis";

interface IDetailContainer {
  shownItem: any;
}

const ProjectDetailContainer = (props: IDetailContainer) => {

  const { shownItem } = props;
  const { t } = useTranslation();
  const itemId = shownItem.id || 0;

  const handleSelect = (eventKey: string, test: any) => {
    ReactGA.event({
      action: `Voir onglet: ${eventKey} pour l'objet ${itemId} `,
      category: "Search",
    });
  };

  return (<>
    <div className={"project"}>
      <div className={"gaia-detail-result--header project-header"}>
        <h1 className="gaia-detail-result--header--primary-title primary-title">
          {shownItem.name}
        </h1>
        <h3 className="gaia-detail-result--header--secondary-title secondary-title">
          {shownItem.address}
        </h3>
      </div>
      <Tab.Container id="gaia-category" defaultActiveKey="synthesis">
        <Nav className="sz-aside__list gaia-detail-result--nav" onSelect={handleSelect}>
          <Nav.Item key={0} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"synthesis"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.synthesis`)}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item key={0} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"permit"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.permit`)}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item key={0} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"documents"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.documments`)}</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane key={0} eventKey={"synthesis"}>
            <ProjectSynthesis shownItem={shownItem} />
          </Tab.Pane>
          <Tab.Pane key={1} eventKey={"permit"}>
            <ProjectPermitSynthesis shownItem={shownItem} />
          </Tab.Pane>
          <Tab.Pane key={2} eventKey={"documents"}>
            <ProjectDocuments
              photoFile={shownItem.photoFile}
              stoppedFile={shownItem.photoFile}
              otherAttachments={shownItem.otherAttachments}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </>
  );
};

export default ProjectDetailContainer;
