import React, { useEffect, useState} from "react";
import { connect } from "react-redux";
import { SzButton } from "react-theme-components";
import { bindActionCreators } from "redux";
import { smartshapeConfig } from "../../config/";
import { Smartshape as SmartshapeService } from "../../services/Smartshape";
import { SearchActions } from "../../store/redux/actions";
import "./smartshape.scss";
import { Token as TokenService } from "../../services/Token";
function Smartshape(props: any) {

    const [iframeKey, setIframeKey] = useState(0);

/*    useEffect(() => {
        if (props.shownDetailItem["3dstrucuture:3d_ifc_available"] && !props.showResultDetail) {
            const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
            window.addEventListener("message", smartShapeMessageHandler, false);
            setTimeout(() => {
                if (iframe.contentWindow) {
                    iframe.contentWindow.postMessage(
                        "",
                        smartshapeConfig.host ? smartshapeConfig.host : "",
                    );
                }
            }, 12000);
        } else {
            window.removeEventListener("message", smartShapeMessageHandler);
        }
        return () => {
            window.removeEventListener("message", smartShapeMessageHandler);
        };
    }, [props.showResultDetail]);

    const smartShapeMessageHandler = (event: any) => {
        if (!event || !event.data || props.showResultDetail) {
            return;
        }
        const message = JSON.parse(JSON.stringify(event.data));
        if (message[0] === "selectedNodes") {
            if (message[1]) {
                const selectedNodes = message[1].split(";");
                return SmartshapeService.getNodesById(props.shownDetailItem["3dstrucuture:3d_ifc_list"][0]["3dstrucuture:3d_ifc_file_id"], selectedNodes[0])
                    .then((response: any) => {
                        if (response.attributes) {
                            const result = response.attributes.attributes.filter(
                                (attribute: any) => attribute.key === "CodEquip",
                            );
                            if (result[0]) {
                                props.displayEquipementFromSmartshapePlayer(result[0].value);

                            }
                        }
                    });
            }
        }
    };*/
    const handleClick = () => {
        props.displaySmartshapeFullScreen(!props.isDisplayed);
    };
    const getIdSharedFolder = () => {
        let idSharedFolder = "false";


        if (props.shownDetailItem["3dstrucuture:3d_ifc_available"] && props.shown3dObjectOnSmartshapeIframe)
            idSharedFolder = props.shown3dObjectOnSmartshapeIframe["3dstrucuture:3d_ifc_shared_folder_id"];


        else if (props.shownDetailItem["3dnetwork:3d_mesh_available"] && props.shown3dObjectOnSmartshapeIframe)
            idSharedFolder = props.shown3dObjectOnSmartshapeIframe["3dnetwork:3d_mesh_shared_folder_id"];


        return idSharedFolder
    };
    const getIframeUrl = () => {
        let iframeUrl = "false";

        if (props.shownDetailItem["3dstrucuture:3d_ifc_available"] && props.shown3dObjectOnSmartshapeIframe)
            iframeUrl = props.shown3dObjectOnSmartshapeIframe["3dstrucuture:3d_ifc_file_path"];


        else if (props.shownDetailItem["3dnetwork:3d_mesh_available"] && props.shown3dObjectOnSmartshapeIframe)
            iframeUrl = props.shown3dObjectOnSmartshapeIframe["3dnetwork:3d_mesh_file_path"];


        return iframeUrl
    };

    const deleteSmartshapeSession= async (jsonwebtoken: string,xSmartShapeSession: string)=>{


        if (jsonwebtoken && jsonwebtoken != "" && xSmartShapeSession && xSmartShapeSession != "") {

            const responseDeleteSession = await SmartshapeService.deleteSmartshapeSession(jsonwebtoken, xSmartShapeSession);
            const responselogout = await SmartshapeService.logoutSmartshape();

            console.log('responseDeleteSession', responseDeleteSession)
            console.log('responselogout', responselogout)

            return responseDeleteSession
        }
        else {
            console.log("cannot delete smartshape session")
        }
        return []
    }

/*    const updateSmartshapeSession =async (jsonwebtoken : string)=>{

        if (jsonwebtoken && jsonwebtoken != "") {
            //first create session, at this stage the XSmartShapeSession is not our
            // it is the same as the one in cookies smarthsape, and  we can't use it to delete session
            console.log("first create session")
            await  SmartshapeService.setXSmartShapeSession(jsonwebtoken)

            //second update session to be able to update the XSmartShapeSession and manipulate it
            setTimeout(async function() {
                console.log("second update session")
                await SmartshapeService.setXSmartShapeSession(jsonwebtoken)
            }, 1000);


        }

        else console.log("cannot update smartshape session")
    }*/

    const logoutSmartshape = async  () => {

        const jsonwebtoken = TokenService.getCookie("jsonwebtoken") ?? "";
        const XSmartShapeSession = TokenService.getCookie("X-SmartShape-Session") ?? "";

        console.log('jsonwebtoken',jsonwebtoken)
        console.log('XSmartShapeSession',XSmartShapeSession)

        //await updateSmartshapeSession(jsonwebtoken);

        //setTimeout(async function() {
            await deleteSmartshapeSession(jsonwebtoken,XSmartShapeSession);
        //}, 3000);

        setIframeKey((prevKey) => prevKey + 1);

        //close iframe
        props.displaySmartshape({showSmartshape:false});
    };


    useEffect(() => {
        setIframeKey((prevKey) => prevKey + 1);
    }, [props.showSmartshape]);

    return (
        <>
            <SzButton className="btn-fullscreen"
                onClick={handleClick}
                type="button">
                <i className="material-icons icon-resize">
                    {props.isDisplayed ? "fullscreen_exit" : "fullscreen"}
                </i>
            </SzButton>
            <SzButton className="btn-logout"
                      onClick={logoutSmartshape}
                      type="button">
                <i className="material-icons icon-resize">
                    close
                </i>
            </SzButton>
            <iframe
                key={iframeKey}
                id="smartshape"
                title="Test smartshape"
                width="100%"
                height="100%"
                src={smartshapeConfig.host + getIdSharedFolder() + getIframeUrl() + "?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1#autoplay=true"}
                allow="autoplay"
                allowFullScreen={true}
                frameBorder="0"
            >
            </iframe>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        isDisplayed: state.search.isDisplayed,
        showResultDetail: state.search.flag.showResultDetail,
        shown3dObjectOnSmartshapeIframe: state.search.detail.shown3dObjectOnSmartshapeIframe,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(SearchActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Smartshape);
