
export enum conformity {
  conform = "conforme",
  notConform = "non-conforme",
  notConformWithoutMalfunction = "non-conforme-sans-dysfonctionnement",
  unknown = "a-determiner",
}

export enum nonConformOther {
  none = "none",
}

export enum thematicMapConformity {
  conform = "conforme",
  unknown = "a-determiner",
}

export enum projectStatuses {
  Confirmed = "Confirmé",
  Possible = "Suspecté",
  Construction = "Travaux",
}

export enum signalStatuses {
  new = "new",
  approved = "approved",
  rejected = "rejected",
  closed = "closed",
}

export enum siteDomains {
  AGRO_ALIMENTAIRE = "AGROALIMENTAIRE",
  BETON = "BETON",
  BLANCHISSERIE = "BLANCHISSERIE",
  CHIMIE_ET_DIVERS = "CHIMIE ET DIVERS",
  DECHETS = "DECHETS",
  HOPITAL = "HOPITAL",
  TRAITEMENT_DE_SURFACE = "TRAITEMENT DE SURFACE",
  TRANSPORTS = "TRANSPORT",
  GEOTHERMIE = "GEOTHERMIE",
}

export enum siteConformities {
  "Conforme" = "Conforme",
  "Non Conforme" = "Non Conforme",
  "Non concerné" = "Non concerné",
  "Non renseigné" = "none",
}

export enum signalOrigins {
  SEVESC = "SEVESC",
  other = "other",
}

export enum signalCategories {
  SIGNAL_ON_FREE = "SIGNAL_ON_FREE",
  SIGNAL_ON_HARMFUL = "SIGNAL_ON_HARMFUL",
  SIGNAL_ON_NOISE = "SIGNAL_ON_NOISE",
  SIGNAL_ON_ODOUR = "SIGNAL_ON_ODOUR",
  SIGNAL_ON_OVERFLOW = "SIGNAL_ON_OVERFLOW",
  SIGNAL_ON_REJECTION = "SIGNAL_ON_REJECTION",
  SIGNAL_ON_ROAD = "SIGNAL_ON_ROAD",
  SIGNAL_ON_WORK = "SIGNAL_ON_WORK",
}

export enum conformityCheckType {
  initVisit = "init-visit",
  cons = "cons-visit",
  visitAdvice = "visit-advice",
  null = "",
}

export enum currentState {
  validated = "validated",
  generatedMail = "generatedMail",
  closed = "closed",
}

export enum majorFaultType {
  epeu = "epeu",
  euep = "euep",
  euep_epeu = "euep_epeu",
}

export enum thematicMapMajorFaultType {
  epeu = "epeu",
  euep = "euep",
  euep_epeu = "euep_epeu",
}

export enum thematicMapGeoserverBVsColors {
  nonConforme_greater_20 = "rouge",
  nonConforme_greater_10 = "jaune",
  nonConforme_greater_5 = "bleu",
  nonConforme_less_5 = "vert",
}

export enum propertyType {
  pavillon = "pavillon",
  appartement = "appartement",
  immeuble = "immeuble",
  residence = "residence",
  garage = "garage",
  restaurant = "restaurant",
  zac = "zac",
  voirie = "voirie",
  autres = "autres",
}

export enum networkOperatingSector {
  Zone_Sud_Exploitation = "Zone_Sud_Exploitation",
  Zone_Nord_Exploitation = "Zone_Nord_Exploitation",
  Zone_Nord_Exploitation_Zone_Sud_Exploitation = "Zone_Nord_Exploitation;Zone_Sud_Exploitation",
}

export enum listType {
  project = "projectsReport",
  control = "connectionsReport",
  signal = "signalsReport",
  siteIndustriel = "siteIndustrielsReport",
  parcel = "parcelReport",
  plu = "pluReport",
  curage = "curageReport",
}

export enum emOperatingSector {
  Secteur_Sud_Est = "Secteur_Sud_Est",
  Secteur_Electromecanique_Nordouest = "Secteur_Electromecanique_Nordouest",
  Secteur_Electromecanique_Nordouest_Secteur_Sud_Est = "Secteur_Electromecanique_Nordouest;Secteur_Sud_Est",
}

export enum veConformity {
  conform = "1",
  notConform = "0",
}

export enum vptConformity {
  conform = "1",
  notConform = "0",
}

export enum controlType {
  conformite = "CONFORMITE",
  ve = "VE",
  vpt = "VPT",
}

export enum parcelType {
  disconnected = "DECONNECTE",
  regulated = "REGULE",
  unknown = "INCONNU",
}

export enum pluCriteria {
  favorable = "Favorable",
  defavorable = "Défavorable",
  inexistant = "Inexistant",
}

export enum parcelConformiteBVsColors {
  greater_5 = "greater-than-5",
  between_1_and_5 = "between-1-and-5",
  less_1 = "less-than-1",
}

export enum bvSectors {
  Nord1 = "Nord1",
  Nord2 = "Nord2",
  Centre = "Centre",
  Rueil = "Rueil",
  Sud = "Sud",
}

export enum bvCollectors {
  SAN = "SAN",
  CAA = "CAA",
  DES2B = "DES2B",
  SAR = "SAR",
  CAB = "CAB",
  CLI = "CLI",
  ES1B = "ES1B",
  MAR = "MAR",
  NL = "NL",
  ANT = "ANT",
  BLR = "BLR",
  SUR = "SUR",
  NE = "NE",
  ASN = "ASN",
  LAS = "LAS",
}

export enum cities {
  "antony" = "Antony",
  "arcueil" = "Arcueil",
  "asnieres-sur-seine" = "Asnières-sur-Seine",
  "bagneux" = "Bagneux",
  "bois-colombes" = "Bois-Colombes",
  "boulogne-billancourt" = "Boulogne-Billancourt",
  "bourg-la-reine" = "Bourg-la-Reine",
  "cachan" = "Cachan",
  "chatenay-malabry" = "Châtenay-Malabry",
  "chatillon" = "Châtillon",
  "chaville" = "Chaville",
  "clamart" = "Clamart",
  "clichy" = "Clichy",
  "clichy-la-garenne" = "Clichy-la-Garenne",
  "colombes" = "Colombes",
  "courbevoie" = "Courbevoie",
  "fontenay-aux-roses" = "Fontenay-aux-Roses",
  "fresnes" = "Fresnes",
  "garches" = "Garches",
  "gennevilliers" = "Gennevilliers",
  "issy-les-moulineaux" = "Issy-les-Moulineaux",
  "la garenne-colombes" = "La Garenne-Colombes",
  "le plessis-robinson" = "Le Plessis-Robinson",
  "levallois-perret" = "Levallois-Perret",
  "malakoff" = "Malakoff",
  "marnes-la-coquette" = "Marnes-la-Coquette",
  "massy" = "Massy",
  "meudon" = "Meudon",
  "montrouge" = "Montrouge",
  "nanterre" = "Nanterre",
  "neuilly-sur-seine" = "Neuilly-sur-Seine",
  "paris-14" = "Paris-14eme",
  "paris-15" = "Paris-15eme",
  "paris-16" = "Paris-16eme",
  "paris-17" = "Paris-17eme",
  "puteaux" = "Puteaux",
  "rueil-malmaison" = "Rueil-Malmaison",
  "saint-cloud" = "Saint-Cloud",
  "saint-ouen" = "Saint-ouen",
  "sceaux" = "Sceaux",
  "sevres" = "Sèvres",
  "suresnes" = "Suresnes",
  "vanves" = "Vanves",
  "vaucresson" = "Vaucresson",
  "versailles" = "Versailles",
  "ville-d-avray" = "Ville-d'Avray",
  "villeneuve-la-garenne" = "Villeneuve-la-Garenne",
  "viroflay" = "Viroflay",
  "L-hay-les-roses" = "L'Haÿ-les-Roses",
}

export enum projectCities {
  "antony" = "Antony",
  "arcueil" = "Arcueil",
  "asnieres-sur-seine" = "Asnières-sur-Seine",
  "bagneux" = "Bagneux",
  "bois-colombes" = "Bois-Colombes",
  "boulogne-billancourt" = "Boulogne-Billancourt",
  "bourg-la-reine" = "Bourg-la-Reine",
  "cachan" = "Cachan",
  "chatenay-malabry" = "Châtenay-Malabry",
  "chatillon" = "Châtillon",
  "chaville" = "Chaville",
  "clamart" = "Clamart",
  "clichy" = "Clichy",
  "clichy-la-garenne" = "Clichy-la-Garenne",
  "colombes" = "Colombes",
  "courbevoie" = "Courbevoie",
  "fontenay-aux-roses" = "Fontenay-aux-Roses",
  "fresnes" = "Fresnes",
  "garches" = "Garches",
  "gennevilliers" = "Gennevilliers",
  "issy-les-moulineaux" = "Issy-les-Moulineaux",
  "la garenne-colombes" = "La Garenne-Colombes",
  "le plessis-robinson" = "Le Plessis-Robinson",
  "levallois-perret" = "Levallois-Perret",
  "malakoff" = "Malakoff",
  "marnes-la-coquette" = "Marnes-la-Coquette",
  "massy" = "Massy",
  "meudon" = "Meudon",
  "montrouge" = "Montrouge",
  "nanterre" = "Nanterre",
  "neuilly-sur-seine" = "Neuilly-sur-Seine",
  "puteaux" = "Puteaux",
  "rueil-malmaison" = "Rueil-Malmaison",
  "saint-cloud" = "Saint-Cloud",
  "saint-ouen" = "Saint-ouen",
  "sceaux" = "Sceaux",
  "sevres" = "Sèvres",
  "suresnes" = "Suresnes",
  "vanves" = "Vanves",
  "vaucresson" = "Vaucresson",
  "versailles" = "Versailles",
  "ville-d-avray" = "Ville-d'Avray",
  "villeneuve-la-garenne" = "Villeneuve-la-Garenne",
  "viroflay" = "Viroflay",
  "L-hay-les-roses" = "L'Haÿ-les-Roses",
}

export const citiesBoundingBoxes: {
  [key: string]: {
    extent: {
      spatialReference: { wkid: number },
      xmax: number,
      xmin: number,
      ymax: number,
      ymin: number,
    },
    label: string,
  },
} = {
  "L-hay-les-roses": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.359010,
      xmin: 2.319010,
      ymax: 48.798710,
      ymin: 48.758710,
    },
    label: "L'Haÿ-les-Roses",
  },
  "antony": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.332768,
      xmin: 2.265305,
      ymax: 48.777215,
      ymin: 48.723679,
    },
    label: "Antony",
  },
  "arcueil": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.352086,
      xmin: 2.318086,
      ymax: 48.822310,
      ymin: 48.788310,
    },
    label: "Arcueil",
  },
  "asnieres-sur-seine": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.323073,
      xmin: 2.262047,
      ymax: 48.942020,
      ymin: 48.896504,
    },
    label: "Asnieres-sur-Seine",
  },
  "bagneux": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.338026,
      xmin: 2.283266,
      ymax: 48.815463,
      ymin: 48.781145,
    },
    label: "Bagneux",
  },
  "bois-colombes": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.288713,
      xmin: 2.249089,
      ymax: 48.932718,
      ymin: 48.897192,
    },
    label: "Bois-Colombes",
  },
  "boulogne-billancourt": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.271482,
      xmin: 2.221529,
      ymax: 48.857258,
      ymin: 48.816752,
    },
    label: "Boulogne-Billancourt",
  },
  "bourg-la-reine": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.334107,
      xmin: 2.301196,
      ymax: 48.793334,
      ymin: 48.766891,
    },
    label: "Bourg-la-Reine",
  },
  "cachan": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.347950,
      xmin: 2.321950,
      ymax: 48.807660,
      ymin: 48.781660,
    },
    label: "Cachan",
  },
  "chatenay-malabry": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.304487,
      xmin: 2.220631,
      ymax: 48.788917,
      ymin: 48.752374,
    },
    label: "Chatenay-Malabry",
  },
  "chatillon": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.311522,
      xmin: 2.267224,
      ymax: 48.814833,
      ymin: 48.789554,
    },
    label: "Chatillon",
  },
  "chaville": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.225367,
      xmin: 2.165200,
      ymax: 48.823988,
      ymin: 48.794991,
    },
    label: "Chaville",
  },
  "clamart": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.295405,
      xmin: 2.213866,
      ymax: 48.823102,
      ymin: 48.768031,
    },
    label: "Clamart",
  },
  "clichy": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.329895,
      xmin: 2.281572,
      ymax: 48.918311,
      ymin: 48.889537,
    },
    label: "Clichy",
  },
  "clichy-la-garenne": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.329895,
      xmin: 2.281572,
      ymax: 48.918311,
      ymin: 48.889537,
    },
    label: "Clichy-la-Garenne",
  },
  "colombes": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.289493,
      xmin: 2.216194,
      ymax: 48.942931,
      ymin: 48.901196,
    },
    label: "Colombes",
  },
  "courbevoie": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.288733,
      xmin: 2.227107,
      ymax: 48.912077,
      ymin: 48.884823,
    },
    label: "Courbevoie",
  },
  "fontenay-aux-roses": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.311487,
      xmin: 2.268093,
      ymax: 48.800986,
      ymin: 48.779794,
    },
    label: "Fontenay-aux-Roses",
  },
  "fresnes": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.335200,
      xmin: 2.307200,
      ymax: 48.771080,
      ymin: 48.743080,
    },
    label: "Fresnes",
  },
  "garches": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.210546,
      xmin: 2.161751,
      ymax: 48.857154,
      ymin: 48.837103,
    },
    label: "Garches",
  },
  "gennevilliers": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.347210,
      xmin: 2.234944,
      ymax: 48.962174,
      ymin: 48.899583,
    },
    label: "Gennevilliers",
  },
  "issy-les-moulineaux": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.298789,
      xmin: 2.225332,
      ymax: 48.839219,
      ymin: 48.807461,
    },
    label: "Issy-les-Moulineaux",
  },
  "la garenne-colombes": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.265127,
      xmin: 2.227452,
      ymax: 48.917701,
      ymin: 48.896069,
    },
    label: "La Garenne-Colombes",
  },
  "le plessis-robinson": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.289770,
      xmin: 2.231362,
      ymax: 48.794778,
      ymin: 48.769836,
    },
    label: "Le Plessis-Robinson",
  },
  "levallois-perret": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.314695,
      xmin: 2.263969,
      ymax: 48.908959,
      ymin: 48.880236,
    },
    label: "Levallois-Perret",
  },
  "malakoff": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.322966,
      xmin: 2.268971,
      ymax: 48.829392,
      ymin: 48.803920,
    },
    label: "Malakoff",
  },
  "marnes-la-coquette": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.198118,
      xmin: 2.142796,
      ymax: 48.845966,
      ymin: 48.813982,
    },
    label: "Marnes-la-Coquette",
  },
  "massy": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.297290,
      xmin: 2.249290,
      ymax: 48.754300,
      ymin: 48.706300,
    },
    label: "Massy",
  },
  "meudon": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.268451,
      xmin: 2.190647,
      ymax: 48.826893,
      ymin: 48.774598,
    },
    label: "Meudon",
  },
  "montrouge": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.347384,
      xmin: 2.293542,
      ymax: 48.824756,
      ymin: 48.805603,
    },
    label: "Montrouge",
  },
  "nanterre": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.246180,
      xmin: 2.160440,
      ymax: 48.927176,
      ymin: 48.868494,
    },
    label: "Nanterre",
  },
  "neuilly-sur-seine": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.298904,
      xmin: 2.234395,
      ymax: 48.906908,
      ymin: 48.868048,
    },
    label: "Neuilly-sur-Seine",
  },
  "paris-14": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.345460,
      xmin: 2.327460,
      ymax: 48.831157,
      ymin: 48.813157,
    },
    label: "Paris-14eme",
  },
  "paris-15": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.307384,
      xmin: 2.291384,
      ymax: 48.838518,
      ymin: 48.822518,
    },
    label: "Paris-15eme",
  },
  "paris-16": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.274198,
      xmin: 2.262198,
      ymax: 48.845805,
      ymin: 48.833805,
    },
    label: "Paris-16eme",
  },
  "paris-17": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.306332,
      xmin: 2.292332,
      ymax: 48.887798,
      ymin: 48.873798,
    },
    label: "Paris-17eme",
  },
  "puteaux": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.265440,
      xmin: 2.212397,
      ymax: 48.901487,
      ymin: 48.867397,
    },
    label: "Puteaux",
  },
  "rueil-malmaison": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.235655,
      xmin: 2.133600,
      ymax: 48.907987,
      ymin: 48.841339,
    },
    label: "Rueil-Malmaison",
  },
  "saint-cloud": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.234520,
      xmin: 2.172464,
      ymax: 48.862536,
      ymin: 48.822543,
    },
    label: "Saint-Cloud",
  },
  "saint-ouen": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.353330,
      xmin: 2.313330,
      ymax: 48.920000,
      ymin: 48.880000,
    },
    label: "Saint-ouen",
  },
  "sceaux": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.326537,
      xmin: 2.272158,
      ymax: 48.791057,
      ymin: 48.762020,
    },
    label: "Sceaux",
  },
  "sevres": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.241355,
      xmin: 2.172089,
      ymax: 48.841558,
      ymin: 48.803527,
    },
    label: "Sèvres",
  },
  "suresnes": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.244194,
      xmin: 2.193618,
      ymax: 48.886329,
      ymin: 48.856218,
    },
    label: "Suresnes",
  },
  "vanves": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.306528,
      xmin: 2.266918,
      ymax: 48.831145,
      ymin: 48.811428,
    },
    label: "Vanves",
  },
  "vaucresson": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.195795,
      xmin: 2.134983,
      ymax: 48.858101,
      ymin: 48.814717,
    },
    label: "Vaucresson",
  },
  "versailles": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.125760,
      xmin: 2.115760,
      ymax: 48.80977,
      ymin: 48.799769,
    },
    label: "Versailles",
  },
  "ville-d-avray": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.213907,
      xmin: 2.143681,
      ymax: 48.835421,
      ymin: 48.807242,
    },
    label: "Ville d'Avray",
  },
  "villeneuve-la-garenne": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.248870,
      xmin: 2.301470,
      ymax: 48.953374,
      ymin: 48.919766,
    },
    label: "Villeneuve-la-Garenne",
  },
  "viroflay": {
    extent: {
      spatialReference: { wkid: 4326 },
      xmax: 2.183740,
      xmin: 2.155740,
      ymax: 48.818130,
      ymin: 48.790130,
    },
    label: "Viroflay",
  },
};

export const calculateCityCentroid = (extent: {
  xmax: number,
  xmin: number,
  ymax: number,
  ymin: number,
}) => {
  let longitude: number = 0;
  let latitude: number = 0;
  longitude = (extent.xmax + extent.xmin) / 2;
  latitude = (extent.ymax + extent.ymin) / 2;
  return { longitude, latitude };
};

export const removeAccents = (s: string) => {
  let r = s.toLowerCase();
  r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
  r = r.replace(new RegExp("æ", "g"), "ae");
  r = r.replace(new RegExp("ç", "g"), "c");
  r = r.replace(new RegExp("[èéêë]", "g"), "e");
  r = r.replace(new RegExp("[ìíîï]", "g"), "i");
  r = r.replace(new RegExp("ñ", "g"), "n");
  r = r.replace(new RegExp("[òóôõö]", "g"), "o");
  r = r.replace(new RegExp("œ", "g"), "oe");
  r = r.replace(new RegExp("[ùúûü]", "g"), "u");
  r = r.replace(new RegExp("[ýÿ]", "g"), "y");
  return r;
};
