import React, { useEffect, useState } from "react";
import { Collapse} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ISearch, ISearchDetail } from "../../store/redux/types";
import Breadcrumb from "../Utils/breadcrumb";
import CollapseToggle from "../Utils/buttons/CollapseToggle";
import "./detail.scss";
import DetailContainer from "./detailContainer";

interface IDetail {
  detail: ISearchDetail;
  searchData: any;
}

const Detail = (props: IDetail) => {
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    setIsHidden(false);
  }, [props.detail.shownDetailId, props.detail.shownEquipementId]);
  const shownItemDetails = props.detail.shownDetailItem;
  const getDetailTitle = () => {
    let title: string = t(shownItemDetails["index:name"]);
    if (shownItemDetails["index:name"] === "asset:pluviometre") {
      title += " " + shownItemDetails["asset:idpluv"];
    } else if (shownItemDetails["index:name"] === "asset:point_de_mesure") {
      title += " " + shownItemDetails["asset:idpm"] + " " + shownItemDetails["asset:idps"];
    } else if (shownItemDetails["index:name"] === "asset:poste_satellite") {
      title += " " + shownItemDetails["asset:idps"];
    } else {
      title += shownItemDetails["asset:nomexplo"] ? " " + shownItemDetails["asset:nomexplo"] : " " + shownItemDetails["asset:id_sig"];
    }
    return title;
  };

  return (
    <>
      <div className="gaia-detail-result">
        {isHidden && <div onClick={() => setIsHidden(false)} className="gaia-detail-result--collapsed-body">
          <div className="gaia-detail-result--collapsed-body--title">
            {getDetailTitle()}
          </div>
        </div>
        }
        <Collapse in={!isHidden} timeout={0} dimension="width">
          <div className="gaia-detail-result--expanded-body">
            <Breadcrumb />
            <DetailContainer
              shownItem={props.detail.shownDetailItem}
            />
          </div>
        </Collapse>
        <CollapseToggle collapseDirection={"left"} handler={setIsHidden} toggled={isHidden} />
      </div>
    </>
  );
};

const mapStateToProps = (state: { search: ISearch }): IDetail => {
  return {
    detail: state.search.detail,
    searchData: state.search.searchData,
  };
};

export default connect(mapStateToProps)(Detail);
