import { FILTERS } from "../constants";
import { IFilter, IResetFilterAction, ISetFilterAction, IUpdateFilterAction } from "../types";

export default {
  updateFilters(filter: IFilter): IUpdateFilterAction {
    return {
      payload: { ...filter },
      type: FILTERS.UPDATE,
    };
  },
  setFilters(filters: IFilter[]): ISetFilterAction {
    return {
      payload: filters,
      type: FILTERS.SET,
    };
  },
  resetFilters(): IResetFilterAction {
    return {
      type: FILTERS.RESET,
    };
  },
};
