import React, { useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Report } from "../../../services/Report";
import { ReportActions } from "../../../store/redux/actions";
import { IReportData } from "../../../store/redux/types";
import GeoserverBVFavFilters from "./GeoserverBVFavFilters";

function GeoserverBVListHeader(props: any) {
  const { t } = useTranslation();
  const [favFilterFormIsActive, setFavFilterFormIsActive] = useState(false);

  const showFavFilterForm = () => {
    ReactGA.event({ action: `Open fav filter form`, category: "geoserver bv" });
    setFavFilterFormIsActive(true);
  };

  const handleCloseFavFilterForm = () => {
    setFavFilterFormIsActive(false);
  };

  return (
    <>
      <div className={"report--menu--tabs_list--header_buttons"}>
        {/* <Button className={"report--menu--tabs_list--button"}>{t("report:chooseColumns")}</Button> */}
        <Dropdown className={"report--menu--tabs_list--button"}>
          <Dropdown.Toggle id={"dropdown-export"} className={"report--menu--tabs_list--button ml-0"}>
            {t("report:export")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              target={"_blank"}
              href={Report.getGeoserverBVCSVExportURL(props.geoserverBVFilters, 1, Report.maxElasticResult)}
            >
              {t("report:exportAll")}
            </Dropdown.Item>
            <Dropdown.Item
              target={"_blank"}
              href={Report.getGeoserverBVCSVExportURL(props.geoserverBVFilters, props.geoserverBVCurrentPage)}
            >
              {t("report:exportVisible")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button
          className={`report--menu--tabs_list--button ml-0 ${props.areFiltersEmpty ? "disabled" : ""}`}
          disabled={props.areFiltersEmpty}
          onClick={showFavFilterForm}
        >
          {t("report:addFavFilter")}
        </Button>
        <Button
          onClick={props.resetAction}
          disabled={props.areFiltersEmpty}
          className={`report--menu--tabs_list--button ${props.areFiltersEmpty ? "disabled" : ""}`}
        >
          {t("report:reset")}
        </Button>
        <Button
          onClick={props.filterAction}
          disabled={props.areFiltersEmpty}
          className={`report--menu--tabs_list--button ${props.areFiltersEmpty ? "disabled" : ""}`}
        >
          {t("report:filter")}
        </Button>
      </div>
      <GeoserverBVFavFilters
        favFilterFormIsActive={favFilterFormIsActive}
        handleCloseFavFilterForm={handleCloseFavFilterForm}
      />
    </>
  );
}

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    geoserverBVCurrentPage: state.report.geoserverBVCurrentPage,
    geoserverBVFilters: state.report.geoserverBVFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoserverBVListHeader);
