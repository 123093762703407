import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import {
  calculateCityCentroid,
  citiesBoundingBoxes,
  parcelConformiteBVsColors,
  parcelType,
  projectCities,
} from "../../../config/report";
import {goToHome, zoomToCoordinates} from "../../../manager/map/navigation";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IParcelFilter,
  IReportData,
} from "../../../store/redux/types";
import ListHeader from "./ListHeader";

const ParcelListFilters = (props: any): ReactElement => {
  const { t } = useTranslation();

  const cityOptions = Object.entries(projectCities).map((item: any) => {
    return { label: item[1], value: item[0].toUpperCase() };
  });

  const typeOptions = Object.entries(parcelType).map((item: any) => {
    return { label: t(`report:parcel.typeOptions.${item[0]}`), value: item[1] };
  });

  const rateOptions = Object.entries(parcelConformiteBVsColors).map((item: any) => {
    return { label: t(`report:parcel.rateOptions.${item[0]}`), value: item[1] };
  });

  const [localCity, setCity] = useState<any[]>(props.parcelFilters.city);
  const [localType, setType] = useState<any[]>(props.parcelFilters.type);
  const [localRate, setRate] = useState<any[]>(props.parcelFilters.rate);

  useEffect(() => {
    if (props.parcelFilters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.parcelFilters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.parcelFilters.type) {
      const typeValues = typeOptions.filter((item: any) => {
        return props.parcelFilters.type.includes(item.value);
      });
      setType(typeValues);
    }
    if (props.parcelFilters.rate) {
      const rateValues = rateOptions.filter((item: any) => {
        return props.parcelFilters.city.includes(item.value);
      });
      setRate(rateValues);
    }
  }, [props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  const isFiltersEmpty = () => {
    return ((!localCity || localCity.length === 0) &&
      (!localType || localType.length === 0) &&
      (!localRate || localRate.length === 0));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());

  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localCity,
    localType,
    localRate,
  ]);

  useEffect(() => {
    if (areFiltersEmpty) {
      setTimeout(() => {
        resetAction();
      }, 1000);
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    const filters: IParcelFilter = {
      city: localCity.map((item) => item.value),
      type: localType.map((item) => item.value),
      rate: localRate.map((item) => item.value),
    };
    if (filters.city && filters.city.length === 1) {
      const cityValue = Object.entries(projectCities).filter((item: any) => {
        if (filters.city && filters.city.length === 1 && item[0] === filters.city[0].toLowerCase()) {
          return item[0];
        }
      });
      const coordinates = calculateCityCentroid(citiesBoundingBoxes[cityValue[0][0]].extent);
      props.setReportMapPosition([coordinates.latitude, coordinates.longitude, 10000]);
      zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
    } else if (filters.city && filters.city.length > 1) {
      props.setReportMapPosition(mapOrigin);
      zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
    }
    props.updateParcelFilters(filters);
  };

  const resetAction = () => {
    setCity([]);
    setType([]);
    setRate([]);
    const filters: IParcelFilter = {
      city: [],
      type: [],
      rate: [],
    };
    props.updateParcelFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset Déconnexion des eaux pluviales filters`, category: "Report" });
  };

  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:parcel.city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:parcel.type")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary type"
            isSearchable
            isClearable
            isMulti
            id="type"
            name="type"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setType);
            }}
            options={typeOptions}
            value={localType}
          />
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
              <div className={"color-bar line"}></div>
              <div className={"option-text"}>
                {t("report:parcel.typeOptions.disconnected")}
              </div>
          </div>
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
              <div className={"color-bar squash-blossom"}></div>
              <div className={"option-text"}>
                {t("report:parcel.typeOptions.regulated")}
              </div>
          </div>
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
              <div className={"color-bar good-samaritan"}></div>
              <div className={"option-text"}>
                {t("report:parcel.typeOptions.unknown")}
              </div>
          </div>
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:parcel.rate")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary rate"
            isSearchable
            isClearable
            isMulti
            id="rate"
            name="rate"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setRate);
            }}
            options={rateOptions}
            value={localRate}
          />
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
            <div className={"color-bar green"}></div>
            <div className={"option-text"}>
              {t("report:parcel.rateOptions.greater_5")}
            </div>
          </div>
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
            <div className={"color-bar orange"}></div>
            <div className={"option-text"}>
              {t("report:parcel.rateOptions.between_1_and_5")}
            </div>
          </div>
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
            <div className={"color-bar red"}></div>
            <div className={"option-text"}>
              {t("report:parcel.rateOptions.less_1")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    parcelCurrentPage: state.report.parcelCurrentPage,
    currentTab: state.report.currentTab,
    parcelFilters: state.report.parcelFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParcelListFilters);
