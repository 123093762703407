import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReportFilters from "../../components/Filters/Report/ReportFilters";
import Map from "../../components/Map";
import NetworkTable from "../../components/NetworkTable/NetworkTable";
import Smartshape from "../../components/Smartshape";
import { highlightObjects } from "../../manager/map/loader";
import { zoomToCoordinates } from "../../manager/map/navigation";
import { ReportActions, UserActions } from "../../store/redux/actions";
import {
  IBV,
  IParcel,
  IPlu,
  IProject,
  IReport,
  IReportColumn,
  IReportData,
  IReportFilter,
  ISearch,
  ISignal,
  ISiteIndustriel,
  IUser,
} from "../../store/redux/types";
import "./report.scss";
import {store} from "../../store";
import {SEARCH} from "../../store/redux/constants";

export enum reportTabs {
  list = "list",
  map = "map",
}

export interface IReportLayersData {
  conform: IReport[];
  notConformOther: IReport[];
  EUEPNotConform: IReport[];
  EPEUNotConform: IReport[];
  EUEPEPEUNotConform: IReport[];
  functioningNotConform: IReport[];
  unknown: IReport[];
}

export interface IProjectLayersData {
  confirmed: IProject[];
  suspecte: IProject[];
  travaux: IProject[];
}

export interface ISignalLayersData {
  free: ISignal[];
  harmful: ISignal[];
  noise: ISignal[];
  odour: ISignal[];
  overflow: ISignal[];
  rejection: ISignal[];
  road: ISignal[];
  work: ISignal[];
}

export interface ISiteIndustrielLayersData {
  siteIndustrielConform: ISiteIndustriel[];
  siteIndustrielNotConform: ISiteIndustriel[];
  siteIndustrielNotConcerned: ISiteIndustriel[];
  siteIndustrielUnknown: ISiteIndustriel[];
  //
  siteIndustrielActifSuivi: ISiteIndustriel[];
  siteIndustrielNotSuivi: ISiteIndustriel[];
  siteIndustrielFerme: ISiteIndustriel[];

}

export interface IParcelLayersData {
  disconnected: IParcel[];
  regulated: IParcel[];
  unknown: IParcel[];
}

export interface IPluLayersData {
  favorable: IPlu[];
  defavorable: IPlu[];
  inexistant: IPlu[];
}

export interface IBVParcelLayersData {
  lessthan1: IParcel[];
  between1and5: IParcel[];
  greaterThan5: IParcel[];
}

export interface IBVCollectorLayersData {
  SAN: IBV[];
  CAA: IBV[];
  DES2B: IBV[];
  SAR: IBV[];
  CAB: IBV[];
  CLI: IBV[];
  ES1B: IBV[];
  MAR: IBV[];
  NL: IBV[];
  ANT: IBV[];
  BLR: IBV[];
  SUR: IBV[];
  NE: IBV[];
  ASN: IBV[];
  LAS: IBV[];
}

interface IReportProps {
  connection: any;
  shownDetailItem: any;
  currentTab: keyof typeof reportTabs;
  changeTab: (newTab: keyof typeof reportTabs) => void;
  reportMapPosition: [number, number, number];
  filters: IReportFilter;
  geoserverBVs: any;
  filteredGeoserverBVs: any;
  geoserverBVsColors: string[];
  getMapPreferences: () => void;
  getReports: (filters: IReportFilter, sortField: string, sortDirection: string, currentPage?: number) => any;
  getProjects: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getSiteIndustriels: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getParcels: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getPlu: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  getGeoserverBV: (colors: string[]) => any;
  getGeoserverBVByFilters: (filters: any, sortField: string, sortDirection: string, currentPage?: number) => any;
  listType: string;
  reports: IReport[];
  setReportMapPosition: (position: [number, number, number]) => void;
  showDetail: (itemID: string) => any;
  showProjectDetail: (projectId: string) => any;
  showSignalDetail: (signalId: string) => any;
  showSiteIndustrielDetail: (siteIndustrielId: string) => any;
  showGeoserverBVDetail: (geoserverBVId: string) => any;
  sortDirection: string;
  sortField: string;
  setSort: (sortField: string) => void;
  projectSortDirection: string;
  projectSortField: string;
  setProjectSort: (sortField: string) => void;
  signalSortDirection: string;
  signalSortField: string;
  setSignalSort: (sortField: string) => void;
  geoserverBVSortDirection: string;
  geoserverBVSortField: string;
  setGeoserverBVSort: (sortField: string) => void;
  siteIndustrielSortDirection: string;
  siteIndustrielSortField: string;
  setSiteIndustrielSort: (sortField: string) => void;
  parcelSortDirection: string;
  parcelSortField: string;
  pluSortDirection: string;
  pluSortField: string;
  showSmartshape: boolean;
  isMapDisplayedFullscreen: boolean;
}

const Report = (props: IReportProps) => {
  const elementRef: any = useRef();

  const [currentTab, setCurrentTab]: [keyof typeof reportTabs, (t: keyof typeof reportTabs) => void]
    = useState(props.currentTab);

  const { t } = useTranslation();
  const { reportMapPosition } = props;

  const conformityState = [
    "Conforme",
    "Non conforme",
    "Non Conforme sans Dysfonctionnement",
    "A Déterminer",
  ];

  useEffect(() => {
    props.getGeoserverBV(props.geoserverBVsColors);
  }, []);

  useEffect(() => {
    if (props.listType === "parcel" || props.listType === "plu") {
      setCurrentTab(reportTabs.map);
    }
  }, [props.listType]);

  useEffect(() => {
    if ("undefined" !== typeof props.getReports && currentTab === reportTabs.map) {
      props.getReports(props.filters, props.sortField, props.sortDirection);
    }
  }, [props.filters, props.sortField, props.sortDirection]);

  const calculateCentroid = (data: any) => {
    let longitude: number = 0;
    let latitude: number = 0;
    longitude = (parseFloat(data[1][1]) + parseFloat(data[0][1])) / 2;
    latitude = (parseFloat(data[1][0]) + parseFloat(data[0][0])) / 2;
    return { longitude, latitude };
  };

  useEffect(() => {
    if (props.reports.length === 1 && props.reports[0].position && currentTab === reportTabs.map) {
      const coordinates = calculateCentroid(props.reports[0].position);
      zoomToCoordinates(coordinates.latitude, coordinates.longitude, 250);
    }
  }, [props.reports]);

    useEffect(() => {
      if (props.currentTab === reportTabs.map) {
        setTimeout(() => {
          if (props.reportMapPosition) {
            zoomToCoordinates(props.reportMapPosition[0], props.reportMapPosition[1], props.reportMapPosition[2]);
          }
        }, 500);
      }
    }, [props.currentTab]);

  const [shownItemId, setShownItemId]: [number | undefined, (id: number | undefined) => void] = useState();

  const handleNetworkNumberClick = (networkID: any): void => {
    props.showDetail(networkID)
      .then((data: any) => {
        if (data.centroid && data.centroid.latitude !== 0 && data.centroid.longitude !== 0) {
          props.setReportMapPosition([data.centroid.latitude, data.centroid.longitude, 250]);
          const objectId: string = networkID;
          setTimeout(() => {
            highlightObjects([{ objectId, layerName: "Conduite_de_branchement" }]);
          }, 5000);
        }
        setShownItemId(networkID);
        setCurrentTab(reportTabs.map);
      });
  };

  const handleProjectNameClick = (projectID: any): void => {
    props.showProjectDetail(projectID)
      .then((data: any) => {
        if (data.latitude !== 0 && data.longitude !== 0) {
          props.setReportMapPosition([data.latitude, data.longitude, 250]);
        }
        setShownItemId(projectID);
        setCurrentTab(reportTabs.map);
      });
  };

  const handleSignalCategoryClick = (signalID: any): void => {
    props.showSignalDetail(signalID)
      .then((data: any) => {
        if (data.address.latitude !== 0 && data.address.longitude !== 0) {
          props.setReportMapPosition([data.address.latitude as number, data.address.longitude as number, 250]);
        }
        setShownItemId(signalID);
        setCurrentTab(reportTabs.map);
      });
  };

  const handleSiteIndustrielNameClick = (siteIndustrielId: any): void => {
    store.dispatch({payload: {flag: {loading: true}}, type: SEARCH.LOADING});
    props.showSiteIndustrielDetail(siteIndustrielId)
        .then((data: any) => {
          if (data.centroid !== "" && data.centroid !== undefined) {
            const position: number[] = data.centroid.split(",");
            props.setReportMapPosition([position[1] as number, position[0] as number, 1400]);
          }
          setShownItemId(siteIndustrielId);
          setCurrentTab(reportTabs.map);
          store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});
        }).catch((error: Error) => {
      store.dispatch({payload: {flag: {loading: false}}, type: SEARCH.LOADING});
      console.log(error);
    });
  };

  const handleGeoserverBVIdClick = (id: any): void => {
    props.showGeoserverBVDetail(id)
      .then((data: any) => {
        if (data.coordinates) {
          const coordinates = data.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
          });
          const centroid = calculateCentroid(coordinates);
          props.setReportMapPosition([centroid.latitude, centroid.longitude, 10000]);
        }
        setShownItemId(id);
        setCurrentTab(reportTabs.map);
      });
  };

  useEffect(() => {
    props.changeTab(currentTab);
    props.getMapPreferences();
  }, [currentTab]);

  const prefixLabelFormatter = (cell: string, row: IReport, index: number, colName: string) => {
    return i18next.t(`report:${colName}.${cell}`);
  };

  const conformiteFormatter = (cell: number) => {
    return conformityState[cell];
  };

  const siteIndustrielConformiteFormatter = (cell: string) => {
    return i18next.t(`report:siteIndustriel.conformity.${cell}`);
  };

  const isClickable = (networkID: string, row: IReport): boolean => {
    return !("Neuf" === networkID || "" === row.iD);
  };

  const changeTab = (tabName: any) => {
    if (Object.values(reportTabs).includes(tabName)) {
      setCurrentTab(tabName);
      if (shownItemId && tabName === reportTabs.list) {
        const elements = document.getElementsByClassName("selected");
        for (const element of elements as any) {
          element.classList.remove("selected");
        }
        document.getElementsByClassName(`row-${shownItemId}`)[0]?.classList.add("selected");
        document.getElementsByClassName(`row-${shownItemId}`)[0]?.scrollIntoView({
          behavior: "auto",
          block: "end",
        });
      }
      ReactGA.event({ action: `Change tab: ${tabName}`, category: "Report" });
    }
  };

  const networkNumberFormatter = (networkID: string, row: IReport) => {
    if (isClickable(networkID, row)) {
      return (
        <span
          className={`report-table__networkID clickable row-${row.iD}`}
          onClick={() => {
            handleNetworkNumberClick(row.iD);
          }}
        >
          {networkID}
        </span>
      );
    }
    return (
      <span className={"report-table__networkID"}>
        {networkID}
      </span>
    );
  };

  const projectNameFormatter = (name: string, row: IProject) => {
    return (
      <span
        className={`report-table__networkID clickable row-${row.id}`}
        onClick={() => {
          handleProjectNameClick(row.id);
        }}
      >
        {name}
      </span>
    );
  };

  const signalCategoryFormatter = (category: string, row: IProject) => {
    return (
      <span
        className={`report-table__networkID clickable row-${row.id}`}
        onClick={() => {
          handleSignalCategoryClick(row.id);
        }}
      >
        {i18next.t(`report:category.${category}`)}
      </span>
    );
  };

  const siteIndustrielNameFormatter = (name: string, row: ISiteIndustriel) => {
    return (
      <span
        className={`report-table__networkID clickable row-${row.idCD92}`}
        onClick={() => {
          handleSiteIndustrielNameClick(row.idCD92);
        }}
      >
        {name}
      </span>
    );
  };

  const siteIndustrielConnectionNumberFormatter = (name: any, row: any) => {
    const content = row.connectionNumber.map((connection: any) => {
      return (
        <span
          className={`report-table__networkID clickable row-${connection.layer_id}`}
          onClick={() => {
            handleNetworkNumberClick(connection.layer_id);
          }}
        >
          {connection.nomexplo}<br></br>
        </span>
      );
    });
    return (content);
  };

  const geoserverBVIdFormatter = (id: any, row: any) => {
    // Check if id is a string
    if (typeof id === 'string') {
      // Perform the replace operation
      id = id.replace(/_/g, " ");
      return (
          <span
              className={`report-table__networkID clickable row-${row.id}`}
              onClick={() => {
                handleGeoserverBVIdClick(row.id);
              }}
          >
        {id}
      </span>
      );
    } else {
      // Handle other types or unexpected data
      return id; // or some other default value or behavior
    }
  };


  const sortByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setSort(sortField);
  };

  const sortProjectByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setProjectSort(sortField);
  };

  const sortSignalByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setSignalSort(sortField);
  };

  const sortSiteIndustrielByColumn = (e: any, column: any) => {
    e.stopPropagation();
    const sortField = column.dataField;
    props.setSiteIndustrielSort(sortField);
  };

  const sortGeoserverBVByColumn = (e: any, column: any) => {
    const sortField = column.dataField;
    props.setGeoserverBVSort(sortField);
  };

  const tableSortFunc = (a: any, b: any, order: any, dataField: any, rowA: any, rowB: any) => {
    if (order === "asc") {
      return b - a;
    }
    return a - b; // desc
  };

  const defaultControlTableHeader = [
    {
      dataField: "number",
      formatter: networkNumberFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 0,
      sort: true,
      text: "report:network.number",
      weight: 0,
    },
    {
      dataField: "address",
      key: 1,
      sort: false,
      text: "report:network.address",
      weight: 1,
    },
    {
      dataField: "city",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 2,
      sort: true,
      text: "report:network.city",
      weight: 2,
    },
    {
      dataField: "controlType",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 3,
      sort: true,
      text: "report:controlType.label",
      weight: 3,
    },
    {
      dataField: "conformity",
      formatter: conformiteFormatter,
      key: 4,
      sort: false,
      text: "report:network.conformity",
      weight: 4,
    },
    {
      dataField: "majorFaultType",
      key: 5,
      sort: false,
      text: "report:majorFaultType.label",
      weight: 5,
    },
    {
      dataField: "conformityCheckType",
      formatExtraData: "conformityCheckType",
      formatter: prefixLabelFormatter,
      key: 6,
      sort: false,
      text: "report:network.conformityCheckType",
      weight: 6,
    },
    {
      dataField: "propertyType",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 7,
      sort: true,
      text: "report:propertyType.label",
      weight: 7,
    },
    {
      dataField: "conformityCheckDate",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 8,
      sort: true,
      text: "report:network.conformityCheckDate",
      weight: 8,
    },
    {
      dataField: "generationReportDate",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 9,
      sort: true,
      text: "report:network.generationReportDate",
      weight: 9,
    },
    {
      dataField: "emOperatingSector",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 10,
      sort: true,
      text: "report:emOperatingSector.label",
      weight: 10,
    },
    {
      dataField: "networkOperatingSector",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 11,
      sort: true,
      text: "report:networkOperatingSector.label",
      weight: 11,
    },
  ];

  const defaultProjectTableHeader = [
    {
      dataField: "name",
      formatter: projectNameFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 0,
      sort: true,
      text: "report:project.name",
      weight: 0,
    },
    {
      dataField: "status",
      formatExtraData: "status",
      formatter: prefixLabelFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 1,
      sort: true,
      text: "report:project.status",
      weight: 1,
    },
    {
      dataField: "address",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 2,
      sort: true,
      text: "report:project.address",
      weight: 2,
    },
    {
      dataField: "city",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 3,
      sort: true,
      text: "report:project.city",
      weight: 3,
    },
    {
      dataField: "pcDate",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 4,
      sort: true,
      text: "report:project.pcDate",
      weight: 4,
    },
    {
      dataField: "pcNumber",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 5,
      sort: true,
      text: "report:project.pcNumber",
      weight: 5,
    },
    {
      dataField: "hold",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 6,
      sort: true,
      text: "report:project.hold",
      weight: 6,
    },
    {
      dataField: "zac",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 7,
      sort: true,
      text: "report:project.zac",
      weight: 7,
    },
    {
      dataField: "actor",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 8,
      sort: true,
      text: "report:project.actor",
      weight: 8,
    },
    {
      dataField: "deliveryDate",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 9,
      sort: true,
      text: "report:project.deliveryDate",
      weight: 9,
    },
    {
      dataField: "hasFiles",
      key: 10,
      sort: false,
      text: "report:project.hasFiles",
      weight: 10,
    },
    {
      dataField: "url",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortProjectByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 7,
      sort: true,
      text: "report:project.url",
      weight: 7,
    },
  ];

  const defaultSignalTableHeader = [
    {
      dataField: "category",
      formatExtraData: "category",
      // formatter: prefixLabelFormatter,
      formatter: signalCategoryFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 0,
      sort: true,
      text: "report:signal.category",
      weight: 0,
    },
    {
      dataField: "status",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 1,
      sort: true,
      text: "report:signal.status",
      weight: 1,
    },
    {
      dataField: "detail",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 2,
      sort: true,
      text: "report:signal.detail",
      weight: 2,
    },
    {
      dataField: "city",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 3,
      sort: true,
      text: "report:signal.city",
      weight: 3,
    },
    {
      dataField: "address",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 4,
      sort: true,
      text: "report:signal.address",
      weight: 4,
    },
    {
      dataField: "date",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 5,
      sort: true,
      text: "report:signal.date",
      weight: 5,
    },
    {
      dataField: "origin",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSignalByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 6,
      sort: false,
      text: "report:signal.origin",
      weight: 6,
    },
    {
      dataField: "hasComments",
      key: 7,
      sort: false,
      text: "report:signal.origin",
      weight: 7,
    },
    {
      dataField: "hasFiles",
      key: 8,
      sort: false,
      text: "report:signal.hasFiles",
      weight: 8,
    },
  ];

  const defaultSiteIndustrielTableHeader = [
    {
      dataField: "name",
      formatter: siteIndustrielNameFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 0,
      sort: true,
      text: "report:siteIndustriel.name",
      weight: 0,
    },
    {
      dataField: "idCD92",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 1,
      sort: true,
      text: "report:siteIndustriel.idCD92",
      weight: 1,
    },
    {
      dataField: "siteIndustrielConformity",
      formatter: siteIndustrielConformiteFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 2,
      sort: true,
      text: "report:siteIndustriel.conformity.label",
      weight: 2,
    },
    {
      dataField: "address",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 3,
      sort: true,
      text: "report:siteIndustriel.address",
      weight: 3,
    },
    {
      dataField: "updatedAt",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 4,
      sort: true,
      text: "report:siteIndustriel.updatedAt",
      weight: 4,
    },
    {
      dataField: "city",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 5,
      sort: true,
      text: "report:siteIndustriel.city",
      weight: 5,
    },
    {
      dataField: "connectionNumber",
      formatter: siteIndustrielConnectionNumberFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 6,
      sort: true,
      text: "report:siteIndustriel.connectionNumber",
      weight: 6,
    },
    {
      dataField: "domain",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 7,
      sort: true,
      text: "report:siteIndustriel.domain",
      weight: 7,
    },
    {
      dataField: "samplingPoint",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 8,
      sort: true,
      text: "report:siteIndustriel.samplingPoint",
      weight: 8,
    },
    {
      dataField: "samplingPointCity",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 9,
      sort: true,
      text: "report:siteIndustriel.samplingPointCity",
      weight: 9,
    },
    {
      dataField: "visitDate",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 10,
      sort: true,
      text: "report:siteIndustriel.visitDate",
      weight: 10,
    },
    {
      dataField: "sampleType",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortSiteIndustrielByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 11,
      sort: true,
      text: "report:siteIndustriel.visitType",
      weight: 11,
    },
  ];

  const defaultGeoserverBVTableHeader = [
    {
      dataField: "BvName",
      formatter: geoserverBVIdFormatter,
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 1,
      sort: true,
      text: "report:geoserverBV.id",
      weight: 0,
      classes: 'font-weight-bold'
    },
    {
      dataField: "Secteur",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 2,
      sort: true,
      text: "report:geoserverBV.Secteur",
      weight: 2,
      
    },
    {
      dataField: "Collecteur",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 3,
      sort: true,
      text: "report:geoserverBV.Collecteur",
      weight: 3,
    },
    {
      dataField: "dateRange",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 4,
      sort: true,
      text: "report:geoserverBV.Periode",
      weight: 4,
    },
    {
      dataField: "sum_length_troncon",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 5,
      sort: true,
      text: "report:geoserverBV.LinéaireRéseauCuré",
      weight: 5
    },
    {
      dataField: "total_bouche_cure",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 6,
      sort: true,
      text: "report:geoserverBV.NombreBouchesCurées",
      weight: 6
    },
    {
      dataField: "sum_masse_cure_bouche_Troncon",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 7,
      sort: true,
      text: "report:geoserverBV.RéseauxBouches",
      weight: 7,
    },
    {
      dataField: "total_chambre_cure",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 8,
      sort: true,
      text: "report:geoserverBV.NombreChambresSableCurées",
      weight: 8,
    },
    {
      dataField: "sum_masse_cure_chambre",
      headerEvents: {
        onClick: (e: any, column: any) => {
          sortGeoserverBVByColumn(e, column);
        },
      },
      sortFunc: tableSortFunc,
      key: 9,
      sort: true,
      text: "report:geoserverBV.chambresSable",
      weight: 9
    }

  ];

  const translatedControlTableHeader = defaultControlTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });

  const translatedProjectTableHeader = defaultProjectTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });

  const translatedSignalTableHeader = defaultSignalTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });

  const translatedSiteIndustrielTableHeader = defaultSiteIndustrielTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });

  const translatedGeoserverBVTableHeader = defaultGeoserverBVTableHeader.map((column: IReportColumn) => {
    column.text = t(column.text);
    return column;
  });

  const [controlTableHeader, setControlTableHeader] = useState(translatedControlTableHeader);
  const [projectTableHeader, setProjectableHeader] = useState(translatedProjectTableHeader);
  const [signalTableHeader, setSignalTableHeader] = useState(translatedSignalTableHeader);
  const [siteIndustrielTableHeader, setSiteIndustrielTableHeader] = useState(translatedSiteIndustrielTableHeader);
  const [geoserverBVTableHeader, setGeoserverBVTableHeader] = useState(translatedGeoserverBVTableHeader);

  const renderReportTable = (listType: string) => {
    const switchButton = document.getElementById("switch-btn") as HTMLInputElement;
    if (switchButton) {
      if (reportTabs.list === currentTab) {
        switchButton.className = "esri-component esri-widget--button esri-widget esri-interactive hidden";
      } else {
        switchButton.className = "esri-component esri-widget--button esri-widget esri-interactive";
      }
    }
    switch (listType) {
      case "project":
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              projectTableHeader={projectTableHeader}
              signalTableHeader={signalTableHeader}
              siteIndustrielTableHeader={siteIndustrielTableHeader}
              geoserverBVTableHeader={geoserverBVTableHeader}
              projectSortField={props.projectSortField}
              sortDirection={props.projectSortDirection}
            />
          </div>
        );
      case "signal":
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              projectTableHeader={projectTableHeader}
              signalTableHeader={signalTableHeader}
              siteIndustrielTableHeader={siteIndustrielTableHeader}
              geoserverBVTableHeader={geoserverBVTableHeader}
              signalSortField={props.signalSortField}
              sortDirection={props.signalSortDirection}
            />
          </div>
        );
      case "control":
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              projectTableHeader={projectTableHeader}
              signalTableHeader={signalTableHeader}
              siteIndustrielTableHeader={siteIndustrielTableHeader}
              geoserverBVTableHeader={geoserverBVTableHeader}
              sortField={props.sortField}
              sortDirection={props.sortDirection}
            />
          </div>
        );
      case "siteIndustriel":
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              projectTableHeader={projectTableHeader}
              signalTableHeader={signalTableHeader}
              siteIndustrielTableHeader={siteIndustrielTableHeader}
              geoserverBVTableHeader={geoserverBVTableHeader}
              sortField={props.siteIndustrielSortField}
              sortDirection={props.siteIndustrielSortDirection}
            />
          </div>
        );
      case "curage":
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              projectTableHeader={projectTableHeader}
              signalTableHeader={signalTableHeader}
              siteIndustrielTableHeader={siteIndustrielTableHeader}
              geoserverBVTableHeader={geoserverBVTableHeader}
              sortField={props.geoserverBVSortField}
              sortDirection={props.geoserverBVSortDirection}
            />
          </div>
        );
      default:
        return (
          <div className={`report--body_table_container ${reportTabs.list === currentTab ? "" : "hidden"}`}>
            <NetworkTable
              controlTableHeader={controlTableHeader}
              projectTableHeader={projectTableHeader}
              signalTableHeader={signalTableHeader}
              siteIndustrielTableHeader={siteIndustrielTableHeader}
              geoserverBVTableHeader={geoserverBVTableHeader}
              sortField={props.sortField}
              sortDirection={props.sortDirection}
            />
          </div>
        );
    }
  };

  return (
    <div className={"report"} ref={elementRef}>
      <div className={"report--menu_container"}>
        <ReportFilters
          controlTableHeader={controlTableHeader}
          updateControlWeight={setControlTableHeader}
          projectTableHeader={projectTableHeader}
          updateProjectWeight={setProjectableHeader}
          signalTableHeader={signalTableHeader}
          updateSiteIndustrielWeight={setSiteIndustrielTableHeader}
          geoserverBVTableHeader={geoserverBVTableHeader}
          updateGeoserverBVWeight={setGeoserverBVTableHeader}
          siteIndustrielTableHeader={siteIndustrielTableHeader}
          updateSignalWeight={setSignalTableHeader}
          currentLocalTab={currentTab}
          changeLocalTab={setCurrentTab}
        />
      </div>
      <div className={"report--body"}>
        {"parcel" !== props.listType && "plu" !== props.listType &&
          <Tabs activeKey={props.currentTab} id={"report--menu"} onSelect={changeTab}>
            <Tab eventKey={reportTabs.list} title={t("report:list")} />
            <Tab eventKey={reportTabs.map} title={t("report:map")} />
          </Tabs>
        }
        <div className={"report--body_container"}>
          {renderReportTable(props.listType)}
          <div className={"report--body_map_container"}>
            <div className={`no-padding map  ${reportTabs.list === currentTab ? " hidden" : ""}`}>
              <div className={`display-container no-padding map`}>
                <Map
                  defaultCoordinates={reportMapPosition}
                  itemId={shownItemId}
                  showDetailObject={props.showDetail}
                  setMapPosition={props.setReportMapPosition}
                  filters={props.filters}
                  reports={props.reports}
                  sortDirection={props.sortDirection}
                  sortField={props.sortField}
                  geoserverBVs={props.geoserverBVs}
                  displayBVLayers={true}
                  displayProjectLayers={true}
                  displaySignalLayers={true}
                  displaySiteIndustrielLayers={true}
                  displayParcelLayers={true}
                  displayPluLayers={true}
                  showSmartshape={false}
                />
                {props.showSmartshape && !props.isMapDisplayedFullscreen && (
                  <div className="no-padding smartshape">
                    <Smartshape shownDetailItem={props.shownDetailItem} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData, user: IUser, search: ISearch }) => {
  return {
    currentTab: state.report.currentTab,
    filters: state.report.filters,
    geoserverBVs: state.report.geoserverBVs,
    geoserverBVsColors: state.report.geoserverBVsColors,
    isMapDisplayedFullscreen: state.search.isMapDisplayedFullscreen,
    listType: state.user.listType,
    projectSortDirection: state.report.projectSortField,
    projectSortField: state.report.projectSortField,
    geoserverBVSortDirection: state.report.geoserverBVSortDirection,
    geoserverBVSortField: state.report.geoserverBVSortField,
    reportMapPosition: state.user.reportMapPosition,
    reports: state.report.reports,
    signalSortDirection: state.report.signalSortDirection,
    signalSortField: state.report.signalSortField,
    showSmartshape: state.report.showSmartshape,
    shownDetailItem: state.report.shownDetailItem,
    sortDirection: state.report.sortDirection,
    sortField: state.report.sortField,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
