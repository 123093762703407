import { reportTabs } from "../../../pages/Report";
import { REPORT } from "../constants";
import { IReportData, ReportActionTypes } from "../types";

const defaultTableHeaderWeight = [
  {
    key: 0,
    weight: 0,
  },
  {
    key: 1,
    weight: 1,
  },
  {
    key: 2,
    weight: 2,
  },
  {
    key: 3,
    weight: 3,
  },
  {
    key: 7,
    weight: 7,
  },
  {
    key: 8,
    weight: 8,
  },
  {
    key: 9,
    weight: 9,
  },
  {
    key: 10,
    weight: 10,
  },
  {
    key: 11,
    weight: 11,
  },
  {
    key: 12,
    weight: 12,
  },
  {
    key: 13,
    weight: 13,
  },
];

const initialState: IReportData = {
  currentFavFilter: { label: "Filtre(s) Personnalisé(s)", value: 0 },
  currentProjectFavFilter: { label: "Filtre(s) Personnalisé(s)", value: 0 },
  currentSignalFavFilter: { label: "Filtre(s) Personnalisé(s)", value: 0 },
  currentSiteIndustrielFavFilter: { label: "Filtre(s) Personnalisé(s)", value: 0 },
  currentGeoserverBVFavFilter: {label: "Filtre(s) Personnalisé(s)", value: 0 },
  currentPage: 1,
  projectCurrentPage: 1,
  signalCurrentPage: 1,
  siteIndustrielCurrentPage: 1,
  parcelCurrentPage: 1,
  currentTab: reportTabs.list,
  defaultTableHeaderWeight,
  favFilters: [],
  projectFavFilters: [],
  signalFavFilters: [],
  siteIndustrielFavFilters: [],
  geoserverBVFavFilters: [],
  geoserverBVFilters: {
    id: "",
    sector: [],
    collector: [],
  },
  filters: {
    address: "",
    city: [],
    controlType: [],
    conformity: [],
    veConformity: [],
    vptConformity: [],
    conformityCheckType: [],
    connectionNumber: "",
    currentState: [],
    emOperatingSector: [],
    endDate: null,
    generatedMailEndDate: null,
    generatedMailStartDate: null,
    majorFaultType: [],
    networkOperatingSector: [],
    propertyType: [],
    startDate: null,
  },
  projectFilters: {
    address: "",
    name: "",
    status: [],
    city: [],
    permitStartDate: null,
    permitEndDate: null,
    zac: "",
  },
  signalFilters: {
    address: "",
    category: [],
    origin: [],
    status: [],
    city: [],
    startDate: null,
    endDate: null,
  },
  siteIndustrielFilters: {
    id: "",
    address: "",
    name: "",
    connectionNumber: "",
    city: [],
    domain: [],
    conformity: [],
    visitStartDate: null,
    visitEndDate: null,
    startDate: null,
    endDate: null,
  },
  parcelFilters: {
    city: [],
    type: [],
    rate: [],
  },
  geoserverBVs: [],
  geoserverBVsColors: [],
  filteredGeoserverBVs: [],
  projects: [],
  reports: [],
  signals: [],
  siteIndustriels: [],
  parcels: [],
  showDetails: false,
  showSmartshape: false,
  shownDetailItem: {},
  showProjectDetails: false,
  shownProjectDetailItem: {},
  showSignalDetails: false,
  shownSignalDetailItem: {},
  showSiteIndustrielDetails: false,
  shownSiteIndustrielDetailItem: {},
  showGeoserverBVDetails: false,
  shownGeoserverBVDetailItem: {},
  sortDirection: "desc",
  sortField: "generationReportDate",
  projectSortDirection: "desc",
  projectSortField: "pcDate",
  signalSortDirection: "desc",
  signalSortField: "date",
  siteIndustrielSortDirection: "desc",
  siteIndustrielSortField: "updatedAt",
  parcelSortDirection: "desc",
  parcelSortField: "updatedAt",
  geoserverBVSortDirection: "desc",
  geoserverBVSortField: "id",
  totalItem: 0,
  totalPage: 0,
  projectTotalItem: 0,
  projectTotalPage: 0,
  signalTotalItem: 0,
  signalTotalPage: 0,
  siteIndustrielTotalItem: 0,
  siteIndustrielTotalPage: 0,
  parcelTotalItem: 0,
  parcelTotalPage: 0,
  geoserverBVTotalItem: 0,
  geoserverBVCurrentPage: 1,
  geoserverBVTotalPage: 0,
  plu: [],
  pluFilters: {
    city: [],
    criteria: [],
  },
  pluTotalItem: 0,
  pluCurrentPage: 0,
  pluTotalPage: 0,
  pluSortDirection: "desc",
  pluSortField: "datValid",
};

const reportReducers = (state = initialState, action: ReportActionTypes): IReportData => {
  switch (action.type) {
    case REPORT.RETRIEVE_REPORTS:
      return {
        ...state,
        currentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        reports: action.payload.data,
        totalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        totalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.RETRIEVE_PROJECTS:
      return {
        ...state,
        projectCurrentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        projects: action.payload.data,
        projectTotalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        projectTotalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.RETRIEVE_SIGNALS:
      return {
        ...state,
        signalCurrentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        signals: action.payload.data,
        signalTotalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        signalTotalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.RETRIEVE_SITES:
      return {
        ...state,
        siteIndustrielCurrentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        siteIndustriels: action.payload.data,
        siteIndustrielTotalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        siteIndustrielTotalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.RETRIEVE_PARCEL:
      return {
        ...state,
        parcelCurrentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        parcels: action.payload.data,
        parcelTotalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        parcelTotalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.RETRIEVE_PLU:
      return {
        ...state,
        pluCurrentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        plu: action.payload.data,
        pluTotalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        pluTotalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.RETRIEVE_GEOSERVER_BV:
      return {
        ...state,
        geoserverBVs: action.payload.data,
        geoserverBVsColors: action.payload.colors,
      };
    case REPORT.RETRIEVE_FILTERED_GEOSERVER_BV:
      return {
        ...state,
        filteredGeoserverBVs: action.payload.data,
        geoserverBVCurrentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        geoserverBVTotalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
        geoserverBVTotalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
      };
    case REPORT.UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };
    case REPORT.UPDATE_PROJECT_FILTERS:
      return {
        ...state,
        projectFilters: action.payload.filters,
      };
    case REPORT.UPDATE_SIGNAL_FILTERS:
      return {
        ...state,
        signalFilters: action.payload.filters,
      };
    case REPORT.UPDATE_SITES_FILTERS:
      return {
        ...state,
        siteIndustrielFilters: action.payload.filters,
      };
    case REPORT.UPDATE_GEOSERVER_BV_FILTERS:
      return {
        ...state,
        geoserverBVFilters: action.payload.filters,
      };
    case REPORT.UPDATE_PARCEL_FILTERS:
      return {
        ...state,
        parcelFilters: action.payload.filters,
      };
    case REPORT.UPDATE_PLU_FILTERS:
      return {
        ...state,
        pluFilters: action.payload.filters,
      };
    case REPORT.GET_FAVORITES:
      return {
        ...state,
        favFilters: action.payload.data,
      };
    case REPORT.SET_FAVORITE:
      return {
        ...state,
        currentFavFilter: action.payload.newFav,
      };
    case REPORT.GET_PROJECT_FAVORITES:
      return {
        ...state,
        projectFavFilters: action.payload.data,
      };
    case REPORT.SET_PROJECT_FAVORITE:
      return {
        ...state,
        currentProjectFavFilter: action.payload.newFav,
      };
    case REPORT.GET_SIGNAL_FAVORITES:
      return {
        ...state,
        signalFavFilters: action.payload.data,
      };
    case REPORT.SET_SIGNAL_FAVORITE:
      return {
        ...state,
        currentSignalFavFilter: action.payload.newFav,
      };
    case REPORT.GET_SITE_INDUS_FAVORITES:
      return {
        ...state,
        siteIndustrielFavFilters: action.payload.data,
      };
    case REPORT.SET_SITE_INDUS_FAVORITE:
      return {
        ...state,
        currentSiteIndustrielFavFilter: action.payload.newFav,
      };
    case REPORT.GET_GEOSERVER_BV_FAVORITES:
      return {
        ...state,
        geoserverBVFavFilters: action.payload.data,
      };
    case REPORT.SET_GEOSERVER_BV_FAVORITE:
      return {
        ...state,
        currentGeoserverBVFavFilter: action.payload.newFav,
      };
    case REPORT.SHOW_DETAIL:
      return {
        ...state,
        showDetails: true,
        showSignalDetails: false,
        showProjectDetails: false,
        showSiteIndustrielDetails: false,
        showGeoserverBVDetails: false,
        //showSmartshape: (action.payload.connection["3dnetwork:3d_mesh_available"] || action.payload.connection["3dstrucuture:3d_ifc_available"]) ? (action.payload.connection["3dnetwork:3d_mesh_available"] || action.payload.connection["3dstrucuture:3d_ifc_available"]) : false,
        shownDetailItem: action.payload.connection,
      };
    case REPORT.HIDE_DETAIL:
      return {
        ...state,
        showDetails: false,
      };
    case REPORT.SHOW_PROJECT_DETAIL:
      return {
        ...state,
        showDetails: false,
        showSignalDetails: false,
        showSiteIndustrielDetails: false,
        showGeoserverBVDetails: false,
        showProjectDetails: true,
        shownProjectDetailItem: action.payload.project,
      };
    case REPORT.HIDE_PROJECT_DETAIL:
      return {
        ...state,
        showProjectDetails: false,
      };
    case REPORT.SHOW_SIGNAL_DETAIL:
      return {
        ...state,
        showDetails: false,
        showProjectDetails: false,
        showSiteIndustrielDetails: false,
        showGeoserverBVDetails: false,
        showSignalDetails: true,
        shownSignalDetailItem: action.payload.signal,
      };
    case REPORT.HIDE_SIGNAL_DETAIL:
      return {
        ...state,
        showSignalDetails: false,
      };
    case REPORT.SHOW_SITE_INDUS_DETAIL:
      return {
        ...state,
        showDetails: false,
        showProjectDetails: false,
        showSignalDetails: false,
        showGeoserverBVDetails: false,
        showSiteIndustrielDetails: true,
        shownSiteIndustrielDetailItem: action.payload.siteIndustriel,
      };
    case REPORT.HIDE_SITE_INDUS_DETAIL:
      return {
        ...state,
        showSiteIndustrielDetails: false,
      };
    case REPORT.SHOW_GEOSERVER_BV_DETAIL:
      return {
        ...state,
        showDetails: false,
        showProjectDetails: false,
        showSignalDetails: false,
        showSiteIndustrielDetails: false,
        showGeoserverBVDetails: true,
        shownGeoserverBVDetailItem: action.payload.geoserverBV,
      };
    case REPORT.HIDE_GEOSERVER_BV_DETAIL:
      return {
        ...state,
        showGeoserverBVDetails: false,
      };
    case REPORT.CHANGE_TAB:
      return {
        ...state,
        currentTab: action.payload.newTab,
      };
    case REPORT.SET_SORT:
      return {
        ...state,
        sortDirection: action.payload.sortDirection,
        sortField: action.payload.sortField,
      };
    case REPORT.SET_PROJECT_SORT:
      return {
        ...state,
        projectSortDirection: action.payload.projectSortDirection,
        projectSortField: action.payload.projectSortField,
      };
    case REPORT.SET_SIGNAL_SORT:
      return {
        ...state,
        signalSortDirection: action.payload.signalSortDirection,
        signalSortField: action.payload.signalSortField,
      };
    case REPORT.SET_SITE_INDUS_SORT:
      return {
        ...state,
        siteIndustrielSortDirection: action.payload.siteIndustrielSortDirection,
        siteIndustrielSortField: action.payload.siteIndustrielSortField,
      };
    case REPORT.SET_GEOSERVER_BV_SORT:
      return {
        ...state,
        geoserverBVSortDirection: action.payload.geoserverBVSortDirection,
        geoserverBVSortField: action.payload.geoserverBVSortField,
      };
    default:
      return state;
  }
};

export default reportReducers;
