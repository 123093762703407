import i18next from "i18next";
import React, { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Document, Page, pdfjs } from "react-pdf";
import { SzModal } from "react-theme-components";
import "./pdfViewer.scss";
import Toolbar from "./toolbar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPdfViewerProps {
  title: string;
  uri: string;
  visible: boolean;
  documentLength: number;
  handleClosePdfViewer: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function PdfViewer(props: IPdfViewerProps) {
  const { handleClosePdfViewer, documentLength } = props;
  const [scale, setScale] = useState(1);
  const [progressValue, setProgressValue] = useState(0);
  const [isloadProgress, setIsLoadProgress] = useState(true);
  const [visible, isVisible] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const handleClose = (e: any) => {
    isVisible(!visible);
    handleClosePdfViewer(e);
  };

  const onDocumentLoadSuccess = (document: any) => {
    setNumPages(document.numPages);
    setPageNumber(1);
    setIsLoadProgress(!isloadProgress);
  };

  const OnDocumentLoadProgress = (document: any) => {
    setProgressValue(Math.round((document.loaded / documentLength) * 100));
  };
  const changePage = (offset: number) => setPageNumber(pageNumber + offset);

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const zoomIn = () => {
    setScale(scale * 1.1);
  };
  const zoomOut = () => {
    setScale(scale / 1.1);
  };

  return (
    <SzModal title={props.title} show={visible} handleClose={handleClose}>
    <Toolbar scale={scale} onZoomIn={zoomIn} onZoomOut={zoomOut} />
      <div className="gaia-pdf">
        <Document
          className="gaia-pdf__document"
          file={props.uri}
          loading={i18next.t("loading")}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error: any) => {
            console.log(error);
          }}
          onLoadProgress={OnDocumentLoadProgress}
        >
          <Page
            className="gaia-pdf__page"
            pageNumber={pageNumber}
            height={900}
            scale={scale}
            error={i18next.t("error.loading")}
          />
        </Document>
        {isloadProgress && (
          <CircularProgressbar
            className="gaia-progress-bar"
            value={progressValue}
            text={`${progressValue}%`}
            styles={buildStyles({
              backgroundColor: "#3e98c7",
              pathColor: `rgba(62, 152, 199, ${progressValue / 100})`,
              pathTransitionDuration: 0.5,
              strokeLinecap: "butt",
              textColor: "#f88",
              textSize: "16px",
              trailColor: "#d6d6d6",
            })}
          />
        )}
        <div>
          <p>
            {i18next.t("paginationText", {
              countPage: `${numPages || "--"}`,
              currentPage: `${pageNumber || (numPages ? 1 : "--")}`,
            })}
          </p>
          <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
            {i18next.t("previous")}
          </button>
          <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
            {i18next.t("next")}
          </button>
        </div>
      </div>
    </SzModal>
  );
}
export default PdfViewer;
