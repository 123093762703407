import React from "react";
import loading from "../../assets/img/loading.svg";
import { AppState } from "../../store";
import "./loading.scss";

class Loading extends React.Component<AppState> {

    public render() {
        return (
            <>
                <div className=" background-loading">
                    <img src={loading} className="mx-auto d-block" alt=""/>
                </div>
            </>
        );
    }
}

export default Loading;
