import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import {
  calculateCityCentroid,
  citiesBoundingBoxes,
  pluCriteria,
  projectCities,
} from "../../../config/report";
import {goToHome, zoomToCoordinates} from "../../../manager/map/navigation";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IPluFilter,
  IReportData,
} from "../../../store/redux/types";
import ListHeader from "./ListHeader";

const PluListFilters = (props: any): ReactElement => {
  const { t } = useTranslation();

  const cityOptions = Object.entries(projectCities).map((item: any) => {
    return { label: item[1], value: item[0].toUpperCase() };
  });

  const criteriaOptions = Object.entries(pluCriteria).map((item: any) => {
    return { label: t(`report:plu.criteriaOptions.${item[0]}`), value: item[1] };
  });

  const [localCity, setCity] = useState<any[]>(props.pluFilters.city);
  const [localCriteria, setCriteria] = useState<any[]>(props.pluFilters.criteria);

  useEffect(() => {
    if (props.pluFilters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.pluFilters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.pluFilters.criteria) {
      const criteriaValues = criteriaOptions.filter((item: any) => {
        return props.pluFilters.criteria.includes(item.value);
      });
      setCriteria(criteriaValues);
    }
  }, [props.currentPluFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  const isFiltersEmpty = () => {
    return ((!localCity || localCity.length === 0) &&
      (!localCriteria || localCriteria.length === 0));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());

  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localCity,
    localCriteria,
  ]);

  useEffect(() => {
    if (areFiltersEmpty) {
      setTimeout(() => {
        resetAction();
      }, 1000);
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    const filters: IPluFilter = {
      city: localCity.map((item) => item.value),
      criteria: localCriteria.map((item) => item.value),
    };
    if (filters.city && filters.city.length === 1) {
      const cityValue = Object.entries(projectCities).filter((item: any) => {
        if (filters.city && filters.city.length === 1 && item[0] === filters.city[0].toLowerCase()) {
          return item[0];
        }
      });
      const coordinates = calculateCityCentroid(citiesBoundingBoxes[cityValue[0][0]].extent);
      props.setReportMapPosition([coordinates.latitude, coordinates.longitude, 10000]);
      zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
    } else if (filters.city && filters.city.length > 1) {
      props.setReportMapPosition(mapOrigin);
      zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
    }
    props.updatePluFilters(filters);
  };

  const resetAction = () => {
    setCity([]);
    setCriteria([]);
    const filters: IPluFilter = {
      city: [],
      criteria: [],
    };
    props.updatePluFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset Déconnexion des eaux pluviales filters`, category: "Report" });
  };

  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:plu.city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:plu.criteria")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary criteria"
            isSearchable
            isClearable
            isMulti
            id="criteria"
            name="criteria"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCriteria);
            }}
            options={criteriaOptions}
            value={localCriteria}
          />
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
              <div className={"color-bar blue"}></div>
              <div className={"option-text"}>
                {t("report:plu.criteriaOptions.favorable")}
              </div>
          </div>
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
              <div className={"color-bar grey"}></div>
              <div className={"option-text"}>
                {t("report:plu.criteriaOptions.defavorable")}
              </div>
          </div>
          <div className={"report-menu-tabs-list-option-container pt-15 pb-0"}>
              <div className={"color-bar black"}></div>
              <div className={"option-text"}>
                {t("report:plu.criteriaOptions.inexistant")}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    pluCurrentPage: state.report.pluCurrentPage,
    currentTab: state.report.currentTab,
    pluFilters: state.report.pluFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PluListFilters);
