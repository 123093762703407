import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzAlert, SzCheckbox, SzDatePicker, SzInput, SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import {
  calculateCityCentroid,
  cities, citiesBoundingBoxes,
  conformity,
  conformityCheckType,
  controlType,
  currentState,
  emOperatingSector,
  majorFaultType,
  networkOperatingSector,
  nonConformOther,
  propertyType,
  thematicMapGeoserverBVsColors,
  veConformity,
  vptConformity,
} from "../../../config/report";
import { defaultProperties } from "../../../manager/map/config";
import { goToHome, zoomToCoordinates } from "../../../manager/map/navigation";
import { reportTabs } from "../../../pages/Report";
import { Report as ReportService } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IReportData,
  IReportFilter,
  IUser,
} from "../../../store/redux/types";
import AutoCompleteInput from "../../Utils/AutoCompleteInput/AutoCompleteInput";
import ListHeader from "./ListHeader";

const ControlListFilters = (props: any): ReactElement => {

  const { t } = useTranslation();

  const conformityCheckTypeOptions = Object.entries(conformityCheckType).map((item: any) => {
    return { label: t(`report:conformityCheckType:${item[1]}`), value: item[1] };
  });
  const currentStateOptions = Object.entries(currentState).map((item: any) => {
    return { label: t(`report:conformityStatus:${item[1]}`), value: item[1] };
  });

  const cityOptions = Object.entries(cities).map((item: any) => {
    return { label: item[1], value: item[0] };
  });

  const controlTypeOptions = Object.entries(controlType).map((item: any, key: any) => {
    return { label: t(`report:controlType:${item[0]}`), value: item[1] };
  });

  const conformityOptions = Object.entries(conformity).map((item: any, key: any) => {
    return { label: t(`report:conformity:${item[1]}`), value: item[1] };
  });

  const veConformityOptions = Object.entries(veConformity).map((item: any, key: any) => {
    return { label: t(`report:conformity:${item[0]}`), value: item[1] };
  });

  const vptConformityOptions = Object.entries(vptConformity).map((item: any, key: any) => {
    return { label: t(`report:conformity:${item[0]}`), value: item[1] };
  });

  const propertyTypeOptions = Object.entries(propertyType).map((item: any) => {
    return { label: t(`report:propertyType:${item[0]}`), value: item[0] };
  });

  const emOperatingSectorOptions = Object.entries(emOperatingSector).map((item: any) => {
    return { label: item[1].replace(/_|;/g, " "), value: item[1] };
  });

  const networkOperatingSectorOptions = Object.entries(networkOperatingSector).map((item: any) => {
    return { label: t(`report:networkOperatingSector:${item[0]}`), value: item[1] };
  });

  const majorFaultTypeOptions = Object.entries(majorFaultType).map((item: any) => {
    return { label: t(`report:majorFaultType:${item[0]}`), value: item[0] };
  });

  const geoserverBVsColorsOptions = Object.entries(thematicMapGeoserverBVsColors).map((item: any) => {
    return { label: t(`thematicMap:geoserverBVs:${item[0]}`), value: item[1] };
  });

  const autoCompleteMinLength = 3;
  const [autocompleteOptions, setAutocompleteOptions] = useState([""]);

  const [localAddress, setAddress] = useState(props.filters.address);
  const [localConnectionNumber, setConnectionNumber] = useState(props.filters.connectionNumber);
  const [localConformity, setConformity] = useState<any[]>(props.filters.conformity);
  const [localControlType, setControlType] = useState<any[]>(props.filters.controlType);
  const [localVptConformity, setVptConformity] = useState<any[]>(props.filters.vptConformity);
  const [localVeConformity, setVeConformity] = useState<any[]>(props.filters.veConformity);
  const [localNonConformOther, setNonConformOther] = useState<boolean>();
  const [localConformityCheckType, setConformityCheckType] = useState<any[]>(props.filters.conformityCheckType);
  const [localCurrentState, setCurrentState] = useState<any[]>(props.filters.currentState);
  const [localCity, setCity] = useState<any[]>(props.filters.city);
  const [localMajorFaultType, setMajorFaultType] = useState<any[]>(props.filters.majorFaultType);
  const [localPropertyType, setPropertyType] = useState<any[]>(props.filters.propertyType);
  const [localEmOperatingSector, setEmOperatingSector] = useState<any[]>(props.filters.emOperatingSector);
  const [
    localNetworkOperatingSector,
    setNetworkOperatingSector,
  ] = useState<any[]>(props.filters.networkOperatingSector);
  const [startDate, setStartDate] = useState<Date | null>(props.filters.startDate);
  const [endDate, setEndDate] = useState<Date | null>(props.filters.endDate);
  const [errorShow, setErrorShow] = useState(false);
  const [localColors, setLocalColors] = useState<any[]>(props.geoserverBVsColors);
  const [
    generatedMailStartDate,
    setGeneratedMailStartDate,
  ] = useState<Date | null>(props.filters.generatedMailStartDate);
  const [
    generatedMailEndDate,
    setGeneratedMailEndDate,
  ] = useState<Date | null>(props.filters.generatedMailEndDate);

  const [
    conformityIsDisabled,
    setConformityIsDisabled,
  ] = useState(!(localControlType.length === 1 && localControlType[0].value === "CONFORMITE"));
  const [
    veConformityIsDisabled,
    setVeConformityIsDisabled,
  ] = useState(!(localControlType.length === 1 && localControlType[0].value === "VE"));
  const [
    vptConformityIsDisabled,
    setVptConformityIsDisabled,
  ] = useState(!(localControlType.length === 1 && localControlType[0].value === "VPT"));

  const [
    majorFaultTypeIsDisabled,
    setMajorFaultTypeIsDisabled,
  ] = useState(!(localConformity.length === 1 && localConformity[0].value === "non-conforme"));

  const isFiltersEmpty = () => {
    return ((!localCity || localCity.length === 0) &&
      (!localConformityCheckType || localConformityCheckType.length === 0) &&
      (!localConformity || localConformity.length === 0) &&
      (!localVeConformity || localVeConformity.length === 0) &&
      (!localVptConformity || localVptConformity.length === 0) &&
      (!localControlType || localControlType.length === 0) &&
      (localNonConformOther === false) &&
      (!localCurrentState || localCurrentState.length === 0) &&
      (!localMajorFaultType || localMajorFaultType.length === 0) &&
      (!localPropertyType || localPropertyType.length === 0) &&
      (!localNetworkOperatingSector || localNetworkOperatingSector.length === 0) &&
      (!localEmOperatingSector || localEmOperatingSector.length === 0) &&
      (!localConnectionNumber || localConnectionNumber === "") &&
      (!localAddress || localAddress === "") &&
      (!startDate) && (!endDate) &&
      (!generatedMailStartDate) && (!generatedMailEndDate) &&
      (!localColors || localColors.length === 0));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());
  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localCity,
    localConformityCheckType,
    localConformity,
    localNonConformOther,
    localCurrentState,
    localMajorFaultType,
    localPropertyType,
    localNetworkOperatingSector,
    localEmOperatingSector,
    localConnectionNumber,
    localAddress,
    startDate,
    endDate,
    generatedMailStartDate,
    generatedMailEndDate,
    localColors,
    localVeConformity,
    localVptConformity,
    localControlType,
  ]);

  const updateDate = (newDate: Date, startOrEnd: string) => {
    if (startDate && endDate) {
      if ("start" === startOrEnd && newDate.getTime() <= endDate.getTime()) {
        setStartDate(newDate);
      } else if (newDate.getTime() >= startDate.getTime()) {
        setEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  const updateGeneratedMailDate = (newDate: Date, startOrEnd: string) => {
    if (generatedMailStartDate && generatedMailEndDate) {
      if ("start" === startOrEnd && newDate.getTime() <= generatedMailEndDate.getTime()) {
        setGeneratedMailStartDate(newDate);
      } else if (newDate.getTime() >= generatedMailStartDate.getTime()) {
        setGeneratedMailEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setGeneratedMailStartDate(newDate);
      } else {
        setGeneratedMailEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (props.filters.address) {
      setAddress(props.filters.address);
    }
    if (props.filters.conformity) {
      const conformityValues = conformityOptions.filter((item: any) => {
        return props.filters.conformity.includes(item.value);
      });
      setConformity(conformityValues);
      setMajorFaultTypeIsDisabled(
        !(
          conformityValues.length === 1 &&
          conformityValues[0].value === "non-conforme"
        ),
      );
    }
    if (props.filters.controlType) {
      const controlTypeValues = controlTypeOptions.filter((item: any) => {
        return props.filters.controlType.includes(item.value);
      });
      setControlType(controlTypeValues);
      setConformityIsDisabled(
        !(controlTypeValues.length === 1 && controlTypeValues[0].value === "CONFORMITE"),
      );
      setVeConformityIsDisabled(
        !(controlTypeValues.length === 1 && controlTypeValues[0].value === "VE"),
      );
      setVptConformityIsDisabled(
        !(controlTypeValues.length === 1 && controlTypeValues[0].value === "VPT"),
      );
    }
    if (props.filters.veConformity) {
      const veConformityValues = veConformityOptions.filter((item: any) => {
        return props.filters.veConformity.includes(item.value);
      });
      setVeConformity(veConformityValues);
    }
    if (props.filters.vptConformity) {
      const vptConformityValues = vptConformityOptions.filter((item: any) => {
        return props.filters.vptConformity.includes(item.value);
      });
      setVptConformity(vptConformityValues);
    }
    if (props.filters.conformityCheckType) {
      const conformityCheckTypeValues = conformityCheckTypeOptions.filter((item: any) => {
        return props.filters.conformityCheckType.includes(item.value);
      });
      setConformityCheckType(conformityCheckTypeValues);
    }
    if (props.filters.currentState) {
      const currentStateValues = currentStateOptions.filter((item: any) => {
        return props.filters.currentState.includes(item.value);
      });
      setCurrentState(currentStateValues);
    }
    if (props.filters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.filters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.filters.majorFaultType) {
      const majorFaultTypeValues = majorFaultTypeOptions.filter((item: any) => {
        return props.filters.majorFaultType.includes(item.value);
      });
      setMajorFaultType(majorFaultTypeValues);
      setNonConformOther(props.filters.majorFaultType.includes("none") ? true : false);
    }
    if (props.filters.propertyType) {
      const propertyTypeValues = propertyTypeOptions.filter((item: any) => {
        return props.filters.propertyType.includes(item.value);
      });
      setPropertyType(propertyTypeValues);
    }

    if (props.filters.emOperatingSector) {
      const emOperatingSectorValues = emOperatingSectorOptions.filter((item: any) => {
        return props.filters.emOperatingSector.includes(item.value);
      });
      setEmOperatingSector(emOperatingSectorValues);
    }

    if (props.filters.networkOperatingSector) {
      const networkOperatingSectorValues = networkOperatingSectorOptions.filter((item: any) => {
        return props.filters.networkOperatingSector.includes(item.value);
      });
      setNetworkOperatingSector(networkOperatingSectorValues);
    }

    if (props.filters.startDate) {
      if (typeof props.filters.startDate !== "object") {
        const startingDate = new Date(props.filters.startDate);
        setStartDate(startingDate);
      }
    }
    if (props.filters.endDate) {
      if (typeof props.filters.endDate !== "object") {
        const endingDate = new Date(props.filters.endDate);
        setEndDate(endingDate);
      }
    }

    if (props.filters.generatedMailStartDate) {
      if (typeof props.filters.generatedMailStartDate !== "object") {
        const startingDate = new Date(props.filters.generatedMailStartDate);
        setGeneratedMailStartDate(startingDate);
      }
    }
    if (props.filters.generatedMailEndDate) {
      if (typeof props.filters.generatedMailEndDate !== "object") {
        const endingDate = new Date(props.filters.generatedMailEndDate);
        setGeneratedMailEndDate(endingDate);
      }
    }
    if (props.geoserverBVsColors) {
      const geoserverBVsColorsValues = geoserverBVsColorsOptions.filter((item: any) => {
        return props.geoserverBVsColors.includes(item.value);
      });
      setLocalColors(geoserverBVsColorsValues);
    }
  }, [props.currentFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  useEffect(() => {
    if (areFiltersEmpty) {
      resetAction();
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    if (startDate && endDate && endDate < startDate) {
      setErrorShow(true);
    } else if (generatedMailStartDate && generatedMailEndDate && generatedMailEndDate < generatedMailStartDate) {
      setErrorShow(true);
    } else {
      goToHome();
      const filters: IReportFilter = {
        address: localAddress,
        city: localCity.map((item) => item.value),
        controlType: localControlType.map((item) => item.value),
        conformity: localConformity.map((item) => item.value),
        veConformity: localVeConformity.map((item) => item.value),
        vptConformity: localVptConformity.map((item) => item.value),
        conformityCheckType: localConformityCheckType.map((item) => item.value),
        connectionNumber: localConnectionNumber,
        currentState: localCurrentState.map((item) => item.value),
        endDate,
        generatedMailEndDate,
        generatedMailStartDate,
        majorFaultType: localMajorFaultType.map((item) => item.value)
          .concat(localNonConformOther ? ["none"] : []),
        propertyType: localPropertyType.map((item) => item.value),
        emOperatingSector: localEmOperatingSector.map((item) => item.value),
        networkOperatingSector: localNetworkOperatingSector.map((item) => item.value),
        startDate,
      };
      let colors = [];
      if (localColors && localColors.length === 1) {
        colors = localColors.map((item) => item.value);
      }
      if (filters.city && filters.city.length === 1) {
        const coordinates = calculateCityCentroid(citiesBoundingBoxes[filters.city[0]].extent);
        props.setReportMapPosition([coordinates.latitude, coordinates.longitude, 10000]);
        if (props.currentTab === reportTabs.map) {
          zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
        }
      } else if (filters.city && filters.city.length > 1) {
        props.setReportMapPosition(mapOrigin);
        if (props.currentTab === reportTabs.map) {
          zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
        }
      } else if (filters.address && (
        (!filters.city || filters.city.length === 0) &&
        (!filters.veConformity || filters.veConformity.length === 0) &&
        (!filters.vptConformity || filters.vptConformity.length === 0) &&
        (!filters.controlType || filters.controlType.length === 0) &&
        (!filters.conformityCheckType || filters.conformityCheckType.length === 0) &&
        (!filters.conformity || filters.conformity.length === 0) &&
        (!filters.currentState || filters.currentState.length === 0) &&
        (!filters.majorFaultType || filters.majorFaultType.length === 0) &&
        (!filters.propertyType || filters.propertyType.length === 0) &&
        (!filters.emOperatingSector || filters.emOperatingSector.length === 0) &&
        (!filters.networkOperatingSector || filters.networkOperatingSector.length === 0) &&
        (!filters.connectionNumber || filters.connectionNumber === "")
      )
      ) {
        SearchService.getAddressCoordinates(filters.address).then((res: any) => {
          if (res.coordinates && res.cityCode.match(/92/g)) {
            props.setReportMapPosition([res.coordinates[1], res.coordinates[0], defaultProperties.scale]);
            if (props.currentTab === reportTabs.map) {
              zoomToCoordinates(res.coordinates[1], res.coordinates[0]);
            }
          } else {
            props.setReportMapPosition(mapOrigin);
            if (props.currentTab === reportTabs.map) {
              zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
            }
          }

        });
      }
      props.updateFilters(filters);
      if (colors) {
        props.getGeoserverBV(colors);
      }
    }
  };

  const resetAction = () => {
    setAddress("");
    setControlType([]);
    setConformity([]);
    setVeConformity([]);
    setVptConformity([]);
    setNonConformOther(false);
    setConformityCheckType([]);
    setConnectionNumber("");
    setCurrentState([]);
    setCity([]);
    setMajorFaultType([]);
    setPropertyType([]);
    setStartDate(null);
    setEndDate(null);
    setGeneratedMailStartDate(null);
    setGeneratedMailEndDate(null);
    setLocalColors([]);
    setNetworkOperatingSector([]);
    setEmOperatingSector([]);
    setConformityIsDisabled(true);
    setVeConformityIsDisabled(true);
    setVptConformityIsDisabled(true);
    setMajorFaultTypeIsDisabled(true);
    const filters: IReportFilter = {
      address: "",
      city: [],
      controlType: [],
      conformity: [],
      veConformity: [],
      vptConformity: [],
      conformityCheckType: [],
      connectionNumber: "",
      currentState: [],
      emOperatingSector: [],
      endDate: null,
      generatedMailEndDate: null,
      generatedMailStartDate: null,
      majorFaultType: [],
      networkOperatingSector: [],
      propertyType: [],
      startDate: null,
    };
    props.getGeoserverBV();
    props.updateFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset filters`, category: "Report" });
  };

  const autocompleteAddress = (address: string) => {
    return ReportService.getReportAddressAutocomplete(address)
      .then((data: Array<{ address: string }>) => {
        const options = data.map((item: { address: string }) => {
          return item.address;
        });
        setAutocompleteOptions(options);
      });
  };

  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          {errorShow &&
            <SzAlert type="danger">the end date must be set and greater than the start date</SzAlert>
          }
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:network.number")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary connectionNumber"
            placeholder={""}
            value={localConnectionNumber}
            onChange={(event: { target: { value: string } }) => setConnectionNumber(event.target.value)}
            handleClear={() => setConnectionNumber("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:network.generationReportDate")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            label={"date"}
            endDate={generatedMailEndDate}
            endMinDate={null}
            startDate={generatedMailStartDate}
            startMinDate={null}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateGeneratedMailDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateGeneratedMailDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:network.conformityCheckDate")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            label={"date"}
            endDate={endDate}
            endMinDate={null}
            startDate={startDate}
            startMinDate={null}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:address")}</span>
          </div>
          <AutoCompleteInput
            autoCompleteOptions={autocompleteOptions}
            className={"address"}
            debounce={500}
            handler={setAddress}
            placeholder={""}
            minLength={autoCompleteMinLength}
            searchHandler={autocompleteAddress}
            value={localAddress}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:controlType.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary controlType"
            isSearchable
            isClearable
            isMulti
            id="controlType"
            name="controlType"
            placeholder={""}
            onChange={(values: any) => {
                if (values && values.length > 1) {
                  setConformityIsDisabled(true);
                  setConformity([]);
                  setMajorFaultType([]);
                  setNonConformOther(false);
                  setVeConformityIsDisabled(true);
                  setVeConformity([]);
                  setVptConformityIsDisabled(true);
                  setVptConformity([]);
                }
                if (values && values.length === 1 && values[0].value === "CONFORMITE") {
                  setConformityIsDisabled(false);
                } else {
                  setConformityIsDisabled(true);
                  setConformity([]);
                  setMajorFaultType([]);
                  setNonConformOther(false);
                }
                if (values && values.length === 1 && values[0].value === "VE") {
                  setVeConformityIsDisabled(false);
                } else {
                  setVeConformityIsDisabled(true);
                  setVeConformity([]);
                }
                if (values && values.length === 1 && values[0].value === "VPT") {
                setVptConformityIsDisabled(false);
              } else {
                setVptConformityIsDisabled(true);
                setVptConformity([]);
              }
                updateValues(values, setControlType);
            }}
            options={controlTypeOptions}
            value={localControlType}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span
              className={`report--menu--tabs_list--filter-label ${conformityIsDisabled ? "title-disabled" : ""}`}
            >
              {t("report:conformity.label")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary conformity"
            isSearchable
            isClearable
            isMulti
            isDisabled={conformityIsDisabled}
            id="conformity"
            name="conformity"
            placeholder={""}
            onChange={(values: any) => {
              if (values && values.length === 1 && values[0].value === "non-conforme") {
                setMajorFaultTypeIsDisabled(false);
              } else {
                setMajorFaultTypeIsDisabled(true);
                setMajorFaultType([]);
                setNonConformOther(false);
              }
              updateValues(values, setConformity);
            }}
            options={conformityOptions}
            value={localConformity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span
              className={`report--menu--tabs_list--filter-label ${majorFaultTypeIsDisabled ? "title-disabled" : ""}`}
            >
              {t("report:majorFaultType.label")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary majorFaultType"
            isSearchable
            isClearable
            isMulti
            isDisabled={majorFaultTypeIsDisabled}
            id="majorFaultType"
            name="majorFaultType"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setMajorFaultType);
            }}
            options={majorFaultTypeOptions}
            value={localMajorFaultType}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <SzCheckbox
            label={t("report:non-conforme-other.label")}
            type="checkbox"
            disabled={majorFaultTypeIsDisabled}
            checked={localNonConformOther}
            name={t("report:non-conforme-other.label")}
            onChange={() => {
              setNonConformOther(!localNonConformOther);
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span
              className={`report--menu--tabs_list--filter-label ${veConformityIsDisabled ? "title-disabled" : ""}`}
            >
              {t("report:veConformity.label")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary veConformity"
            isSearchable
            isClearable
            isMulti
            isDisabled={veConformityIsDisabled}
            id="veConformity"
            name="veConformity"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setVeConformity);
            }}
            options={veConformityOptions}
            value={localVeConformity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span
              className={`report--menu--tabs_list--filter-label ${vptConformityIsDisabled ? "title-disabled" : ""}`}
            >
              {t("report:vptConformity.label")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary vptConformity"
            isSearchable
            isClearable
            isMulti
            isDisabled={vptConformityIsDisabled}
            id="vptConformity"
            name="vptConformity"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setVptConformity);
            }}
            options={vptConformityOptions}
            value={localVptConformity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:visitType")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary visitType"
            id="visitType"
            isClearable
            isMulti
            isSearchable
            name="visitType"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setConformityCheckType);
            }}
            options={conformityCheckTypeOptions}
            value={localConformityCheckType}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:propertyType.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary propertyType"
            isSearchable
            isClearable
            isMulti
            id="propertyType"
            name="propertyType"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setPropertyType);
            }}
            options={propertyTypeOptions}
            value={localPropertyType}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={`report--menu--tabs_list--filter-label ${props.currentTab === reportTabs.list ? "title-disabled" : ""}`}>
              {t("thematicMap:bvConformity")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary geoserverBV"
            isSearchable
            isClearable
            isMulti
            isDisabled={props.currentTab === reportTabs.list}
            id="geoserverBV"
            name="geoserverBV"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setLocalColors);
            }}
            options={geoserverBVsColorsOptions}
            value={localColors}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:emOperatingSector.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary emOperatingSector"
            isSearchable
            isClearable
            isMulti
            id="emOperatingSector"
            name="emOperatingSector"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setEmOperatingSector);
            }}
            options={emOperatingSectorOptions}
            value={localEmOperatingSector}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:networkOperatingSector.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary networkOperatingSector"
            isSearchable
            isClearable
            isMulti
            id="networkOperatingSector"
            name="networkOperatingSector"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setNetworkOperatingSector);
            }}
            options={networkOperatingSectorOptions}
            value={localNetworkOperatingSector}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    currentFavFilter: state.report.currentFavFilter,
    currentPage: state.report.currentPage,
    currentTab: state.report.currentTab,
    filters: state.report.filters,
    listType: state.user.listType,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlListFilters);
