import { History } from "history";
import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzHeader, SzInput, SzTable } from "react-theme-components";
import { bindActionCreators } from "redux";
import { menuItems } from "../../components/Container";
import Pagination from "../../components/Utils/Pagination/Pagination";
import { SearchActions } from "../../store/redux/actions";
import { IFilter, ISearch } from "../../store/redux/types";
import "./Historic.scss";

interface IHistoric {
  getHistory: (itemsPerPage?: number, currentPage?: number) => void;
  getSearch: (search: ISearch, filters?: IFilter[], cancelOption?: boolean) => any;
  getSigObjectByIdent: (ident: string) => any;
  history: History;
  search: ISearch;
  searchHistory: Array<{ keywords: string, date: Date }>;
}

const Historic = (props: IHistoric) => {

  const { t } = useTranslation();
  const nbResults = 200;
  const itemPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setData] = useState(props.searchHistory);
  const [maxPage, setMaxPage] = useState(Math.ceil(props.searchHistory.length / itemPerPage) || 1 as number);
  const [searchKeywords, setSearchKeywords] = useState("");

  useEffect(() => {
    props.getHistory(nbResults, 1);
  }, []);

  const customDateRender = (value: Date): ReactElement => {
    return <>{value.toLocaleDateString()}</>;
  };

  useEffect(() => {
    let filteredData = props.searchHistory;

    if (searchKeywords !== "") {
      filteredData = props.searchHistory.filter((item: { keywords: string }) => {
        return item.keywords.toLowerCase().indexOf(searchKeywords.toLowerCase()) !== -1;
      });
    }
    setData(filteredData.slice(itemPerPage * currentPage - itemPerPage, itemPerPage * currentPage));
    setMaxPage(Math.ceil(filteredData.length / itemPerPage) || 1);
  }, [currentPage, props.searchHistory, searchKeywords]);

  const isNumeric = (text: any): boolean => {
    return (!isNaN(text) && !isNaN(parseFloat(text)));
  };

  const isIdent = (text: any): boolean => {
    return (!isNumeric(text) && text.match(/^[0-9 -]*$/));
  };

  const rowEvents = {
    onClick: (e: never, row: { keywords: string }) => {
      ReactGA.event({
        action: `Search from History Page`,
        category: "History",
        label: `: "recherche: "${row.keywords}", terme: ${searchKeywords}", page: "${currentPage}"`,
      });
      const search = props.search;
      search.userInput = row.keywords;
      if (isIdent(row.keywords)) {
        props.getSigObjectByIdent(row.keywords);
      } else {
        props.getSearch(search, [], false);
      }
      props.history.push("/");
    },
  };

  const header = [
    {
      dataField: "keywords",
      sort: true,
      text: t("searchKeywords"),
    },
    {
      dataField: "date",
      formatter: customDateRender,
      sort: true,
      text: t("searchDate"),
    },
  ];

  return (
    <div className={"history"}>
      <SzHeader className="gaia-header d-flex align-items-stretch">
        <h1 className="gaia-header__brand-title px-3">{t(menuItems.history)}</h1>
      </SzHeader>
      <div className={"history-container"}>
      <SzInput
        className={"history-search"}
        placeholder={t("search")}
        value={searchKeywords}
        onChange={(event: any) => { setSearchKeywords(event.target.value); }}
        handleClear={() => setSearchKeywords("")}
      />
      <SzTable
        className={"history-table"}
        columns={header}
        data={tableData}
        keyField={"id"}
        rowEvents={rowEvents}
      />
      <Pagination totalPage={maxPage} currentPage={currentPage} handler={setCurrentPage} />
      </div></div>);

};

const mapStateToProps = (state: { search: ISearch }) => {
  return {
    search: state.search,
    searchHistory: state.search.history.searches,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...SearchActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Historic);
