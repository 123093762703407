import { config } from "../../config";
import { mapOrigin } from "../../store/redux/reducers/user";
import { defaultProperties } from "./config";
import { loadedExtent, loadedMapView, loadedPoint, loadedSceneView } from "./loader";

export interface IExtentObject {
  [key: string]: number | string;
}

export const zoomToCoordinates = (lat: number, lon: number, scale?: number) => {
  const opts = {
    duration: defaultProperties.duration, // Duration of animation will be 1 seconds
  };
  if (loadedPoint) {
    const point = new loadedPoint({
      spatialReference: { wkid: 4326 },
      x: lon,
      y: lat,
    });
    loadedMapView.goTo(
      { target: point, scale: scale ? scale : defaultProperties.scale },
      opts,
    );
    if (loadedSceneView && config.MAP3D_ENABLED === "true") {
      loadedSceneView.goTo(
        { target: point, tilt: 0, scale: scale ? scale : defaultProperties.scale },
        opts,
      );
    }
  }
};

export const zoomToExtent = (points: number[][], scale?: number) => {
  const opts = {
    duration: defaultProperties.duration, // Duration of animation will be 1 seconds
  };
  const extent = new loadedExtent({
    spatialReference: { wkid: 4326 },
    xmax: points[1][1] > points[0][1] ? points[1][1] : points[0][1],
    xmin: points[0][1] < points[1][1] ? points[0][1] : points[1][1],
    ymax: points[1][0] > points[0][0] ? points[1][0] : points[0][0],
    ymin: points[0][0] < points[1][0] ? points[0][0] : points[1][0],
  });
  loadedMapView.goTo(
    { target: extent, scale: scale ? scale : defaultProperties.scale },
    opts,
  );
  if (loadedSceneView && config.MAP3D_ENABLED === "true") {
    loadedSceneView.goTo(
      { target: extent, tilt: 0, scale: scale ? scale : defaultProperties.scale },
      opts,
    );
  }
};

export const zoomToAddressExtent = (extentObject: IExtentObject, scale?: number) => {
  const opts = {
    duration: defaultProperties.duration, // Duration of animation will be 1 seconds
  };
  const extent = new loadedExtent({
    spatialReference: { wkid: 4326 },
    xmax: extentObject.xmax,
    xmin: extentObject.xmin,
    ymax: extentObject.ymax,
    ymin: extentObject.ymin,
  });
  loadedMapView.goTo(
    { target: extent, scale: scale ? scale : defaultProperties.scale },
    opts,
  );
  if (loadedSceneView && config.MAP3D_ENABLED === "true") {
    loadedSceneView.goTo(
      { target: extent, tilt: 0, scale: scale ? scale : defaultProperties.scale },
      opts,
    );
  }
};

export const goToHome = () => {
  if (loadedMapView && loadedMapView.map && loadedMapView.map.initialViewProperties && loadedMapView.map.initialViewProperties.viewpoint) {
    setTimeout(() => {
      loadedMapView.goTo(
        {
          scale: loadedMapView.map.initialViewProperties.viewpoint?.scale,
          target:
            loadedMapView.map.initialViewProperties.viewpoint?.targetGeometry,
        },
        { duration: defaultProperties.duration },
      );
    }, 1000);
  }

  if (loadedSceneView && config.MAP3D_ENABLED === "true") {
    const point = new loadedPoint({
      spatialReference: { wkid: 4326 },
      x: mapOrigin[1],
      y: mapOrigin[0],
    });

    setTimeout(() => {
      loadedSceneView.goTo({ target: point, scale: mapOrigin[2] });
    }, 1000);
  }
};
