import { Formik } from "formik";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzHeader, SzInput } from "react-theme-components";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import { history } from "../../store";
import { UserActions } from "../../store/redux/actions";
import PdfViewer from "../Utils/pdfViewer";
import "./Profil.scss";

interface IProfilProps {
    user: any;
}

function Profil(props: IProfilProps) {
    const { t } = useTranslation();

    const [titlePDF, setTitlePDF] = useState("");
    const [uriPDF, setUriPDF] = useState("");
    const [documentLength, setDocumentLength] = useState(0);
    const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);

    const showPDFViewer = () => {
        setTitlePDF("Aide et documentation_Manuel utilisateur.pdf");
        setUriPDF(getDownloadUrl(props.user.userManualFileDownload));
        setPDFViewerIsActive(!PDFViewerIsActive);
        ReactGA.event({ action: `Pdf viewer: Aide et documentation_Manuel utilisateur.pdf`, category: "PDF" });
    };

    const getDownloadUrl = (downloadUrl: string) => {
        const token = localStorage.getItem("tokenGaiaVision");
        return downloadUrl + "?token=" + token;
    };

    const schema = Yup.object().shape({
        firstName: Yup.string()
            .min(2, "Le champ prénom doit être rempli.")
            .required("Le champ prénom doit être rempli."),
        lastName: Yup.string()
            .min(2, "Le champ nom doit être rempli.")
            .required("Le champ nom doit être rempli."),
    });

    return (
        <>
            {PDFViewerIsActive && (
                <PdfViewer
                    title={titlePDF}
                    uri={uriPDF}
                    visible={PDFViewerIsActive}
                    handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
                    documentLength={documentLength ? documentLength : 0}
                />
            )}
            <div className={"profil"}>
                <SzHeader className="gaia-header d-flex align-items-stretch">
                    <h1 className="gaia-header__brand-title px-3">{t("profil")}</h1>
                </SzHeader>
                <div className={"profil-container"}>
                    <div className={"profil-info-container"}>
                        <div className={"user-info"}>
                            <div className="name">{props.user.firstName} {props.user.lastName}</div>
                            <div className="user-email">{props.user.email}</div>
                        </div>
                        <div className={"buttons"}>
                            <SzButton
                                className="col-9 col-md-5 ml-1"
                                onClick={() => {
                                    history.push("/history");
                                }}
                            >
                                {t("account:activity")}
                            </SzButton>
                            <SzButton
                                className="col-9 col-md-5 ml-1"
                                onClick={() => showPDFViewer()}
                            >
                                {t("account:doc")}
                            </SzButton>
                            <a
                                href={getDownloadUrl(props.user.userManualFileDownload)}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 100, hide: 200 }}
                                    overlay={
                                        <Tooltip id="tooltip">
                                            {t("download_document")}
                                        </Tooltip>
                                    }
                                >
                                    <i className="item-links-icon material-icons" >file_download</i>
                                </OverlayTrigger>
                            </a>
                        </div>
                    </div>
                    <div className={"profil-form-container"}>
                        <Tabs
                            id="account-tab"
                            activeKey="form"
                        >
                            <Tab eventKey="form" title={t("account:userIdentity")}>
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(false);
                                    }}
                                    initialValues={
                                        {
                                            firstName: props.user.firstName,
                                            lastName: props.user.lastName,
                                        }
                                    }
                                    validateOnBlur
                                    validateOnChange={false}
                                >
                                    {({
                                        handleSubmit,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <SzInput
                                                key="firstName"
                                                label={t("account:form:firstName")}
                                                name="firstName"
                                                value={props.user.firstName}
                                                readOnly
                                                type="text"
                                            />
                                            <SzInput
                                                key="lastName"
                                                label={t("account:form:lastName")}
                                                name="lastName"
                                                value={props.user.lastName}
                                                readOnly
                                                type="text"
                                            />
                                            <SzInput
                                                key="function"
                                                label={t("account:form:function")}
                                                name="function"
                                                readOnly
                                                value={props.user.employeeType}
                                                type="text"
                                            />
                                            <SzInput
                                                key="phone"
                                                label={t("account:form:phone")}
                                                name="phone"
                                                value={props.user.homePhone}
                                                readOnly
                                                type="text"
                                            />
                                            <SzInput
                                                key="mobile"
                                                label={t("account:form:mobile")}
                                                name="mobile"
                                                value={props.user.mobile}
                                                readOnly
                                                type="text"
                                            />
                                        </form>
                                    )}
                                </Formik>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
