import { BREADCRUMB } from "../constants";
import { BreadcrumbActionTypes, IBreadcrumb } from "../types";

const initialState: IBreadcrumb = {
  categoryName: "",
  clickable: true,
  clickedItem: "",
  fromMap: false,
  isOnItemDetails: false,
  searchKeywords: "",
};

const breadcrumbReducers = (state = initialState, action: BreadcrumbActionTypes): IBreadcrumb => {
  switch (action.type) {
    case BREADCRUMB.SET:
      return { ...action.payload };
    case BREADCRUMB.SET_SEARCH_KEYWORDS:
      return { ...state, searchKeywords: action.payload };
    case BREADCRUMB.BACK_TO_SEARCH:
      return {
        ...initialState,
        categoryName: action.category,
        clickedItem: state.clickedItem,
        fromMap: state.fromMap,
        searchKeywords: state.searchKeywords,
      };
    default:
      return state;
  }
};

export default breadcrumbReducers;
