import { API_ROUTES } from "../config/routes";
import Request from "./Request";

export class Ppv3d {
    public static getEquipement(equipementId: string) {
        const url = `${API_ROUTES.EQUIPEMENT_DETAIL}/${equipementId}`;
        return Request.get(url, true)
            .then((res: any) => res.data);
    }
}
