import React, { useEffect } from 'react';
import {config} from "./config";

// Déclaration de module global pour _paq
declare global {
    interface Window {
        _paq: any[]; // Définissez le type de _paq en fonction de votre utilisation réelle
    }
}
const MATOMO_URL_BASE = config.MATOMO_URL_BASE;
const MATOMO_SITE_ID = config.MATOMO_SITE_ID;
const MatomoTracker: React.FC = () => {
    useEffect(() => {
        const initializeMatomo = () => {
            window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            window._paq.push(['trackPageView']);
            window._paq.push(['enableLinkTracking']);
            (function() {
                var u = MATOMO_URL_BASE;
                window._paq.push(['setTrackerUrl', u + 'matomo.php']);
                window._paq.push(['setSiteId', MATOMO_SITE_ID]);
                var d = document,
                    g = d.createElement('script'),
                    s = d.getElementsByTagName('script')[0];
                g.async = true;
                g.src = u + 'matomo.js';
                if (s && s.parentNode) {
                    s.parentNode.insertBefore(g, s);
                } else {
                    console.error('Unable to find script element to insert Matomo script.');
                }
            })();
        };

        initializeMatomo();
    }, []);

    return null; // No need to return anything as this component is only responsible for side effects
};


export default MatomoTracker;
