import React, {ReactElement, useState} from "react";
import {Accordion, Card, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import "../detail.scss";
import {SzIcon} from "react-theme-components";
import DataNotReady from "../../Utils/messages/dataNotReady";



const GeoserverBVSynthesis = (props: { shownItem: any }): ReactElement => {
    const [statPatrimoineOpen, setStatPatrimoineOpen] = useState<boolean>(true);
    const [statMasseExtractedOpen, setStatMasseExtractedOpen] = useState<boolean>(true);
    const [mostRecentYearOpen, setMostRecentYearOpen] = useState('0');

    const AllSigObjectGroupedPeryear = props.shownItem.AllSigObjectGroupedPeryear;
    const years = Object.keys(AllSigObjectGroupedPeryear);
    years.sort((a, b) => parseInt(b) - parseInt(a));
    const mostRecentYear = years[0];
    const mostRecentYearObject = AllSigObjectGroupedPeryear[mostRecentYear];
    const {t} = useTranslation();

    const [openYearIndex, setOpenYearIndex] = useState<number>(0); // Index de l'année ouverte, initialement 0
    years.sort((a, b) => parseInt(b) - parseInt(a));
    return (
        <>
            <Table size="sm">
                <tbody>
                <tr>
                    <td className="gaia-td-align-right cell-width">
                        {t("report:geoserverBV.DetailPerBV.Collecteur")} :
                    </td>
                    <td className="gaia-td-align-left cell-width">
                        {props.shownItem.Collecteur}
                    </td>
                </tr>
                <tr>
                    <td className="gaia-td-align-right cell-width">
                        {t("report:geoserverBV.DetailPerBV.rejectName")} :
                    </td>
                    <td className="gaia-td-align-left cell-width">
                        {props.shownItem.Nom_Rejet}
                    </td>
                </tr>
                <tr>
                    <td className="gaia-td-align-right cell-width">
                        {t("report:geoserverBV.DetailPerBV.aireBV")} :
                    </td>
                    <td className="gaia-td-align-left cell-width">
                        {props.shownItem.Aire}
                    </td>
                </tr>
                </tbody>
            </Table>

            <Accordion className="container-bordered" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1"
                                      onClick={() => setStatPatrimoineOpen(!statPatrimoineOpen)}>
                        {t("report:geoserverBV.DetailPerBV.StatistiquesPatrimoineCuré")}
                        {statPatrimoineOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1"/> :
                            <SzIcon variant="bold" icon="arrow-down-1"/>
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            {years.map((year, index) => (
                                <Accordion key={year} className="container-bordered" defaultActiveKey={`${index === openYearIndex ? '1' : ''}`}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setOpenYearIndex(index)}>
                                            {year}
                                            {statMasseExtractedOpen ?
                                                <SzIcon variant="bold" icon="arrow-up-1" /> :
                                                <SzIcon variant="bold" icon="arrow-down-1" />
                                            }
                                        </Accordion.Toggle>
                                        {AllSigObjectGroupedPeryear.hasOwnProperty(year) && (
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <Table size="sm" className="table-overflow">
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>{'Dans le Bassin Versant'}</th>
                                                            <th>{'Curé(es)'} </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td className="gaia-td-align-left">
                                                                {t("report:geoserverBV.DetailPerBV.NBchambresSable")}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {props.shownItem.nbre_chambre_into_BV ? props.shownItem.nbre_chambre_into_BV.toString() : '-'}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {AllSigObjectGroupedPeryear[year]['count_sig_object_chambre'] ? AllSigObjectGroupedPeryear[year]['count_sig_object_chambre'].toString() : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="gaia-td-align-left">
                                                                {t("report:geoserverBV.DetailPerBV.LinéaireRéseauCuré")}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {props.shownItem.Lineaire_reseau_into_bv ? props.shownItem.Lineaire_reseau_into_bv.toString() : '-'}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {AllSigObjectGroupedPeryear[year]['sum_troncon_longcalc_troncon'] ? AllSigObjectGroupedPeryear[year]['sum_troncon_longcalc_troncon'].toString() : '-'}

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="gaia-td-align-left">
                                                                {t("report:geoserverBV.DetailPerBV.NBBouches")}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {props.shownItem.nbre_bouchee_into_BV ? props.shownItem.nbre_bouchee_into_BV.toString() : '-'}
                                                            </td>
                                                            <td className="gaia-td-align-left">
                                                                {AllSigObjectGroupedPeryear[year]['count_sig_object_bouche'] ? AllSigObjectGroupedPeryear[year]['count_sig_object_bouche'].toString() : '-'}

                                                            </td>
                                                        </tr>

                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        )}
                                    </Card>
                                </Accordion>
                            ))}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Accordion className="container-bordered" defaultActiveKey="1">
                <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setStatMasseExtractedOpen(!statMasseExtractedOpen)}>
                        {t("report:geoserverBV.DetailPerBV.StatistiquesMassesExtraites")}
                        {statMasseExtractedOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1"/> :
                            <SzIcon variant="bold" icon="arrow-down-1"/>
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            {years.map((year, index) => (
                                <Accordion key={year} className="container-bordered" defaultActiveKey={`${index === openYearIndex ? '1' : ''}`}>
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setOpenYearIndex(index)}>
                                            {year}
                                            {statMasseExtractedOpen ?
                                                <SzIcon variant="bold" icon="arrow-up-1" /> :
                                                <SzIcon variant="bold" icon="arrow-down-1" />
                                            }
                                        </Accordion.Toggle>
                                        {AllSigObjectGroupedPeryear.hasOwnProperty(year) && (
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <Table size="sm">
                                                        <tbody>
                                                        <tr>
                                                            <td className="gaia-td-align-right cell-width">
                                                                {t("report:geoserverBV.DetailPerBV.chambresSable")} :
                                                            </td>
                                                            <td className="gaia-td-align-left cell-width">
                                                                {AllSigObjectGroupedPeryear[year]['sum_masse_chambre'] ? AllSigObjectGroupedPeryear[year]['sum_masse_chambre'] : '-'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="gaia-td-align-right cell-width">
                                                                {t("report:geoserverBV.DetailPerBV.RéseauxBouches")} :
                                                            </td>
                                                            <td className="gaia-td-align-left cell-width">
                                                                {((AllSigObjectGroupedPeryear[year]['sum_masse_bouche'] !== undefined && AllSigObjectGroupedPeryear[year]['sum_masse_troncon'] !== undefined) && (AllSigObjectGroupedPeryear[year]['sum_masse_bouche'] !== 0 || AllSigObjectGroupedPeryear[year]['sum_masse_troncon'] !== 0))
                                                                    ? (AllSigObjectGroupedPeryear[year]['sum_masse_bouche'] >= AllSigObjectGroupedPeryear[year]['sum_masse_troncon']
                                                                        ? AllSigObjectGroupedPeryear[year]['sum_masse_bouche']
                                                                        : AllSigObjectGroupedPeryear[year]['sum_masse_troncon'])
                                                                    : '-'}

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        )}
                                    </Card>
                                </Accordion>
                            ))}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>


        </>
    );
};

export default GeoserverBVSynthesis;
