import { store } from "../..";
import { highlightBV, highlightSiteIndus } from "../../../manager/map/loader";
import { reportTabs } from "../../../pages/Report";
import { Report } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { REPORT } from "../constants";
import {
  IFavFilter,
  IGeoserverBVFavFilter,
  IGeoserverBVFilter,
  IParcelFilter,
  IPluFilter,
  IProjectFavFilter,
  IProjectFilter,
  IReport,
  IReportFilter,
  IReportUpdateFiltersAction,
  IReportUpdateGeoserverBVFiltersAction,
  IReportUpdateParcelFiltersAction,
  IReportUpdatePluFiltersAction,
  IReportUpdateProjectFiltersAction,
  IReportUpdateSignalFiltersAction,
  IReportUpdateSiteIndustrielFiltersAction,
  ISignalFavFilter,
  ISignalFilter,
  ISiteIndustrielFavFilter,
  ISiteIndustrielFilter,
} from "../types";
import { launchLoading } from "./search";

const getReports = (filters: IReportFilter, sortField: string, sortDirection: string, currentPage?: number): any => {
    currentPage = currentPage ? currentPage : 1;
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      Report.getReportListPromise(filters, sortField, sortDirection, 10, currentPage)
        .then((res: { data: IReport[], headers: any }) => {
          dispatch({
            payload: res,
            type: REPORT.RETRIEVE_REPORTS,
          });
          launchLoading(dispatch, false);
        }).catch(() => launchLoading(dispatch, false));
    };
  };

const getProjects = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getProjectListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_PROJECTS,
        });
        launchLoading(dispatch, false);
      }).catch(() => launchLoading(dispatch, false));
  };
};

const getSignals = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getSignalListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_SIGNALS,
        });
        launchLoading(dispatch, false);
      }).catch(() => launchLoading(dispatch, false));
  };
};

const getSiteIndustriels = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getSiteIndustrielListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_SITES,
        });
        launchLoading(dispatch, false);
      }).catch(() => launchLoading(dispatch, false));
  };
};

const getParcels = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getParcelListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_PARCEL,
        });
        launchLoading(dispatch, false);
      }).catch(() => launchLoading(dispatch, false));
  };
};

const getPlu = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getPluListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_PLU,
        });
        launchLoading(dispatch, false);
      }).catch(() => launchLoading(dispatch, false));
  };
};

const updateFilters = (filters: IReportFilter): IReportUpdateFiltersAction => {
    return {
      payload: {
        filters,
      },
      type: REPORT.UPDATE_FILTERS,
    };
  };

const updateProjectFilters = (filters: IProjectFilter): IReportUpdateProjectFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_PROJECT_FILTERS,
  };
};

const updateSignalFilters = (filters: ISignalFilter): IReportUpdateSignalFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_SIGNAL_FILTERS,
  };
};

const updateSiteIndustrielFilters = (filters: ISiteIndustrielFilter): IReportUpdateSiteIndustrielFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_SITES_FILTERS,
  };
};

const updateParcelFilters = (filters: IParcelFilter): IReportUpdateParcelFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_PARCEL_FILTERS,
  };
};

const updatePluFilters = (filters: IPluFilter): IReportUpdatePluFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_PLU_FILTERS,
  };
};

const updateGeoserverBVFilters = (filters: IGeoserverBVFilter): IReportUpdateGeoserverBVFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_GEOSERVER_BV_FILTERS,
  };
};

const setSort = (sortField: string): any => {
  const sortDirection: string = store.getState().report.sortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        sortDirection: (sortDirection === "desc") ? "asc" : "desc" ,
        sortField,
      },
      type: REPORT.SET_SORT,
    });
  };
};

const setProjectSort = (projectSortField: string): any => {
  const sortDirection: string = store.getState().report.projectSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        projectSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        projectSortField,
      },
      type: REPORT.SET_PROJECT_SORT,
    });
  };
};

const setSignalSort = (signalSortField: string): any => {
  const sortDirection: string = store.getState().report.signalSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        signalSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        signalSortField,
      },
      type: REPORT.SET_SIGNAL_SORT,
    });
  };
};

const setSiteIndustrielSort = (siteIndustrielSortField: string): any => {
  const sortDirection: string = store.getState().report.siteIndustrielSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        siteIndustrielSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        siteIndustrielSortField,
      },
      type: REPORT.SET_SITE_INDUS_SORT,
    });
  };
};

const setGeoserverBVSort = (geoserverBVSortField: string): any => {
  const sortDirection: string = store.getState().report.geoserverBVSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        geoserverBVSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        geoserverBVSortField,
      },
      type: REPORT.SET_GEOSERVER_BV_SORT,
    });
  };
};

const getFav = (email: string) => {
    return (dispatch: any) => {
      Report.getFavFilters(email)
        .then((res: { data: IFavFilter[] }) => {
          res.data.map((item: IFavFilter) => {
            if (item.isDefault && item.id) {
              dispatch(setFav({label: item.title, value: item.id}, item.filters));
            }
          });
          dispatch({
            payload: res,
            type: REPORT.GET_FAVORITES,
          });
        });
    };
  };

const setFav = (newFav: {label: string, value: number}, filter: IReportFilter) => {
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      dispatch(updateFilters(filter));
      dispatch({
        payload: {newFav},
        type: REPORT.SET_FAVORITE,
      });
      launchLoading(dispatch, false);
    };
  };

const getProjectFav = (email: string) => {
  return (dispatch: any) => {
    Report.getProjectFavFilters(email)
      .then((res: { data: IProjectFavFilter[] }) => {
        res.data.map((item: IProjectFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setProjectFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_PROJECT_FAVORITES,
        });
      });
  };
};

const setProjectFav = (newFav: { label: string, value: number }, filter: IProjectFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateProjectFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_PROJECT_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const getSignalFav = (email: string) => {
  return (dispatch: any) => {
    Report.getSignalFavFilters(email)
      .then((res: { data: ISignalFavFilter[] }) => {
        res.data.map((item: ISignalFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setSignalFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_SIGNAL_FAVORITES,
        });
      });
  };
};

const setSignalFav = (newFav: { label: string, value: number }, filter: ISignalFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateSignalFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_SIGNAL_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const getSiteIndusFav = (email: string) => {
  return (dispatch: any) => {
    Report.getSiteIndusFavFilters(email)
      .then((res: { data: ISiteIndustrielFavFilter[] }) => {
        res.data.map((item: ISiteIndustrielFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setSiteIndusFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_SITE_INDUS_FAVORITES,
        });
      });
  };
};

const setSiteIndusFav = (newFav: { label: string, value: number }, filter: ISiteIndustrielFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateSiteIndustrielFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_SITE_INDUS_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const getGeoserverBVFav = (email: string) => {
  return (dispatch: any) => {
    Report.getGeoserverBVFavFilters(email)
      .then((res: { data: IGeoserverBVFavFilter[] }) => {
        res.data.map((item: IGeoserverBVFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setGeoserverBVFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_GEOSERVER_BV_FAVORITES,
        });
      });
  };
};

const setGeoserverBVFav = (newFav: { label: string, value: number }, filter: IGeoserverBVFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateGeoserverBVFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_GEOSERVER_BV_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const showDetail = (itemID: string) => {
  return async (dispatch: any) => {
    try {
      launchLoading(dispatch, true);
      const data = await SearchService.getSigObject(itemID);
        data.centroid = calculateCentroid(data);
        dispatch({
          type: REPORT.SHOW_DETAIL,
          payload: {
            connection: data,
          },
        });
        launchLoading(dispatch, false);
        return data;
    } catch (error) {
      console.error('Erreur lors de la récupération des données:', error);
      launchLoading(dispatch, false);
      throw error;
    }
  };
};


const hideDetail = () => {
  return {
    type: REPORT.HIDE_DETAIL,
  };
};


const showProjectDetail = (projectID: string) => {
  return async (dispatch: any) => {
    try {
      launchLoading(dispatch, true);
      const data = await Report.getProject(projectID);
        dispatch({
          type: REPORT.SHOW_PROJECT_DETAIL,
          payload: {
            project: data,
          },
        });
        launchLoading(dispatch, false);
        return data;
    } catch (error) {
      console.error('Erreur lors de la récupération des données du projet:', error);
      launchLoading(dispatch, false);
      throw error;
    }
  };
};

const hideProjectDetail = () => {
  return {
    type: REPORT.HIDE_PROJECT_DETAIL,
  };
};

const showSignalDetail = (signalID: string) => {
  return async (dispatch: any) => {
    try {
      launchLoading(dispatch, true);
      const data = await Report.getSignal(signalID);
        dispatch({
          type: REPORT.SHOW_SIGNAL_DETAIL,
          payload: {
            signal: data,
          },
        });
        launchLoading(dispatch, false);
        return data;

    } catch (error) {
      console.error('Erreur lors de la récupération des données Signal:', error);
      launchLoading(dispatch, false);
      throw error;
    }
  };
};

const hideSignalDetail = () => {
  return {
    type: REPORT.HIDE_SIGNAL_DETAIL,
  };
};

const showSiteIndustrielDetail = (siteIndustrielId: string) => {
  return async (dispatch: any) => {
    try {
      launchLoading(dispatch, true);
      const data  = await Report.getSiteIndustriel(siteIndustrielId);
      if ('ID_CD92' in data && 'coordinates' in data) {
        // Mettre à jour le store Redux avec les données récupérées
        dispatch({
          type: REPORT.SHOW_SITE_INDUS_DETAIL,
          payload: {
            siteIndustriel: data,
          },
        });
        highlightSiteIndus(data.ID_CD92, data.coordinates);
        launchLoading(dispatch, false);
        return data;
      } else {
        console.error('Les données SiteIndustriel récupérées ne contiennent pas les propriétés ID_CD92 et coordinates.');
        throw new Error('Les données récupérées ne sont pas valides.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données SiteIndustriel:', error);
      launchLoading(dispatch, false);
      throw error;
    }
  };
};


const showGeoserverBVDetail = (geoserverBVId: string) => {
  return async (dispatch: any) => {
    try {
      launchLoading(dispatch, true);
      const data = await Report.getGeoserverBV(geoserverBVId);

      if ('id' in data && 'coordinates' in data) {
        // Mettre à jour le store Redux avec les données récupérées
        dispatch({
          type: REPORT.SHOW_GEOSERVER_BV_DETAIL,
          payload: {
            geoserverBV: data,
          },
        });

        highlightBV(data.id, data.coordinates);

        launchLoading(dispatch, false);

        return data;
      } else {
        console.error('Les données GeoserverBV récupérées ne contiennent pas les propriétés id et coordinates.');
        throw new Error('Les données récupérées ne sont pas valides.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données GeoserverBV:', error);
      launchLoading(dispatch, false);
      throw error;
    }
  };
};


const hideSiteIndustrielDetail = () => {
  return {
    type: REPORT.HIDE_SITE_INDUS_DETAIL,
  };
};

const hideGeoserverBVDetail = () => {
  return {
    type: REPORT.HIDE_GEOSERVER_BV_DETAIL,
  };
};

const changeTab = (newTab: keyof typeof reportTabs) => {
  return{
    payload: {
      newTab,
    },
    type: REPORT.CHANGE_TAB,
  };
};

const calculateCentroid = (data: any) => {
  let longitude: number = 0;
  let latitude: number = 0;
  if ("undefined" !== typeof data["sig:point"]) {
    longitude = data["sig:point"][0][0] as number;
    latitude = data["sig:point"][0][1] as number;
  } else if ("undefined" !== typeof data["sig:line"]) {
    longitude = (parseFloat(data["sig:line"][1][1]) + parseFloat(data["sig:line"][0][1])) / 2;
    latitude = (parseFloat(data["sig:line"][1][0]) + parseFloat(data["sig:line"][0][0])) / 2;
  }
  return {longitude, latitude};
};

const displayMap3d = (isDisplayed3d?: boolean) => {
  return {
    payload: isDisplayed3d,
    type: REPORT.DISPLAY_REPORT_MAP_3D,
  };
};

export const getGeoserverBV = (colors: string[]): any => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return Report.getGeoserverBVListPromise(colors)
      .then((data: any) => {
        dispatch({
          payload: data,
          type: REPORT.RETRIEVE_GEOSERVER_BV,
        });
        launchLoading(dispatch, false);
        return data;
      }).then((data: any) => {
        return data;
      }).catch(() => launchLoading(dispatch, false));
  };
};

export const getFilteredGeoserverBVs = (
  filters: IGeoserverBVFilter,
  sortField: string,
  sortDirection: string,
  currentPage?: number,
): any => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return Report.getFilteredGeoserverBVListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((data: any) => {
        dispatch({
          payload: data,
          type: REPORT.RETRIEVE_FILTERED_GEOSERVER_BV,
        });
        launchLoading(dispatch, false);
        return data;
      }).then((data: any) => {
        return data;
      }).catch(() => launchLoading(dispatch, false));
  };
};

export default {
  changeTab,
  displayMap3d,
  getFav,
  getProjectFav,
  getSignalFav,
  getSiteIndusFav,
  getGeoserverBVFav,
  getGeoserverBV,
  getFilteredGeoserverBVs,
  getReports,
  hideDetail,
  hideProjectDetail,
  hideSignalDetail,
  hideSiteIndustrielDetail,
  hideGeoserverBVDetail,
  setFav,
  setProjectFav,
  setSignalFav,
  setSiteIndusFav,
  setGeoserverBVFav,
  setSort,
  setProjectSort,
  setSignalSort,
  setSiteIndustrielSort,
  setGeoserverBVSort,
  showDetail,
  showProjectDetail,
  showSignalDetail,
  showSiteIndustrielDetail,
  showGeoserverBVDetail,
  updateFilters,
  updateProjectFilters,
  updateSignalFilters,
  updateSiteIndustrielFilters,
  updateParcelFilters,
  updatePluFilters,
  updateGeoserverBVFilters,
  getProjects,
  getSignals,
  getSiteIndustriels,
  getParcels,
  getPlu,
};
