import React from "react";
import { useTranslation } from "react-i18next";
import "./countMessage.scss";

const CountMessage = (props: { maxResult: number; nbResult: number; total?: number }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="gaia-search-data-count">
        {props.nbResult === props.maxResult ?
            (props.total ?
                t("max-result-count-document-message", {
                    maxResult: props.maxResult,
                    totalCount: props.total,
                })
                : t("max-result-count-message", { maxResult: props.maxResult }))
          : t("result-count-message", { nbResult: props.nbResult })
        }
        <br />
        {props.nbResult === props.maxResult ? t("max-result-fine-search") : ""}
      </p>
    </>
  );
};

export default CountMessage;
