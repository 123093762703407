import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { categories, mapType3D } from "../../config/constants";
import { mapLoaderConfig } from "../../manager/map/loader";
import { zoomToAddressExtent, zoomToCoordinates, zoomToExtent } from "../../manager/map/navigation";
import { SearchActions, UserActions } from "../../store/redux/actions";
import { ISearch, IUser } from "../../store/redux/types";
import NoResults from "../Utils/messages/noResults";
import Address from "./categories/address";
import Asset from "./categories/asset";
import Documents from "./categories/documents";
import "./search.scss";

export interface IAddress {
  [key: string]: { [key: string]: number | string };
}

function SearchList(props: any) {
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const { searchData } = props.search;
  const { showDetail } = props;
  let searchListComponent;

  useEffect(() => {
    setSelectedIndex(null);
    setSelectedAsset(null);
  }, [props.search.userInput, props.search.searchData]);

  const searchResult = searchData.find((data: any) => data.category === props.category);

  const getDownloadUrl = (downloadUrl: string) => {
    const token = localStorage.getItem("tokenGaiaVision");
    return downloadUrl + "?token=" + token;
  };
  const handleTargetClickOfAddress = (address: IAddress, clickedIndex: number) => {
    const coordinates = calculateCentroid(address);
    if (address.attributes.Type === "City" || address.attributes.Addr_type === "Postal") {
      zoomToAddressExtent(address.extent, 20000);
      props.setHomeMapPosition([coordinates.latitude, coordinates.longitude, 20000]);
    } else {
      zoomToAddressExtent(address.extent);
      props.setHomeMapPosition([coordinates.latitude, coordinates.longitude, 1000]);
    }
    setSelectedIndex(clickedIndex);
    ReactGA.event({ action: `Zoom to : ${address.address}`, category: "Search" });
  };
  const handleTargetClickOfAsset = (asset: any, clickedIndex: number) => {
    const scale = (mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D) ? 500 : 250;
    if ("sig:line" in asset) {
      zoomToExtent(asset["sig:line"], scale);
    }
    if ("sig:point" in asset) {
      zoomToCoordinates(asset["sig:point"][0][0], asset["sig:point"][0][1], scale);
    }
    const coordinates = calculateCentroid(asset);
    props.setHomeMapPosition([coordinates.latitude, coordinates.longitude, scale]);
    setSelectedAsset(clickedIndex);
    ReactGA.event({ action: `Zoom objet id: ${asset.id}`, category: "Search" });
  };

  const calculateCentroid = (data: any) => {
    let longitude: number = 0;
    let latitude: number = 0;
    if ("undefined" !== typeof data["sig:point"]) {
      longitude = data["sig:point"][0][0] as number;
      latitude = data["sig:point"][0][1] as number;
    } else if ("undefined" !== typeof data["sig:line"]) {
      longitude = (parseFloat(data["sig:line"][1][1]) + parseFloat(data["sig:line"][0][1])) / 2;
      latitude = (parseFloat(data["sig:line"][1][0]) + parseFloat(data["sig:line"][0][0])) / 2;
    } else if ("undefined" !== typeof data.extent) {
      longitude = (data.extent.xmax + data.extent.xmin) / 2;
      latitude = (data.extent.ymax + data.extent.ymin) / 2;
    }
    return { longitude, latitude };
  };

  searchListComponent = (result: { category: string; data: any }) => {
    switch (result.category) {
      case categories.GEO:
        if (result.data.candidates) {
          return (
            <Address result={result} selectedIndex={selectedIndex} handleTargetClick={handleTargetClickOfAddress} />
          );
        }
        return <NoResults />;
      case categories.DOC:
        if (result.data.documents) {
          return <Documents result={result} downloadUrl={(url: string) => getDownloadUrl(url)} />;
        }
        return <NoResults />;
      case categories.ASSET:
        if (result.data.objects) {
          return (
            <Asset
              result={result}
              handleClick={showDetail}
              handleTargetClick={handleTargetClickOfAsset}
            />
          );
        }
        return <NoResults />;
      default:
        return <NoResults />;
    }
  };

  return searchListComponent(searchResult);
}

const mapStateToProps = (state: { search: ISearch, user: IUser }, props: { category: string }) => {
  return { search: state.search, user: state.user, category: props.category };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...SearchActions, ...UserActions }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
