import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzInput } from "react-theme-components";
import useDebouncedSearch from "../Suggestion/useDebouncedSearch";
import "./AutoCompleteInput.scss";

interface IAutoCompleteInput {
  className: string;
  debounce?: number;
  value: any;
  handler: (v: any) => void;
  minLength: number;
  placeholder: string;
  searchHandler: (v: any) => any;
  autoCompleteOptions: string[];
}

const AutoCompleteInput = (props: IAutoCompleteInput): ReactElement => {

  const {t} = useTranslation();
  const {value, handler, minLength, searchHandler, autoCompleteOptions} = props;
  const [displaySuggestion, setDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const debounceDelay = props.debounce || 500;

  const suggestionClickHandler = (val: string): void => {
    handler(val);
    setDisplay(false);
  };

  const autocomplete = (val: string) => {
    if (val.length >= minLength) {
      setLoading(true);
      searchHandler(val).then(() => {
        setLoading(false);
      });
    }
  };

  const useAutocomplete = () => useDebouncedSearch(autocomplete, debounceDelay);
  const {setInputText} = useAutocomplete();

  useEffect(() => {
    setInputText(value);
    setDisplay(value.length >= minLength);
  }, [value]);

  return <>
    <div className={"autocomplete"}>
      <div className={"autocomplete--input-container"}>
        <SzInput
          className={`autocomplete-input ${props.className}`}
          handleClear={() => handler("")}
          placeholder={props.placeholder}
          onBlur={() => {
            setTimeout(() => {
              setDisplay(false);
            }, 500);
          }}
          onChange={(event: { target: { value: string } }) => handler(event.target.value)}
          onFocus={() => {setDisplay(value.length > minLength); }}
          value={props.value}
        />
      </div>
      {displaySuggestion && (
        <div className={"autocomplete-options"}>
          <div className={"autocomplete-options-header"}>
            <span className={"autocomplete-options-title"}>{t("suggestions")}</span>
            <span className={"autocomplete-options-loader"}>
          {loading && (
            <Icon path={mdiLoading} title="Loader" size={1} spin/>
          )}
        </span>
          </div>
          {autoCompleteOptions && value.length > 0 && (
            autoCompleteOptions.map((item: string) => {
              return <div
                key={`key-${item}`}
                className={"autocomplete-options--option"}
                onClick={() => suggestionClickHandler(item)}
              >
                {item}
              </div>;
            })
          )}
          {(autoCompleteOptions.length === 0 || value.length === 0) &&
          (
            <div
              key={`key-no-result`}
              className={"autocomplete-options--option autocomplete-options--option_none"}
            >
              {t("report:no-suggestion")}
            </div>
          )}
        </div>
      )}
    </div>
  </>;
};

export default AutoCompleteInput;
