import { ERROR } from "../constants";
import { ErrorsActionTypes, IError } from "../types";

const initialState: IError = {
    jwtCheck: false,
    message: "",
    status: null,
};

const errorReducers = (
  state = initialState,
  action: ErrorsActionTypes,
): IError => {
  switch (action.type) {
    case ERROR.SET:
      return { jwtCheck: action.payload.jwtCheck, message: action.payload.message, status: action.payload.status};
    case ERROR.RESET:
      return initialState;
    default:
      return state;
  }
};

export default errorReducers;
