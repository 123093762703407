import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon, SzInput, SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import { ReportActions } from "../../../store/redux/actions";
import { IGeoserverBVFavFilter, IGeoserverBVFilter, IReportData, IUser } from "../../../store/redux/types";
import GeoserverBVFavFilterForm from "../../Forms/GeoserverBV";
import UpdateGeoserverBVFavFilters from "../../Forms/GeoserverBV/updateGeoserverBVFavFilters";

interface IGeoserverBVFavFilters {
  favFilterFormIsActive: boolean;
  handleCloseFavFilterForm: () => void;
  setGeoserverBVFav: (newFav: { label: string, value: number }, filter: IGeoserverBVFilter) => void;
  getGeoserverBVFav: (s: string) => void;
  email?: string;
  geoserverBVFavFilters?: IGeoserverBVFavFilter[];
  currentGeoserverBVFavFilter: { label: string, value: number };
}

function GeoserverBVFavFilters(props: IGeoserverBVFavFilters) {

  useEffect(() => {
    if (props.getGeoserverBVFav && props.email) {
      props.getGeoserverBVFav(props.email);
    }
  }, [props.getGeoserverBVFav, props.email]);

  const {t} = useTranslation();
  const [favFilterUpdateFormIsActive, setFavFilterUpdateFormIsActive] = useState(false);

  const handleCloseFavFilterUpdateForm = () => {
    setFavFilterUpdateFormIsActive(false);
  };

  let filtersOptions: any[] = [{label: t("report:favFilters"), value: 0}];
  if (props.geoserverBVFavFilters && props.geoserverBVFavFilters.length > 0) {
    filtersOptions = filtersOptions.concat(props.geoserverBVFavFilters.map((item: IGeoserverBVFavFilter) => {
      return {label: item.title, value: item.id};
    }));
  }

  const updateFavFilter = (newFav: { label: string, value: number }): void => {
    if (newFav.value && props.geoserverBVFavFilters) {
      const filter = props.geoserverBVFavFilters.find((item: IGeoserverBVFavFilter) => {
        return item.id === newFav.value;
      });
      if (filter) {
        props.setGeoserverBVFav(newFav, filter.filters);
      }
    }
  };

  return (
    <>
      {props.favFilterFormIsActive && (
        <GeoserverBVFavFilterForm
          visible={props.favFilterFormIsActive}
          handleCloseUserForm={props.handleCloseFavFilterForm}
        />
      )}
      {favFilterUpdateFormIsActive && (
        <UpdateGeoserverBVFavFilters
          visible={favFilterUpdateFormIsActive}
          handleCloseUpdateForm={handleCloseFavFilterUpdateForm}
        />
      )}
      <div className={"report--menu--tabs_list--fav"}>
        <div className={"report--menu--tabs_list--input-container"}>
          {(!props.geoserverBVFavFilters || props.geoserverBVFavFilters.length === 0) &&
          <SzInput placeholder={t("report:noFavFilters")}/>
          }
          {(props.geoserverBVFavFilters && props.geoserverBVFavFilters.length > 0) && (
            <><SzSelect
              className="border-secondary fav-filters"
              isSearchable
              id="geoserverBVFavFilters"
              name="geoserverBVFavFilters"
              placeholder={t("report:favFilter")}
              onChange={(newFav: { label: string, value: number }) => {
                updateFavFilter(newFav);
              }}
              options={filtersOptions}
              value={props.currentGeoserverBVFavFilter}
            />
              <OverlayTrigger
                placement="bottom"
                delay={{show: 100, hide: 200}}
                overlay={
                  <Tooltip id="tooltip">
                    {t("set_my_fav_filters")}
                  </Tooltip>
                }
              >
                <div className={"fav-filters_cog-wheel"}>
                <SzIcon
                  className={"cursor-pointer"}
                  onClick={() => setFavFilterUpdateFormIsActive(true)}
                  variant={"line"}
                  icon={"cog"}
                />
                </div>
              </OverlayTrigger>
            </>
          )
          }

        </div>
        {/*<div className={"report--menu--tabs_list--fav--button-container"}>*/}
        {/*  */}
        {/*</div>*/}
      </div>
    </>
  );
}

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    currentGeoserverBVFavFilter: state.report.currentGeoserverBVFavFilter,
    email: state.user.email,
    geoserverBVFavFilters: state.report.geoserverBVFavFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoserverBVFavFilters);
