import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../detail.scss";

const SignalSynthesis = (props: { shownItem: any }): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Table size="sm">
        <tbody>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:signal.category")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {t(`report:category.${props.shownItem.requestType}`)}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:signal.status")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.currentStatus ? props.shownItem.currentStatus.status.label : t("no-data")}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:signal.detail")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.subject}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:city")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.address.city}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:signal.address")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.address.address}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:signal.origin")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.context ? props.shownItem.context[0].label : t("no-data")}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:signal.comment")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.comment}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default SignalSynthesis;
