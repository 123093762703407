import { API_ROUTES } from "../config/routes";
import Request from "./Request";

export class Search {
  public static getHistory(itemsPerPage?: number, currentPage?: number) {
    itemsPerPage = itemsPerPage ? itemsPerPage : 10;
    currentPage = currentPage ? currentPage : 1;
    const queryString = this.queryStringFromParams(itemsPerPage, currentPage);
    const url = `${API_ROUTES.GET_SEARCH_HISTORY}${queryString}`;
    return Request.get(url, false)
      .then((res: any) => ({ data: res, headers: res.headers }));
  }

  public static getAddressAutoComplete(address: string) {
    const url = `${API_ROUTES.SIG_ADDRESS_AUTOCOMPLETE}?term=${address}`;
    return Request.get(url, true)
      .then((res: any) => res.data);
  }

  public static getAddressCoordinates(address: string) {
    const url = `${API_ROUTES.GET_ADDRESS_COORDINATES}?address=${address}`;
    return Request.get(url, false, false)
      .then((res: any) => res.data);
  }

  public static getSigObject(objectId: string,indexName?:any) {
    if (indexName != undefined)
      var url = `/sig/index/${objectId}/${indexName}`;
    else
      var url = `/sig/${objectId}`;
    return Request.get(url, true)
      .then((response: {data: any}) => response.data);
  }

  public static getSigObjectByIdent(ident: string) {
    const url = `/sig/ident/${ident}`;
    return Request.get(url, false)
      .then((response: { data: any }) => response.data);
  }

  private static queryStringFromParams(itemsPerPage: number, currentPage: number): string {
    const parameters = [
      `itemsPerPage=${itemsPerPage}`,
      `currentPage=${currentPage}`,
    ];
    return "?" + parameters.reduce((accumulator: string, parameter: string) => {
      if (!accumulator || accumulator.length === 0) {
        return parameter;
      }
      return `${accumulator}&${parameter}`;
    });
  }
}
