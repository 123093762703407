import React from "react";
import { Col, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ISearch } from "../../../store/redux/types";
import CountMessage from "../../Utils/messages/countMessage";
import NoResults from "../../Utils/messages/noResults";
import { IAddress } from "../list";

interface IAddressProps {
  result: any;
  search?: ISearch;
  selectedIndex?: number;
  handleTargetClick: (address: IAddress, index: number) => void;
}

function Address(props: IAddressProps) {
  const { t } = useTranslation();
  const { search, selectedIndex } = props;
  return (
    <Row className="category-tab gaia-search__main-tab-result">
      <Col sm={12} className="no-padding category-tab">
        {search && (
          <CountMessage maxResult={search.maxResult} nbResult={props.result.data.candidates.length} />
        )}
        <ul className="gaia-search-data">
          {props.result.data.candidates.length > 0 &&
          props.result.data.candidates.map((candidate: any, index: number) => {
            return (
              <li className={`gaia-search-data__item ${selectedIndex === index ? "active" : ""}`} key={index}>
                <span className="gaia-search-data__item-content">{candidate.address}</span>
                <div className="gaia-search-data__item-links">
                  <span className="clickable" onClick={() => props.handleTargetClick(candidate, index)}>
                    <OverlayTrigger
                      placement="bottom"
                      delay={{show: 100, hide: 200}}
                      overlay={
                        <Tooltip id="tooltip">
                          {t("see_address_on_map")}
                        </Tooltip>
                      }
                    >
                    <i className="material-icons">gps_fixed</i>
                    </OverlayTrigger>
                  </span>
                </div>
              </li>
            );
          })}
          {props.result.data.candidates.length === 0 &&
          <>
            <li><NoResults/></li>
          </>
          }
        </ul>
      </Col>
    </Row>
  );
}
const mapStateToProps = (state: any, props: IAddressProps) => {
  return { search: state.search, ...props };
};

export default connect(mapStateToProps)(Address);
