import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { Collapse, Nav, Tab } from "react-bootstrap";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { categories } from "../../config/constants";
import CollapseToggle from "../Utils/buttons/CollapseToggle";
import SearchList from "./list";
import "./search.scss";

interface ICategory {
  icon: string;
  title: string;
  type: string;
}

interface ISearchProps {
  breadcrumbCategoryName: string;
  loading: boolean;
}

function Search(props: ISearchProps) {
  const [searchIsHidden, setSearchIsHidden] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState<any>();
  const { breadcrumbCategoryName } = props;
  const defaultCategories: ICategory[] = [
    {
      icon: "gaia-icon-map-marker",
      title: i18n.t("category.address"),
      type: categories.GEO,
    },
    {
      icon: "gaia-icon-pipeline",
      title: i18n.t("category.asset"),
      type: categories.ASSET,
    },
    {
      icon: "gaia-icon-file",
      title: i18n.t("category.documents"),
      type: categories.DOC,
    },
  ];

  useEffect(() => {
    let activeKey: string = "link-category-GEO";
    const defaultCategory = defaultCategories.find((category) => category.title === breadcrumbCategoryName);
    activeKey = defaultCategory ? `link-category-${defaultCategory.type}` : activeKey ;
    setDefaultActiveKey(activeKey);
  }, []);

  useEffect(() => {
    setSearchIsHidden(false);
  }, [props.loading]);

  const handleSelect = (eventKey: string) => {
    ReactGA.event({ action: `Voir resultat de la catégorie: ${eventKey}`, category: "Search"});
    if (true === searchIsHidden) {
      setSearchIsHidden(false);
    }
  };

  return (
    <div className="gaia-search-result">
      <Tab.Container id="gaia-category" activeKey={defaultActiveKey} onSelect={(key: any) => setDefaultActiveKey(key)}>
        <div className="overflow-hidden bg-white text-primary gaia-aside gaia-search__aside">
          <Nav className="sz-aside__list" onSelect={handleSelect}>
            {defaultCategories.map((category, key) => (
              <Nav.Item key={key} className="gaia-search-category text-decoration-none py-2 gaia-search__aside-item">
                <Nav.Link eventKey={`link-category-${category.type}`}>
                  <span
                    className={`gaia-search-category__item-icon gaia-icons ${category.icon}
                    ${defaultActiveKey === `link-category-${category.type}` ? "active" : ""}`}
                  />
                  <span className="gaia-search-category__item-title">{category.title}</span>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="gaia-search__main category-tab">
          <Collapse in={!searchIsHidden} timeout={0} dimension="width">
            <Tab.Content className="category-tab">
              {defaultCategories.map((category, key) => (
                <Tab.Pane
                  key={`link-category-${key}`}
                  eventKey={`link-category-${category.type}`}
                  className="category-tab"
                >
                  <SearchList category={category.type} />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Collapse>
        </div>
      </Tab.Container>
      <CollapseToggle collapseDirection={"left"} handler={setSearchIsHidden} toggled={searchIsHidden} />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    breadcrumbCategoryName: state.breadcrumb.categoryName,
    loading: state.search.flag.loading,
  };
};

export default connect(mapStateToProps)(Search);
