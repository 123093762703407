import { IAssetMapping } from "./asset.interfaces";

export const ASSET_MAPPING: IAssetMapping = {
  "asset:barrage_a_poutrelles": [
    {
      label: "synthesis",
      layerName: "Barrage_à_poutrelles",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "reseau",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "depth",
          suffix: "m",
          type: "string",
          value: "asset:profond",
        },
        {
          label: "raft",
          suffix: "m NGF",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "position",
          type: "string",
          value: "asset:positio",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    /*{
      label: "relatedAsset",
      properties: [],
    },*/
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:bassin_a_ciel_ouvert": [
    {
      label: "synthesis",
      layerName: "Bassin_à_ciel_ouvert",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "reseau",
          type: "string",
          value: "asset:reseau",
        },
        {
          label: "fonction",
          type: "string",
          value: "asset:fonction",
        },
        {
          label: "volume",
          suffix: "m3",
          type: "string",
          value: "asset:volume",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    /*{
      label: "relatedAsset",
      properties: [],
    },*/
  ],
  "asset:bassin_enterre": [
    {
      label: "synthesis",
      layerName: "Bassin_enterré",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "reseau",
          type: "string",
          value: "asset:reseau",
        },
        {
          label: "fonction",
          type: "string",
          value: "asset:fonction",
        },
        {
          label: "volume",
          suffix: "m3",
          type: "string",
          value: "asset:volume",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    /*{
      label: "relatedAsset",
      properties: [],
    },*/
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:bouche_d_engouffrement": [
    {
      label: "synthesis",
      layerName: "Bouche_d'engouffrement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          label: "engulfmentTypologie",
          type: "string",
          value: "asset:typo",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "raft",
          suffix: "m",
          type: "string",
          value: "asset:radier",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:chambre_a_sable": [
    {
      label: "synthesis",
      layerName: "Chambre_à_sable",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          callback: (value) => {
            if (value === null || value === undefined || value === '' || isNaN(Number(value))) {
              return "Non renseigné";
            }
            return parseFloat(value).toFixed(2);
          },
          label: "volumeUtile",
          type: "string",
          value: "asset:volume",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "byPass",
          type: "calculated",
          value: "asset:by_pass",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
        /*{
          label: "curageWeight",
          suffix: "Tonne",
          type: "string",
          value: "vicr:curage_weight",
        },*/
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:chemin_de_cables_gaia": [
    {
      label: "synthesis",
      layerName: "Chemin_de_cables_gaia",
      properties: [
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "cable_end_point",
          type: "string",
          value: "asset:boite_arr",
        },
        {
          label: "cable_start_point",
          type: "string",
          value: "asset:boite_dep",
        },
        {
          label: "path",
          type: "string",
          value: "asset:chemint",
        },
        {
          label: "route_code",
          type: "string",
          value: "asset:code_itiner",
        },
        {
          label: "tension_domain",
          type: "string",
          value: "asset:dom_tension",
        },
        {
          label: "cable_duo_number",
          type: "string",
          value: "asset:nbpaires",
        },
        {
          label: "route_name",
          type: "string",
          value: "asset:nom_itiner",
        },
        {
          label: "cable_support",
          type: "string",
          value: "asset:support_cabl",
        },
        {
          label: "cable_type",
          type: "string",
          value: "asset:type_cabl",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:chute_-_raccordement": [
    {
      label: "synthesis",
      layerName: "Chute_-_Raccordement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:chute_-_regard": [
    {
      label: "synthesis",
      layerName: "Chute_-_Regard",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          label: "datechar",
          type: "string",
          value: "asset:datechar",
        },
        {
          label: "depth",
          suffix: "mm",
          type: "string",
          value: "asset:profond",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:conduite_de_branchement": [
    {
      label: "synthesis",
      layerName: "Conduite_de_branchement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          attribute: "connector:typeOfConnection",
          callback: (value) => `asset:synthesis.${value}`,
          label: "family",
          type: "calculated",
          value: "controls",
        },
        {
          callback: (value) => `asset:synthesis.assujeti.${value}`,
          label: "assujetiType",
          type: "calculated",
          value: "asset:assujeti",
        },
        {
          label: "siteIndusNumber",
          type: "siteIndusNumber",
          value: "sites",
        },
        {
          attribute: "property:propertyType_internalLabel",
          callback: (value) => `${value}`,
          label: "propertyType",
          type: "calculated",
          value: "controls",
        },
        {
          label: "conformity",
          type: "conformity",
          value: "controls",
        },
        {
          label: "connection",
          type: "string",
          value: "asset:reseau",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "identification",
          type: "calculated",
          value: "asset:identifie",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "connectionBox",
          type: "calculated",
          value: "asset:boitbran",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
        {
          label: "siteIndusConformity",
          type: "siteIndusConformity",
          value: "sites",
        },
      ],
    },
    {
      label: "specifics",
      properties: [
        {
          callback: (value) => (value),
          label: "diameter",
          suffix: "mm",
          type: "string",
          value: "asset:diametre",
        },
        {
          callback: (value) => (value == "-1" ? "inconnu" : value + ' mm'),
          label: "height",
          //suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          callback: (value) => (value == "-1" ? "inconnu" : value),
          label: "length",
          suffix: "m",
          type: "string",
          value: "asset:longueur",
        },
        {
          callback: (value) => `asset:materials.alias.${value}`,
          label: "materials",
          type: "calculated",
          value: "asset:materiau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "siteIndustriel",
      properties: [],
    },
  ],
  "asset:deversoir_d_orage": [
    {
      label: "synthesis",
      layerName: "Déversoir_d'orage",
      properties: [
        {
          label: "address",
          type: "string",
          value: ["sig:adresse", "sig:codepostale", "sig:ville"],
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "typeAuto",
          type: "string",
          value: "asset:type_do",
        },
        {
          label: "codeSandre",
          type: "string",
          value: "asset:sandre",
        },
        {
          label: "fonction",
          type: "string",
          value: "asset:fonction_do",
        },
        {
          label: "declarativeStatus",
          type: "string",
          value: "asset:ref_do",
        },
        {
          label: "declarationReference",
          type: "string",
          value: "asset:source",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    /*{
      label: "relatedAsset",
      properties: [],
    },*/
    {
      label: "documents",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:deversoir_d_orage__declare_a_la_police_de_l_eau_": [
    {
      label: "synthesis",
      layerName: "Déversoir_d'orage_(déclaré_à_la_Police_de_l'eau)",
      properties: [
        {
          label: "address",
          type: "string",
          value: ["sig:adresse", "sig:codepostale", "sig:ville"],
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "fonction",
          type: "string",
          value: "asset:fonction",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "depth",
          suffix: "mm",
          type: "string",
          value: "asset:profond",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    /*{
      label: "relatedAsset",
      properties: [],
    },*/
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:exutoire__declare_": [
    {
      label: "synthesis",
      layerName: "Exutoire_(déclaré)",
      properties: [
        {
          label: "address",
          type: "string",
          value: ["sig:adresse", "sig:codepostale", "sig:ville"],
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "receivingMiddle",
          type: "string",
          value: "asset:recept",
        },
        {
          label: "declarativeStatus",
          type: "string",
          value: "asset:ref_exut",
        },
        {
          label: "declarationReference",
          type: "string",
          value: "asset:source",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:pluviometre": [
    {
      label: "synthesis",
      layerName: "Pluviomètre",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idpluv",
          type: "string",
          value: "asset:idpluv",
        },
        {
          label: "idpm",
          type: "string",
          value: "asset:idpm",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    /*{
      label: "relatedAsset",
      properties: [],
    },*/
  ],
  "asset:point_d_entree_d_un_autre_maitre_d_ouvrage": [
    {
      label: "synthesis",
      layerName: "Point_d'entrée_d'un_autre_maître_d'ouvrage",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:point_de_mesure": [
    {
      label: "synthesis",
      layerName: "Point_de_mesure",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idPm",
          type: "string",
          value: "asset:idpm",
        },
        {
          label: "idps",
          type: "string",
          value: "asset:idps",
        },
        {
          label: "measurementTechnique",
          type: "string",
          value: "asset:techmesu",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:point_de_sortie_vers_autre_maitre_d_ouvrage": [
    {
      label: "synthesis",
      layerName: "Point_de_sortie_vers_autre_maitre_d_ouvrage",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:point_haut_-_raccordement": [
    {
      label: "synthesis",
      layerName: "Point_haut_-_Raccordement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:point_haut_-_regard": [
    {
      label: "synthesis",
      layerName: "Point_haut_-_Regard",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          label: "datechar",
          type: "string",
          value: "asset:datechar",
        },
        {
          label: "depth",
          suffix: "mm",
          type: "string",
          value: "asset:profond",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:porte_a_flot": [
    {
      label: "synthesis",
      layerName: "Porte_à_flot",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "connection",
          type: "string",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "depth",
          suffix: "mm",
          type: "string",
          value: "asset:profond",
        },
        {
          label: "raft",
          suffix: "m NGF",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "position",
          type: "string",
          value: "asset:positio",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "specifics",
      properties: [
        {
          label: "diameter",
          suffix: "mm",
          type: "string",
          value: "asset:diametre",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "length",
          suffix: "m",
          type: "string",
          value: "asset:longueur",
        },
        {
          callback: (value) => `asset:materials.alias.${value}`,
          label: "materials",
          type: "calculated",
          value: "asset:materiau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:poste_satellite": [
    {
      label: "synthesis",
      layerName: "Poste_satellite",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idPs",
          type: "string",
          value: "asset:idps",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:raccordement_de_bouche_d_engouffrement": [
    {
      label: "synthesis",
      layerName: "Raccordement_de_bouche_d'engouffrement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "heightmm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "widthmm",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:regard_de_branchement": [
    {
      label: "synthesis",
      layerName: "Regard_de_branchement",
      properties: [
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:regard_de_visite": [
    {
      label: "synthesis",
      layerName: "Regard_de_visite",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "positionPrecision",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "depth",
          suffix: "m",
          type: "string",
          value: "asset:profond",
        },
        {
          label: "coastline",
          suffix: "m NGF",
          type: "string",
          value: "asset:radier",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:regard_mixte": [
    {
      label: "synthesis",
      layerName: "Regard_mixte",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "position",
          type: "string",
          value: "",
        },
        {
          label: "positionPrecision",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "coastline",
          suffix: "m NGF",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "depth",
          suffix: "m",
          type: "string",
          value: "asset:profond",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:rejet_vers_le_milieu_naturel": [
    {
      label: "synthesis",
      layerName: "Rejet_vers_le_milieu_naturel",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:rejet_vers_le_siaap_ou_le_reseau_ville_de_paris": [
    {
      label: "synthesis",
      layerName: "Rejet_vers_le_SIAAP_ou_le_réseau_Ville_de_Paris",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "basver",
          type: "string",
          value: "asset:basver",
        },
        {
          label: "chute",
          type: "string",
          value: "asset:chute",
        },
        {
          label: "point_ht",
          type: "string",
          value: "asset:point_ht",
        },
        {
          label: "coastline",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "networkHead",
          type: "string",
          value: "asset:tete_reseau",
        },
        {
          label: "comment",
          type: "string",
          value: "asset:comment",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:seuil_fixe": [
    {
      label: "synthesis",
      layerName: "Seuil_fixe",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "depth",
          suffix: "m",
          type: "string",
          value: "asset:profond",
        },
        {
          label: "raft",
          suffix: "m",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "position",
          type: "string",
          value: "asset:positio",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:station_anti_crue": [
    {
      label: "synthesis",
      layerName: "Station_anti_crue",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "workingType",
          type: "string",
          value: "asset:ouvtype",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:station_ep": [
    {
      label: "synthesis",
      layerName: "Station_EP",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "workingType",
          type: "string",
          value: "asset:ouvtype",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:station_eu": [
    {
      label: "synthesis",
      layerName: "Station_EU",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "workingType",
          type: "string",
          value: "asset:ouvtype",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:station_eu_et_crue": [
    {
      label: "synthesis",
      layerName: "Station_EU_et_Crue",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "fonction",
          type: "string",
          value: "asset:fonction",
        },
        {
          label: "workingType",
          type: "string",
          value: "asset:ouvtype",
        },
        {
          label: "pumpType",
          type: "string",
          value: "asset:type_pomp",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:tete_de_reseau_-_raccordement": [
    {
      label: "synthesis",
      layerName: "Tête_de_réseau_-_Raccordement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "coastline",
          suffix: "m",
          type: "string",
          value: "asset:radier",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:tete_de_reseau_-_regard": [
    {
      label: "synthesis",
      layerName: "Tête_de_réseau_-_Regard",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "network",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "coastline",
          suffix: "m",
          type: "string",
          value: "asset:radier",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
  "asset:troncon_de_reseau_-_collecteur": [
    {
      label: "synthesis",
      layerName: "Tronçon_de_réseau_-_Collecteur",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "structurant",
          type: "calculated",
          value: "asset:structurant",
        },
        {
          label: "shape",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:troncon_de_reseau_-_galerie": [
    {
      label: "synthesis",
      layerName: "Tronçon_de_réseaux_-_Galerie",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "shape",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:troncon_de_reseau_-_raccordement": [
    {
      label: "synthesis",
      layerName: "Tronçon_de_réseaux_-_Raccordement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "shape",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:troncon_de_reseaux_partenaires_-_collecteur": [
    {
      label: "synthesis",
      layerName: "Tronçon_de_réseaux_partenaires_-_Collecteu",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "shape",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:troncon_de_reseaux_partenaires_-_galerie": [
    {
      label: "synthesis",
      layerName: "Tronçon_de_réseaux_partenaires_-_Galerie",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "shape",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:troncon_de_reseaux_partenaires_-_raccordement": [
    {
      label: "synthesis",
      layerName: "Tronçon_de_réseaux_partenaires_-_Raccordement",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          callback: (value) => (value === "O" ? "yes" : "no"),
          label: "visitable",
          type: "calculated",
          value: "asset:visitabl",
        },
        {
          label: "shape",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "calculatedLength",
          suffix: "m",
          type: "string",
          value: "asset:longcalc",
        },
        {
          label: "material",
          type: "string",
          value: "asset:materiau",
        },
        {
          label: "ZBefore",
          suffix: "m",
          type: "string",
          value: "asset:zamont",
        },
        {
          label: "ZAfter",
          suffix: "m",
          type: "string",
          value: "asset:zaval",
        },
        {
          label: "depthBefore",
          suffix: "m",
          type: "string",
          value: "asset:profamon",
        },
        {
          label: "depthAfter",
          suffix: "m",
          type: "string",
          value: "asset:profaval",
        },
        {
          label: "slope",
          suffix: "%",
          type: "string",
          value: "asset:pente",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "itvs",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:vanne_asservie": [
    {
      label: "synthesis",
      layerName: "Vanne_asservie",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "connection",
          type: "string",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "depth",
          suffix: "mm",
          type: "string",
          value: "asset:profond",
        },
        {
          label: "raft",
          suffix: "m NGF",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "position",
          type: "string",
          value: "asset:positio",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "specifics",
      properties: [
        {
          label: "diameter",
          suffix: "mm",
          type: "string",
          value: "asset:diametre",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "length",
          suffix: "m",
          type: "string",
          value: "asset:longueur",
        },
        {
          callback: (value) => `asset:materials.alias.${value}`,
          label: "materials",
          type: "calculated",
          value: "asset:materiau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:vanne_non_asservie": [
    {
      label: "synthesis",
      layerName: "Vanne_non_asservie",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "connection",
          type: "string",
          value: "asset:reseau",
        },
        {
          label: "type",
          type: "string",
          value: "asset:typo",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "width",
          suffix: "mm",
          type: "string",
          value: "asset:largeur",
        },
        {
          label: "depth",
          suffix: "mm",
          type: "string",
          value: "asset:profond",
        },
        {
          label: "raft",
          suffix: "m NGF",
          type: "string",
          value: "asset:radier",
        },
        {
          label: "position",
          type: "string",
          value: "asset:positio",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "specifics",
      properties: [
        {
          label: "diameter",
          suffix: "mm",
          type: "string",
          value: "asset:diametre",
        },
        {
          label: "height",
          suffix: "mm",
          type: "string",
          value: "asset:hauteur",
        },
        {
          label: "length",
          suffix: "m",
          type: "string",
          value: "asset:longueur",
        },
        {
          callback: (value) => `asset:materials.alias.${value}`,
          label: "materials",
          type: "calculated",
          value: "asset:materiau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
  ],
  "asset:vidange_de_siphon": [
    {
      label: "synthesis",
      layerName: "Vidange_de_siphon",
      properties: [
        {
          label: "address",
          type: "string",
          value: "sig:adressecomplete",
        },
        {
          label: "nomxplo",
          type: "string",
          value: "asset:nomexplo",
        },
        {
          label: "idSig",
          type: "string",
          value: "asset:id_sig",
        },
        {
          callback: (value) => `asset:network.alias.${value}`,
          label: "networkType",
          type: "calculated",
          value: "asset:reseau",
        },
        {
          label: "class",
          type: "string",
          value: "asset:classe",
        },
        {
          label: "fonction",
          type: "string",
          value: "asset:fonction",
        },
        {
          label: "workingType",
          type: "string",
          value: "asset:ouvtype",
        },
        {
          label: "pumpType",
          type: "string",
          value: "asset:type_pomp",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_em",
          type: "calculated",
          value: "asset:secteur_exploitation_em",
        },
        {
          callback: (value) => value.replace(/_/g, " "),
          label: "secteur_exploitation_reseau",
          type: "calculated",
          value: "asset:secteur_exploitation_reseau",
        },
      ],
    },
    {
      label: "history",
      properties: [],
    },
    {
      label: "work3d",
      properties: [],
    },
    {
      label: "documents",
      properties: [],
    },
  ],
};
