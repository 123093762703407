import i18next from "i18next";
import i18n from "../../i18n";
import {
    IBVCollectorLayersData,
    IBVParcelLayersData,
    IParcelLayersData,
    IPluLayersData,
    IProjectLayersData,
    IReportLayersData,
    ISignalLayersData,
    ISiteIndustrielLayersData,
} from "../../pages/Report";
import {store} from "../../store";
import {updateVisibleLayers} from "../../store/redux/actions/user";
import {SEARCH} from "../../store/redux/constants";
import {IParcel, IPlu, IProject, IReport, ISignal, ISiteIndustriel} from "../../store/redux/types";
import {IRenderer, markerRenderer, projectIconRenderers, reportCircleRenderers, signalIconRenderers} from "./config";
import {
    bvCollectorLayerTitle,
    bvGroupLayerTitle,
    bvLayerTitle,
    bvParcelLayerTitle,
    bvSubLayerTitle,
    changeBaseMap, chooseLayers, conformityLayerTitle, forceLayersOnLoad,
    getMapPosition,
    getVisibleLayersList,
    groupLayer,
    highlightObjects,
    loadedFeatureLayer,
    loadedField,
    loadedGraphic,
    loadedGraphicsLayer,
    loadedMapView,
    loadedWatchUtils,
    loadMap,
    majorFaultTypesLayerTitle,
    parcelLayerTitle,
    pluLayerTitle,
    projectLayerTitle,
    signalLayerTitle,
    siteIndustrielLayerTitle,
    siteIndustrieGroupLayerTitle,
    siteIndustrielSuiviLayerTitle
} from "./loader";
import {zoomToCoordinates} from "./navigation";

let customLayer: any;
let conformityLayer: any;
let nonConformLayer: any;
let custombvGroupLayer: any;
let custombvConformityGroupLayer: any;
let custombvSubGroupLayer: any;
let custombvParcelGroupLayer: any;
let custombvCollectorGroupLayer: any;
let customSiteIndustrielGroupLayer: any;
let customSuiviSiteIndustrielGroupLayer: any;
let customParcelGroupLayer: any;
let customPluGroupLayer: any;
let projectLayer: any;
let signalLayer: any;
let customSiteIndusGroupLayer: any;
let majorFaultTypeLayer: any;
let updateVisibleLayer: boolean = false;

const noop = (e: any) => {
    console.log(e);
};

export const initializeMap = (
    container: string,
    reportLayersData: IReportLayersData,
    projectLayersData: IProjectLayersData,
    signalLayersData: ISignalLayersData,
    siteIndustrielLayersData: ISiteIndustrielLayersData,
    parcelLayersData: IParcelLayersData,
    pluLayersData: IPluLayersData,
    bvCollectorLayersData: any,
    coordinates: [number, number, number],
    setMapPosition: (coordinates: [number, number, number]) => void,
    displayBVLayers: boolean,
    displayProjectLayers: boolean,
    displaySignalLayers: boolean,
    displaySiteIndustrielLayers: boolean,
    displayParcelLayers: boolean,
    displayPluLayers: boolean,
    baseMapIndex?: number,
    forcedLayers?: string[],
    visibleLayers?: string[],
    itemId?: string,
    geoserverBVs?: any,
    bvParcels?: any,
    plu?: any,
    displaySmartshape?: any,
) => {
    loadMap().then(() => {
        loadView(
            container,
            reportLayersData,
            projectLayersData,
            signalLayersData,
            siteIndustrielLayersData,
            parcelLayersData,
            pluLayersData,
            bvCollectorLayersData,
            coordinates,
            setMapPosition,
            displayBVLayers,
            displayProjectLayers,
            displaySignalLayers,
            displaySiteIndustrielLayers,
            displayParcelLayers,
            displayPluLayers,
            baseMapIndex,
            forcedLayers,
            visibleLayers,
            itemId,
            geoserverBVs,
            bvParcels,
            plu,
            displaySmartshape
        );
    });
};

const loadView = (
    container: any,
    reportLayersData: IReportLayersData,
    projectLayersData: IProjectLayersData,
    signalLayersData: ISignalLayersData,
    siteIndustrielLayersData: ISiteIndustrielLayersData,
    parcelLayersData: IParcelLayersData,
    pluLayersData: IPluLayersData,
    bvCollectorLayersData: any,
    coordinates: [number, number, number],
    setMapPosition: (coordinates: [number, number, number]) => void,
    displayBVLayers: boolean,
    displayProjectLayers: boolean,
    displaySignalLayers: boolean,
    displaySiteIndustrielLayers: boolean,
    displayParcelLayers: boolean,
    displayPluLayers: boolean,
    baseMapIndex?: number,
    forcedLayers?: string[],
    visibleLayers?: string[],
    itemId?: string,
    geoserverBVs?: any,
    bvParcels?: any,
    plu?: any,
    displaySmartshape?: any,
) => {
    loadedMapView.container = container;
    loadedMapView.ui.add("save-view", "top-right");
    loadedMapView.ui.add("load-view", "top-right");
    loadedMapView
        .when(() => {
            if (baseMapIndex) {
                changeBaseMap(baseMapIndex);
            }
        })
        .then(() => {
            //desactivate zoomToCoordinates here because zoomToCoordinates it's already called in app/src/pages/Report/index.tsx
            //console.log('zoomToCoordinates 5',coordinates)
            //zoomToCoordinates(coordinates[0], coordinates[1], coordinates[2]);
            if (displayBVLayers) {
                createAllBVLayers(geoserverBVs, bvParcels, bvCollectorLayersData, visibleLayers ? visibleLayers : []);
            }
            if (displayParcelLayers) {
                createParcelLayers(parcelLayersData, visibleLayers ? visibleLayers : []);
            }
            if (displayProjectLayers) {
                createProjectLayers(projectLayersData, visibleLayers ? visibleLayers : []);
            }
            if (displaySignalLayers) {
                createSignalLayers(signalLayersData, visibleLayers ? visibleLayers : []);
            }
            if (forcedLayers) {
                forceLayersOnLoad(forcedLayers);
            }
            if (visibleLayers && visibleLayers.length > 0) {
                chooseLayers(visibleLayers);
            }
            if (plu && displayPluLayers) {
                createPluLayers(plu, visibleLayers ? visibleLayers : []);
            }
            if (displaySiteIndustrielLayers && siteIndustrielLayersData) {
                createAllSiteIndustrielLayers(siteIndustrielLayersData, visibleLayers ? visibleLayers : []);

            }
            createConformityAndMajorFaultTypeLayers(reportLayersData, visibleLayers ? visibleLayers : []);
            if (itemId) {
                const objectId: string = itemId;
                setTimeout(() => {
                    highlightObjects([{objectId, layerName: "Conduite_de_branchement"}]);
                }, 5000);
            }
        })
        .catch(noop);
    loadedMapView.on("pointer-up", () => {
        setMapPosition(getMapPosition());
        //let showSmartshape=true;
        //displaySmartshape({showSmartshape});
    });
    loadedMapView.on("mouse-wheel", () => {
        setMapPosition(getMapPosition());
        //let showSmartshape=true;
        //displaySmartshape({showSmartshape});
    });
};

export const initCustomLayer = () => {
    // Remove old layer
    if (customLayer !== undefined) {
        loadedMapView.map.remove(customLayer);
    }
};

export const createAllBVLayers = (
    geoserverBVs: any,
    bvParcels: any,
    bvCollectorLayersData: any,
    visibleLayers: string[],
) => {
    if (geoserverBVs && geoserverBVs.length) {
        //conduite de branchement
        createBVLayer(geoserverBVs, visibleLayers ? visibleLayers : []);
    }
    if (bvParcels) {
        //Déconnexion des eaux pluviales
        createBVParcelLayer(bvParcels, visibleLayers ? visibleLayers : []);
    }

    //COLLECTEURS
    createBVCollectorLayer(bvCollectorLayersData, visibleLayers ? visibleLayers : []);

/*
    if (loadedMapView && custombvGroupLayer && custombvConformityGroupLayer) {
        loadedMapView.map.layers.forEach((layer: any) => {
            // Watch change on service/FeatureLayer
            loadedWatchUtils.watch(layer, "visible", () => {
                if (bvGroupLayerTitle === layer.title) {
                    if (updateVisibleLayer) {
                        //updateVisibleLayers(getVisibleLayersList());
                    }
                }
            });

            if (layer.type === "group") {
                // Attente que le service soit chargé
                loadedWatchUtils.watch(layer, "loaded", () => {
                    // Recuperer la liste des sous-groupes/sous-couches
                    layer.layers.flatten((item: any) => {
                        return item.layers;
                    }).forEach((item: any) => {
                        // Watch on subLayer visibility
                        loadedWatchUtils.watch(item, "visible", () => {
                            if (
                                (item.parent && bvGroupLayerTitle === item.parent.title) ||
                                (item.parent.parent && bvGroupLayerTitle === item.parent.parent.title) ||
                                (item.parent.parent.parent && bvGroupLayerTitle === item.parent.parent.parent.title)
                            ) {
                                if (updateVisibleLayer) {
                                    //updateVisibleLayers(getVisibleLayersList());
                                }
                            }
                        });
                    });
                });
            }
        });
        //chooseBvLayers(loadedMapView, visibleLayers ? visibleLayers : []);
    }
*/

};

// Bassin versant conduite de branchement
export const createBVLayer = (geoserverBVs: any, visibleLayers: string[]) => {
    initBVGraphicLayer();
    if (groupLayer) {
        // custombvConformityGroupLayer.when().then(() => {
        custombvSubGroupLayer = new groupLayer({
            title: i18next.t("thematicMap:bvReportLayerTitle"),
            visibility: true,
            visibilityMode: "independent",
        });
        custombvConformityGroupLayer.add(custombvSubGroupLayer);
        const nonConformeGreater20 = geoserverBVs.filter((item: any) => "rouge" === item.colorName);
        generateCustomBvGraphisLayer(
            i18next.t("thematicMap:geoserverBVs:nonConforme_greater_20"),
            nonConformeGreater20,
            custombvSubGroupLayer,
        );
        const nonConformeGreater10 = geoserverBVs.filter((item: any) => "jaune" === item.colorName);
        generateCustomBvGraphisLayer(
            i18next.t("thematicMap:geoserverBVs:nonConforme_greater_10"),
            nonConformeGreater10,
            custombvSubGroupLayer,
        );
        const nonConformeGreater5 = geoserverBVs.filter((item: any) => "bleu" === item.colorName);
        generateCustomBvGraphisLayer(
            i18next.t("thematicMap:geoserverBVs:nonConforme_greater_5"),
            nonConformeGreater5,
            custombvSubGroupLayer,
        );
        const nonConformeLess5 = geoserverBVs.filter((item: any) => "vert" === item.colorName);
        generateCustomBvGraphisLayer(
            i18next.t("thematicMap:geoserverBVs:nonConforme_less_5"),
            nonConformeLess5,
            custombvSubGroupLayer,
        );
    }
};

export const initBVGraphicLayer = () => {
    // Remove old layer
    if (custombvSubGroupLayer !== undefined) {
        custombvConformityGroupLayer.remove(custombvSubGroupLayer);
    }
    if (groupLayer) {
        if (custombvGroupLayer === undefined) {
            custombvGroupLayer = new groupLayer({
                title: i18next.t("thematicMap:bvLayerGroupTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            loadedMapView.map.add(custombvGroupLayer, 0);
        } else {
            loadedMapView.map.add(custombvGroupLayer, 0);
        }
        if (custombvConformityGroupLayer === undefined) {
            custombvConformityGroupLayer = new groupLayer({
                title: i18next.t("thematicMap:bvLayerTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            custombvGroupLayer.add(custombvConformityGroupLayer, 0);
        }
    }
};

//COLLECTEURS
export const createBVCollectorLayer = (bvCollectors: IBVCollectorLayersData, visibleLayers: string[]) => {
    initBVCollectorGraphicLayer();

    if (groupLayer) {
        custombvCollectorGroupLayer = new groupLayer({
            title: i18next.t("thematicMap:bvCollectorLayerTitle"),
            visibility: true,
            visibilityMode: "independent",
        });
        custombvGroupLayer.add(custombvCollectorGroupLayer);

        generateCustomBvCollectorGraphisLayer(
            "SAN",
            bvCollectors.SAN,
            custombvCollectorGroupLayer,
        );

        generateCustomBvCollectorGraphisLayer(
            "CAA",
            bvCollectors.CAA,
            custombvCollectorGroupLayer,
        );

        generateCustomBvCollectorGraphisLayer(
            "DES2B",
            bvCollectors.DES2B,
            custombvCollectorGroupLayer,
        );

        generateCustomBvCollectorGraphisLayer(
            "SAR",
            bvCollectors.SAR,
            custombvCollectorGroupLayer,
        );

        generateCustomBvCollectorGraphisLayer(
            "CAB",
            bvCollectors.CAB,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "CLI",
            bvCollectors.CLI,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "ES1B",
            bvCollectors.ES1B,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "MAR",
            bvCollectors.MAR,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "NL",
            bvCollectors.NL,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "ANT",
            bvCollectors.ANT,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "BLR",
            bvCollectors.BLR,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "SUR",
            bvCollectors.SUR,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "NE",
            bvCollectors.NE,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "ASN",
            bvCollectors.ASN,
            custombvCollectorGroupLayer,
        );
        generateCustomBvCollectorGraphisLayer(
            "LAS",
            bvCollectors.LAS,
            custombvCollectorGroupLayer,
        );
    }
};

export const initBVCollectorGraphicLayer = () => {
    if (custombvCollectorGroupLayer !== undefined) {
        custombvGroupLayer.remove(custombvCollectorGroupLayer);
    }
    if (groupLayer) {
        if (custombvGroupLayer === undefined) {
            custombvGroupLayer = new groupLayer({
                title: i18next.t("thematicMap:bvLayerGroupTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            loadedMapView.map.add(custombvGroupLayer, 0);
        } else {
            loadedMapView.map.add(custombvGroupLayer, 0);
        }
    }
};

//Déconnexion des eaux pluviales
export const createBVParcelLayer = (bvParcels: IBVParcelLayersData, visibleLayers: string[]) => {
    initBVParcelGraphicLayer();
    if (groupLayer) {
        custombvParcelGroupLayer = new groupLayer({
            title: i18next.t("thematicMap:bvParcelLayerTitle"),
            visibility: true,
            visibilityMode: "independent",
        });
        custombvConformityGroupLayer.add(custombvParcelGroupLayer);

        generateCustomBvParcelGraphisLayer(
            i18next.t("thematicMap:bvParcels:less_1"),
            bvParcels.lessthan1,
            [255, 0, 0, 0.8],
            custombvParcelGroupLayer,
        );

        generateCustomBvParcelGraphisLayer(
            i18next.t("thematicMap:bvParcels:between_1_and_5"),
            bvParcels.between1and5,
            [255, 165, 0, 0.8],
            custombvParcelGroupLayer,
        );

        generateCustomBvParcelGraphisLayer(
            i18next.t("thematicMap:bvParcels:greater_5"),
            bvParcels.greaterThan5,
            [64, 128, 0, 0.8],
            custombvParcelGroupLayer,
        );
    }
};

export const initBVParcelGraphicLayer = () => {
    if (custombvParcelGroupLayer !== undefined) {
        custombvConformityGroupLayer.remove(custombvParcelGroupLayer);
    }
    if (groupLayer) {
        if (custombvGroupLayer === undefined) {
            custombvGroupLayer = new groupLayer({
                title: i18next.t("thematicMap:bvLayerGroupTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            loadedMapView.map.add(custombvGroupLayer, 0);
        } else {
            loadedMapView.map.add(custombvGroupLayer, 0);
        }
        if (custombvConformityGroupLayer === undefined) {
            custombvConformityGroupLayer = new groupLayer({
                title: i18next.t("thematicMap:bvLayerTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            custombvGroupLayer.add(custombvConformityGroupLayer, 0);
        }
    }
};
//--------------------------------------------------------------------
//--------------------start layer indus------------------------------------------------

export const createAllSiteIndustrielLayers = (
    siteIndustrielLayersData: ISiteIndustrielLayersData,
    visibleLayers: string[],
) => {
    const dataRejet=JSON.parse(JSON.stringify(siteIndustrielLayersData));
    const dataSuivi=JSON.parse(JSON.stringify(siteIndustrielLayersData));
    createSiteIndustrielLayers(dataRejet, visibleLayers ? visibleLayers : [])
    createSuiviSiteIndustrielLayers(dataSuivi, visibleLayers ? visibleLayers : [])


 /*   if (loadedMapView && customSiteIndusGroupLayer) {
        loadedMapView.map.layers.forEach((layer: any) => {
            // Watch change on service/FeatureLayer
            loadedWatchUtils.watch(layer, "visible", () => {
                if (siteIndustrieGroupLayerTitle === layer.title) {
                    if (updateVisibleLayer) {
                        //updateVisibleLayers(getVisibleLayersList());
                    }
                }
            });

            if (layer.type === "group") {
                // Attente que le service soit chargé
                loadedWatchUtils.watch(layer, "loaded", () => {
                    // Recuperer la liste des sous-groupes/sous-couches
                    layer.layers.flatten((item: any) => {
                        return item.layers;
                    }).forEach((item: any) => {
                        // Watch on subLayer visibility
                        loadedWatchUtils.watch(item, "visible", () => {
                            if (
                                (item.parent && siteIndustrieGroupLayerTitle === item.parent.title) ||
                                (item.parent.parent && siteIndustrieGroupLayerTitle === item.parent.parent.title) ||
                                (item.parent.parent.parent && siteIndustrieGroupLayerTitle === item.parent.parent.parent.title)
                            ) {
                                if (updateVisibleLayer) {
                                    //updateVisibleLayers(getVisibleLayersList());
                                }
                            }
                        });
                    });
                });
            }
        });
        //chooseBvLayers(loadedMapView, visibleLayers ? visibleLayers : []);
    }
*/
};


export const createSiteIndustrielLayers = (
    siteIndustrielLayersData: ISiteIndustrielLayersData,
    visibleLayers: string[],
) => {
    initSiteIndustrielGraphicLayer();

    if (groupLayer) {
        customSiteIndustrielGroupLayer = new groupLayer({
            title: i18next.t("report:siteIndustrielLayer.title"),
            visibility: true,
            visibilityMode: "independent",
        });
        customSiteIndusGroupLayer.add(customSiteIndustrielGroupLayer)

        generateCustomSiteIndustrielGraphisLayer(
            i18next.t("report:siteIndustrielLayer.siteIndustrielConform"),
            siteIndustrielLayersData.siteIndustrielConform,
            [0, 100, 0, 0.8],
            customSiteIndustrielGroupLayer,
            i18next.t("report:siteIndustrielLayer.title"),
        );
        generateCustomSiteIndustrielGraphisLayer(
            i18next.t("report:siteIndustrielLayer.siteIndustrielNotConform"),
            siteIndustrielLayersData.siteIndustrielNotConform,
            [255, 0, 0, 0.8],
            customSiteIndustrielGroupLayer,
            i18next.t("report:siteIndustrielLayer.title")
        );
        generateCustomSiteIndustrielGraphisLayer(
            i18next.t("report:siteIndustrielLayer.siteIndustrielNotConcerned"),
            siteIndustrielLayersData.siteIndustrielNotConcerned,
            [128, 128, 128, 0.8],
            customSiteIndustrielGroupLayer,
            i18next.t("report:siteIndustrielLayer.title")
        );
        generateCustomSiteIndustrielGraphisLayer(
            i18next.t("report:siteIndustrielLayer.siteIndustrielUnknown"),
            siteIndustrielLayersData.siteIndustrielUnknown,
            [0, 0, 255, 0.8],
            customSiteIndustrielGroupLayer,
            i18next.t("report:siteIndustrielLayer.title")
        );
        /*if (loadedMapView) {
          loadedMapView.when().then(() => {
            loadedMapView.map.layers.forEach((layer: any) => {
              // Watch change on service/FeatureLayer
              loadedWatchUtils.watch(layer, "visible", () => {
                if (siteIndustrielLayerTitle === layer.title) {
                  if (updateVisibleLayer) {
                    //updateVisibleLayers(getVisibleLayersList());
                  }
                }
              });

              if (layer.type === "group") {
                // Attente que le service soit chargé
                loadedWatchUtils.watch(layer, "loaded", () => {

                  // Recuperer la liste des sous-groupes/sous-couches
                  layer.layers.flatten((item: any) => {
                    return item.layers;
                  }).forEach((item: any) => {
                    // Watch on subLayer visibility
                    loadedWatchUtils.watch(item, "visible", () => {
                      if (
                        siteIndustrielLayerTitle === item.parent.title
                      ) {
                        if (updateVisibleLayer) {
                          //updateVisibleLayers(getVisibleLayersList());
                        }
                      }
                    });
                  });
                });
              }
            });
            //chooseSiteIndustrielLayer(loadedMapView, visibleLayers);
          });
        }*/
   }
};
export const initSiteIndustrielGraphicLayer = () => {
    // Remove old layer
    if (customSiteIndustrielGroupLayer !== undefined) {
        customSiteIndusGroupLayer.remove(customSiteIndustrielGroupLayer)
    }
    if (groupLayer) {
        if (customSiteIndusGroupLayer === undefined) {
            customSiteIndusGroupLayer = new groupLayer({
                title: i18next.t("report:siteIndustrielGroupLayerTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            loadedMapView.map.add(customSiteIndusGroupLayer, 0);
        } else {
            loadedMapView.map.add(customSiteIndusGroupLayer, 0);
        }
    }
};

export const createSuiviSiteIndustrielLayers = (
    siteIndustrielLayersData: ISiteIndustrielLayersData,
    visibleLayers: string[],
) => {
    initSuiviSiteIndustrielGraphicLayer()
    if (groupLayer) {
        customSuiviSiteIndustrielGroupLayer = new groupLayer({
            title: i18next.t("report:suiviSiteIndustrielLayer.title"),
            visibility: true,
            visibilityMode: "independent",
        });
        customSiteIndusGroupLayer.add(customSuiviSiteIndustrielGroupLayer);
        //customSiteIndusGroupLayer.add(customSuiviSiteIndustrielGroupLayer)

        generateCustomSuiviSiteIndustrielGraphisLayer(
            i18next.t("report:suiviSiteIndustrielLayer.siteIndustrielActifSuivi"),
            siteIndustrielLayersData.siteIndustrielActifSuivi,
            [0, 143, 22, 0.8],
            customSuiviSiteIndustrielGroupLayer,
            i18next.t("report:suiviSiteIndustrielLayer.title")
        );
        generateCustomSuiviSiteIndustrielGraphisLayer(
            i18next.t("report:suiviSiteIndustrielLayer.siteIndustrielNotSuivi"),
            siteIndustrielLayersData.siteIndustrielNotSuivi,
            [155, 155, 155, 0.8],
            customSuiviSiteIndustrielGroupLayer,
            i18next.t("report:suiviSiteIndustrielLayer.title")
        );
        generateCustomSuiviSiteIndustrielGraphisLayer(
            i18next.t("report:suiviSiteIndustrielLayer.siteIndustrielFerme"),
            siteIndustrielLayersData.siteIndustrielFerme,
            [196,196,196, 0.8],
            customSuiviSiteIndustrielGroupLayer,
            i18next.t("report:suiviSiteIndustrielLayer.title")
        );

        /*if (loadedMapView) {
          loadedMapView.when().then(() => {
            loadedMapView.map.layers.forEach((layer: any) => {
              // Watch change on service/FeatureLayer
              loadedWatchUtils.watch(layer, "visible", () => {
                if (siteIndustrielLayerTitle === layer.title) {
                  if (updateVisibleLayer) {
                    //updateVisibleLayers(getVisibleLayersList());
                  }
                }
              });

              if (layer.type === "group") {
                // Attente que le service soit chargé
                loadedWatchUtils.watch(layer, "loaded", () => {

                  // Recuperer la liste des sous-groupes/sous-couches
                  layer.layers.flatten((item: any) => {
                    return item.layers;
                  }).forEach((item: any) => {
                    // Watch on subLayer visibility
                    loadedWatchUtils.watch(item, "visible", () => {
                      if (
                          siteIndustrielLayerTitle === item.parent.title
                      ) {
                        if (updateVisibleLayer) {
                          //updateVisibleLayers(getVisibleLayersList());
                        }
                      }
                    });
                  });
                });
              }
            });
            //chooseSiteIndustrielLayer(loadedMapView, visibleLayers);
          });
        }*/
    }
};
export const initSuiviSiteIndustrielGraphicLayer = () => {
    // Remove old layer
    if (customSuiviSiteIndustrielGroupLayer !== undefined) {
        customSiteIndusGroupLayer.remove(customSuiviSiteIndustrielGroupLayer)
    }
    if (groupLayer) {
        if (customSiteIndusGroupLayer === undefined) {
            customSiteIndusGroupLayer = new groupLayer({
                title: i18next.t("report:siteIndustrielGroupLayerTitle"),
                visibility: true,
                visibilityMode: "independent",
            });
            loadedMapView.map.add(customSiteIndusGroupLayer, 0);
        } else {
            loadedMapView.map.add(customSiteIndusGroupLayer, 0);
        }
    }

};
//--------------------------------------------------------------------
//--------------------end layer indus------------------------------------------------
export const createParcelLayers = (
    parcelLayersData: IParcelLayersData,
    visibleLayers: string[],
) => {
    initParcelGraphicLayer();
    if (groupLayer) {
        customParcelGroupLayer = new groupLayer({
            title: i18next.t("report:parcelLayer.title"),
            visibility: true,
            visibilityMode: "independent",
        });
        loadedMapView.map.add(customParcelGroupLayer, 0);
        generateCustomParcelGraphisLayer(
            i18next.t("report:parcelLayer.disconnected"),
            parcelLayersData.disconnected,
            [0, 195, 0, 0.8],
            customParcelGroupLayer,
        );
        generateCustomParcelGraphisLayer(
            i18next.t("report:parcelLayer.regulated"),
            parcelLayersData.regulated,
            [255, 211, 105, 0.8],
            customParcelGroupLayer,
        );
        generateCustomParcelGraphisLayer(
            i18next.t("report:parcelLayer.unknown"),
            parcelLayersData.unknown,
            [63, 103, 130, 0.8],
            customParcelGroupLayer,
        );
        if (loadedMapView) {
            loadedMapView.when().then(() => {
                loadedMapView.map.layers.forEach((layer: any) => {
                    // Watch change on service/FeatureLayer
                    loadedWatchUtils.watch(layer, "visible", () => {
                        if (parcelLayerTitle === layer.title) {
                            if (updateVisibleLayer) {
                                //updateVisibleLayers(getVisibleLayersList());
                            }
                        }
                    });

                    if (layer.type === "group") {
                        // Attente que le service soit chargé
                        loadedWatchUtils.watch(layer, "loaded", () => {

                            // Recuperer la liste des sous-groupes/sous-couches
                            layer.layers.flatten((item: any) => {
                                return item.layers;
                            }).forEach((item: any) => {
                                // Watch on subLayer visibility
                                loadedWatchUtils.watch(item, "visible", () => {
                                    if (
                                        parcelLayerTitle === item.parent.title
                                    ) {
                                        if (updateVisibleLayer) {
                                            //updateVisibleLayers(getVisibleLayersList());
                                        }
                                    }
                                });
                            });
                        });
                    }
                });
                //chooseParcelLayer(loadedMapView, visibleLayers);
            });
        }
    }
};

export const createPluLayers = (
    pluLayersData: IPluLayersData,
    visibleLayers: string[],
) => {
    initPluGraphicLayer();
    if (groupLayer) {
        customPluGroupLayer = new groupLayer({
            title: i18next.t("report:pluLayer.title"),
            visibility: true,
            visibilityMode: "independent",
        });
        loadedMapView.map.add(customPluGroupLayer, 0);
        generateCustomPluGraphisLayer(
            i18next.t("report:pluLayer.favorable"),
            pluLayersData.favorable,
            [0, 0, 255, 0.8],
            customPluGroupLayer,
        );
        generateCustomPluGraphisLayer(
            i18next.t("report:pluLayer.defavorable"),
            pluLayersData.defavorable,
            [128, 128, 128, 0.8],
            customPluGroupLayer,
        );
        generateCustomPluGraphisLayer(
            i18next.t("report:pluLayer.inexistant"),
            pluLayersData.inexistant,
            [0, 0, 0, 0.8],
            customPluGroupLayer,
        );
        if (loadedMapView) {
            loadedMapView.when().then(() => {
                loadedMapView.map.layers.forEach((layer: any) => {
                    // Watch change on service/FeatureLayer
                    loadedWatchUtils.watch(layer, "visible", () => {
                        if (pluLayerTitle === layer.title) {
                            if (updateVisibleLayer) {
                                //updateVisibleLayers(getVisibleLayersList());
                            }
                        }
                    });

                    if (layer.type === "group") {
                        // Attente que le service soit chargé
                        loadedWatchUtils.watch(layer, "loaded", () => {

                            // Recuperer la liste des sous-groupes/sous-couches
                            layer.layers.flatten((item: any) => {
                                return item.layers;
                            }).forEach((item: any) => {
                                // Watch on subLayer visibility
                                loadedWatchUtils.watch(item, "visible", () => {
                                    if (
                                        pluLayerTitle === item.parent.title
                                    ) {
                                        if (updateVisibleLayer) {
                                            //updateVisibleLayers(getVisibleLayersList());
                                        }
                                    }
                                });
                            });
                        });
                    }
                });
                //choosePluLayer(loadedMapView, visibleLayers);
            });
        }
    }
};

const generateCustomBvGraphisLayer = (layerTitle: string, objects: any, parentLayer?: typeof groupLayer) => {
    const bvGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,
    });
    objects.map((bv: any) => {
        const coordinates = bv.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });

        const polygon = {
            type: "polygon",
            rings: coordinates,
            spatialReference: {
                wkid: 4326,
            },
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color: bv.colorCode,
            outline: {
                color: [0, 0, 0],
                width: 1,
            },
        };
        const polygonAtt = {
            idBV: bv.id,
            coordinates: bv.coordinates,
            layerName: i18next.t("thematicMap:bvLayerTitle"),
        };
        const bvTemplate = {
            title: "bv",
            content: [{
                type: "fields",
                fieldInfos: [{
                    fieldName: "idBV",
                    label: "idBV",
                    visible: true,
                }],
            }],
        };

        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
            attributes: polygonAtt,
            popupTemplate: bvTemplate,
        });
        bvGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(bvGraphicLayer);
};

const generateCustomBvParcelGraphisLayer = (
    layerTitle: string,
    objects: any,
    color: number[],
    parentLayer?: typeof groupLayer) => {
    const bvParcelGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,
    });
    objects.map((bv: any) => {
        const coordinates = bv.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });

        const polygon = {
            type: "polygon",
            rings: coordinates,
            spatialReference: {
                wkid: 4326,
            },
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color,
            outline: {
                color: [0, 0, 0],
                width: 1,
            },
        };
        const polygonAtt = {
            idBV: bv.id,
            coordinates: bv.coordinates,
            layerName: i18next.t("thematicMap:bvLayerTitle"),
        };
        const bvParcelTemplate = {
            title: "bv parcel",
            content: [{
                type: "fields",
                fieldInfos: [{
                    fieldName: "idBV",
                    label: "idBV",
                    visible: true,
                }],
            }],
        };

        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
            attributes: polygonAtt,
            popupTemplate: bvParcelTemplate,
        });
        bvParcelGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(bvParcelGraphicLayer);
};

const generateCustomBvCollectorGraphisLayer = (
    layerTitle: string,
    objects: any,
    parentLayer?: typeof groupLayer) => {
    const bvCollectorGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,
    });
    objects.map((bv: any) => {
        const coordinates = bv.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });
        const polygon = {
            type: "polygon",
            rings: coordinates,
            spatialReference: {
                wkid: 4326,
            },
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color: [255, 255, 255, 1],
            outline: {
                color: [0, 0, 255],
                width: 2,
            },
        };
        const polygonAtt = {
            idBV: bv.id,
            coordinates: bv.coordinates,
            layerName: i18next.t("thematicMap:bvLayerTitle"),
        };
        const bvParcelTemplate = {
            title: "bv collector",
            content: [{
                type: "fields",
                fieldInfos: [{
                    fieldName: "idBV",
                    label: "idBV",
                    visible: true,
                }],
            }],
        };

        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
            attributes: polygonAtt,
            popupTemplate: bvParcelTemplate,
        });
        bvCollectorGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(bvCollectorGraphicLayer);
};


const generateCustomSiteIndustrielGraphisLayer = (
    layerTitle: string,
    objects: ISiteIndustriel[],
    color: number[],
    parentLayer?: typeof groupLayer,
    polygonLayerName?: string,
    isVisible: boolean = true
) => {
    const siteIndustrielGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,
        visible: isVisible
    });

    objects.map((object: any) => {
        const coordinates = object.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });
        const polygon = {
            type: "polygon",
            rings: coordinates,
            spatialReference: {
                wkid: 4326,
            },
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color,
            outline: {
                color: [0, 0, 0],
                width: 1,
            },
        };
        const polygonAtt = {
            idCD92: object.idCD92,
            coordinates: object.coordinates,
            //layerName: i18next.t("report:siteIndustrielLayer.title"),
            layerName: polygonLayerName,
        };
        const siteIndustrielTemplate = {
            title: "Site Industriel",
            content: [{
                type: "fields",
                fieldInfos: [{
                    fieldName: "idCD92",
                    label: "idCD92",
                    visible: isVisible
                }],
            }],
        };
        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
            attributes: polygonAtt,
            popupTemplate: siteIndustrielTemplate,
        });
        siteIndustrielGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(siteIndustrielGraphicLayer);
};



const generateCustomSuiviSiteIndustrielGraphisLayer = (
    layerTitle: string,
    objects: ISiteIndustriel[],
    color: number[],
    parentLayer?: typeof groupLayer,
    polygonLayerName?: string,
    isVisible: boolean = true
) => {
    const suiviSiteIndustrielGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,
        visible: isVisible
    });

    objects.map((object: any) => {
        const coordinates = object.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });
        const polygon = {
            type: "polygon",
            rings: coordinates,
            spatialReference: {
                wkid: 4326,
            },
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color,
            outline: {
                color: [0, 0, 0],
                width: 1,
            },
        };
        const polygonAtt = {
            idCD92: object.idCD92,
            coordinates: object.coordinates,
            //layerName: i18next.t("report:siteIndustrielLayer.title"),
            layerName: polygonLayerName,
        };
        const suiviSiteIndustrielTemplate = {
            title: "Suivi Site Industriel",
            content: [{
                type: "fields",
                fieldInfos: [{
                    fieldName: "idCD92",
                    label: "idCD92",
                    visible: isVisible
                }],
            }],
        };
        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
            attributes: polygonAtt,
            popupTemplate: suiviSiteIndustrielTemplate,
        });
        suiviSiteIndustrielGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(suiviSiteIndustrielGraphicLayer);
};


const generateCustomParcelGraphisLayer = (
    layerTitle: string,
    objects: IParcel[],
    color: number[],
    parentLayer?: typeof groupLayer,
) => {
    const parcelGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,

    });

    objects.map((object: any) => {
        const coordinates = object.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });
        const polygon = {
            type: "polygon",
            rings: coordinates,
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color,
            outline: {
                color: [0, 0, 0],
                width: 1,
            },
        };
        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
        });
        parcelGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(parcelGraphicLayer);
};

const generateCustomPluGraphisLayer = (
    layerTitle: string,
    objects: IPlu[],
    color: number[],
    parentLayer?: typeof groupLayer,
) => {
    const pluGraphicLayer = new loadedGraphicsLayer({
        title: layerTitle,
        opacity: 0.2,
    });

    objects.map((object: any) => {
        const coordinates = object.coordinates.map((coordinate: any) => {
            return coordinate.reverse();
        });
        const polygon = {
            type: "polygon",
            rings: coordinates,
        };

        const simpleFillSymbol = {
            type: "simple-fill",
            color,
            outline: {
                color: [0, 0, 0],
                width: 1,
            },
        };
        const polygonGraphic = new loadedGraphic({
            geometry: polygon,
            symbol: simpleFillSymbol,
        });
        pluGraphicLayer.add(polygonGraphic);
    });
    parentLayer.add(pluGraphicLayer);
};

export const initParcelGraphicLayer = () => {
    if (customParcelGroupLayer !== undefined) {
        loadedMapView.map.remove(customParcelGroupLayer);
    }
};

export const initPluGraphicLayer = () => {
    if (customPluGroupLayer !== undefined) {
        loadedMapView.map.remove(customPluGroupLayer);
    }
};

export const generateCustomAddressLayer = (address: any) => {
    initCustomLayer();
    if (loadedFeatureLayer) {
        const features = address.map((item: any) => {
            return {
                attributes: {
                    Address: item.address,
                    SearchTime: Date.now(),
                },
                geometry: {
                    type: "point",
                    x: item.location.x,
                    y: item.location.y,
                    spatialReference: {
                        wkid: 4326,
                    },
                },
            };
        });

        customLayer = new loadedFeatureLayer({
            geometryType: "point",
            objectIdField: "RechercheId",
            renderer: markerRenderer,
            source: features,
            title: i18n.t("category.address"),
        });
        loadedMapView.map.add(customLayer, 0);
    }
};

// 0 = latitude, 1 = longitude
const computeCoordinate = (point1: number[], point2: number[], percentage: number): [number, number] => {
    const newLat = point1[0] + ((point2[0] - point1[0]) * (percentage / 100));
    const newLong = point1[1] + ((point2[1] - point1[1]) * (percentage / 100));
    return [newLat, newLong];
};

export const createConformityAndMajorFaultTypeLayers = (
    reportLayersData: IReportLayersData,
    visibleLayers: string[],
) => {
    emptyGroupLayers();
    conformityLayer = generateCustomGroupLayer(i18next.t("report:conformityLayer.label"), true);
    generateCustomConformityLayer(
        reportLayersData.conform,
        i18next.t("report:conformity.conforme"),
        reportCircleRenderers.green,
        conformityLayer);
    nonConformLayer = generateCustomGroupLayer(i18next.t("report:conformity.non-conforme"), true, conformityLayer);
    generateCustomConformityLayer(
        reportLayersData.functioningNotConform,
        i18next.t("report:conformity.non-conforme-sans-dysfonctionnement"),
        reportCircleRenderers.orange,
        conformityLayer);
    generateCustomConformityLayer(
        reportLayersData.unknown,
        i18next.t("report:conformity.a-determiner"),
        reportCircleRenderers.grey,
        conformityLayer);
    majorFaultTypeLayer = generateCustomGroupLayer(i18next.t("report:majorFaultType.label"), true, nonConformLayer);
    generateCustomConformityLayer(
        reportLayersData.notConformOther,
        i18next.t("report:non-conforme-other.label"),
        reportCircleRenderers.red,
        nonConformLayer);
    generateCustomConformityLayer(
        reportLayersData.EPEUNotConform,
        i18next.t("report:conformity.EPEUNotConform"),
        reportCircleRenderers.redOutlinePurple,
        majorFaultTypeLayer);
    generateCustomConformityLayer(
        reportLayersData.EUEPNotConform,
        i18next.t("report:conformity.EUEPNotConform"),
        reportCircleRenderers.redOutlineRed,
        majorFaultTypeLayer);
    generateCustomConformityLayer(
        reportLayersData.EUEPEPEUNotConform,
        i18next.t("report:conformity.EUEPEPEUNotConform"),
        reportCircleRenderers.redOutlineBlack,
        majorFaultTypeLayer);
    if (loadedMapView) {
        loadedMapView.map.layers.forEach((layer: any) => {
            // Watch change on service/FeatureLayer
            loadedWatchUtils.watch(layer, "visible", () => {
                if (conformityLayerTitle === layer.title) {
                    if (updateVisibleLayer) {
                        //updateVisibleLayers(getVisibleLayersList());
                    }
                }
            });

            if (layer.type === "group") {
                // Attente que le service soit chargÃ©
                loadedWatchUtils.watch(layer, "loaded", () => {

                    // Recuperer la liste des sous-groupes/sous-couches
                    layer.layers.flatten((item: any) => {
                        return item.layers;
                    }).forEach((item: any) => {
                        // Watch on subLayer visibility
                        loadedWatchUtils.watch(item, "visible", () => {
                            if (
                                (item.parent && conformityLayerTitle === item.parent.title) ||
                                (item.parent.parent && conformityLayerTitle === item.parent.parent.title) ||
                                (item.parent.parent.parent && conformityLayerTitle === item.parent.parent.parent.title)
                            ) {
                                if (updateVisibleLayer) {
                                    //updateVisibleLayers(getVisibleLayersList());
                                }
                            }
                        });
                    });
                });
            }
        });
        //chooseConformityLayers(loadedMapView, visibleLayers);
    }
};

export const createProjectLayers = (
    projectLayersData: IProjectLayersData,
    visibleLayers: string[],
) => {
    emptyProjectGroupLayers();
    projectLayer = generateCustomGroupLayer(i18next.t("report:projectLayer.label"), true);
    generateCustomProjectLayer(
        projectLayersData.confirmed,
        i18next.t("report:projectLayer.confirmed"),
        projectIconRenderers.confirmed,
        projectLayer);
    generateCustomProjectLayer(
        projectLayersData.suspecte,
        i18next.t("report:projectLayer.suspecte"),
        projectIconRenderers.suspecte,
        projectLayer);
    generateCustomProjectLayer(
        projectLayersData.travaux,
        i18next.t("report:projectLayer.travaux"),
        projectIconRenderers.travaux,
        projectLayer);
    if (loadedMapView) {
        loadedMapView.when().then(() => {
            loadedMapView.map.layers.forEach((layer: any) => {
                // Watch change on service/FeatureLayer
                loadedWatchUtils.watch(layer, "visible", () => {
                    if (projectLayerTitle === layer.title) {
                        if (updateVisibleLayer) {
                            //updateVisibleLayers(getVisibleLayersList());
                        }
                    }
                });

                if (layer.type === "group") {
                    // Attente que le service soit chargé
                    loadedWatchUtils.watch(layer, "loaded", () => {

                        // Recuperer la liste des sous-groupes/sous-couches
                        layer.layers.flatten((item: any) => {
                            return item.layers;
                        }).forEach((item: any) => {
                            // Watch on subLayer visibility
                            loadedWatchUtils.watch(item, "visible", () => {
                                if (
                                    projectLayerTitle === item.parent.title
                                ) {
                                    if (updateVisibleLayer) {
                                        //updateVisibleLayers(getVisibleLayersList());
                                    }
                                }
                            });
                        });
                    });
                }
            });
            //chooseProjectLayer(loadedMapView, visibleLayers);
        });
    }
};

export const createSignalLayers = (
    signalLayersData: ISignalLayersData,
    visibleLayers: string[],
) => {
    emptySignalGroupLayers();
    signalLayer = generateCustomGroupLayer(i18next.t("report:signalLayer.label"), true);

    generateCustomSignalLayer(
        signalLayersData.free,
        i18next.t("report:signalLayer.free"),
        signalIconRenderers.free,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.harmful,
        i18next.t("report:signalLayer.harmful"),
        signalIconRenderers.harmful,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.noise,
        i18next.t("report:signalLayer.noise"),
        signalIconRenderers.noise,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.odour,
        i18next.t("report:signalLayer.odour"),
        signalIconRenderers.odour,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.overflow,
        i18next.t("report:signalLayer.overflow"),
        signalIconRenderers.overflow,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.rejection,
        i18next.t("report:signalLayer.rejection"),
        signalIconRenderers.rejection,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.road,
        i18next.t("report:signalLayer.road"),
        signalIconRenderers.road,
        signalLayer);
    generateCustomSignalLayer(
        signalLayersData.work,
        i18next.t("report:signalLayer.work"),
        signalIconRenderers.work,
        signalLayer);
    if (loadedMapView) {
        loadedMapView.when().then(() => {
            loadedMapView.map.layers.forEach((layer: any) => {
                // Watch change on service/FeatureLayer
                loadedWatchUtils.watch(layer, "visible", () => {
                    if (signalLayerTitle === layer.title) {
                        if (updateVisibleLayer) {
                            //updateVisibleLayers(getVisibleLayersList());
                        }
                    }
                });

                if (layer.type === "group") {
                    // Attente que le service soit chargé
                    loadedWatchUtils.watch(layer, "loaded", () => {

                        // Recuperer la liste des sous-groupes/sous-couches
                        layer.layers.flatten((item: any) => {
                            return item.layers;
                        }).forEach((item: any) => {
                            // Watch on subLayer visibility
                            loadedWatchUtils.watch(item, "visible", () => {
                                if (
                                    signalLayerTitle === item.parent.title
                                ) {
                                    if (updateVisibleLayer) {
                                        //updateVisibleLayers(getVisibleLayersList());
                                    }
                                }
                            });
                        });
                    });
                }
            });
            //chooseSignalLayer(loadedMapView, visibleLayers);
        });
    }
};

const emptyGroupLayers = () => {
    if (conformityLayer && majorFaultTypeLayer) {
        loadedMapView.map.remove(conformityLayer);
        loadedMapView.map.remove(majorFaultTypeLayer);
    }
};

const emptyProjectGroupLayers = () => {
    if (projectLayer) {
        loadedMapView.map.remove(projectLayer);
    }
};
const emptySignalGroupLayers = () => {
    if (signalLayer) {
        loadedMapView.map.remove(signalLayer);
    }
};
const generateCustomGroupLayer = (layerTitle: string, visibility: boolean, parentLayer?: typeof groupLayer) => {
    let layerAlreadyExist = false;
    if (groupLayer) {
        loadedMapView.map.allLayers.items.forEach((item: { title: string }) => {
            if (item.title === layerTitle) {
                layerAlreadyExist = true;
            }
        });
        if (!layerAlreadyExist) {
            const customGroupLayer = new groupLayer({
                title: layerTitle,
                visibility: true,
                visibilityMode: "independent",
            });
            if (parentLayer) {
                parentLayer.add(customGroupLayer, 0);
            } else {
                loadedMapView.map.add(customGroupLayer, 0);
            }
            customGroupLayer.visible = visibility;
            return customGroupLayer;
        }
    }
};

const generateCustomConformityLayer =
    (reports: IReport[], layerTitle: string, renderer: IRenderer, parentLayer?: typeof groupLayer) => {
        let assetLayerAlreadyExist = false;
        if (loadedFeatureLayer) {
            loadedMapView.map.allLayers.items.forEach((item: any) => {
                if (item.title === layerTitle) {
                    assetLayerAlreadyExist = true;
                }
            });
            if (!assetLayerAlreadyExist) {
                const features = reports.map((item: IReport) => {
                    let position: [number, number] = [0, 0];
                    if (item.position && "string" !== typeof item.position && item.position.length > 1) {
                        const point1 = item.position[0].map(Number);
                        const point2 = item.position[1].map(Number);
                        position = computeCoordinate(point1, point2, 33);
                    }
                    if (JSON.stringify(position) !== JSON.stringify([0, 0])) {
                        return {
                            attributes: {
                                Address: item.address,
                            },
                            geometry: {
                                type: "point",
                                // 0 = latitude, 1 = longitude
                                x: position[1],
                                y: position[0],
                                spatialReference: {
                                    wkid: 4326,
                                },
                            },
                        };
                    }
                });

                const customLayerReport = new loadedFeatureLayer({
                    geometryType: "point",
                    objectIdField: "RechercheId",
                    renderer,
                    source: features,
                    title: layerTitle,
                });
                if (parentLayer) {
                    parentLayer.add(customLayerReport, 0);
                } else {
                    loadedMapView.map.add(customLayerReport, 0);
                }
            }
        }
    };

const generateCustomProjectLayer =
    (projects: IProject[], layerTitle: string, renderer: IRenderer, parentLayer?: typeof groupLayer) => {
        let assetLayerAlreadyExist = false;
        if (loadedFeatureLayer) {
            loadedMapView.map.allLayers.items.forEach((item: any) => {
                if (item.title === layerTitle) {
                    assetLayerAlreadyExist = true;
                }
            });
            if (!assetLayerAlreadyExist) {
                const features = projects.map((item: IProject) => {
                    const position: [number, number] = [0, 0];
                    position[0] = item.latitude;
                    position[1] = item.longitude;
                    if (JSON.stringify(position) !== JSON.stringify([0, 0])) {
                        return {
                            attributes: {
                                Address: item.address,
                                ProjectId: item.id,
                                Project: item.id,
                            },
                            geometry: {
                                type: "point",
                                // 0 = latitude, 1 = longitude
                                x: position[1],
                                y: position[0],
                                spatialReference: {
                                    wkid: 4326,
                                },
                            },
                        };
                    }
                });
                const projectTemplate = {
                    title: "Project",
                    content: [{
                        type: "fields",
                        fieldInfos: [{
                            fieldName: "Project",
                            label: "Project",
                            visible: true,
                        }],
                    }],
                };
                const fields = [
                    new loadedField({
                        name: "ProjectId",
                        alias: "ProjectId",
                        type: "oid",
                    }),
                    new loadedField({
                        name: "Project",
                        alias: "Project",
                        type: "string",
                    }), new loadedField({
                        name: "Address",
                        alias: "Address",
                        type: "string",
                    }),
                ];
                const customLayerReport = new loadedFeatureLayer({
                    geometryType: "point",
                    renderer,
                    source: features,
                    title: layerTitle,
                    fields,
                    outFields: ["*"],
                    popupTemplate: projectTemplate,
                });
                if (parentLayer) {
                    parentLayer.add(customLayerReport, 0);
                } else {
                    loadedMapView.map.add(customLayerReport, 0);
                }
            }
        }
    };

const generateCustomSignalLayer =
    (signals: ISignal[], layerTitle: string, renderer: IRenderer, parentLayer?: typeof groupLayer) => {
        let assetLayerAlreadyExist = false;
        if (loadedFeatureLayer) {
            loadedMapView.map.allLayers.items.forEach((item: any) => {
                if (item.title === layerTitle) {
                    assetLayerAlreadyExist = true;
                }
            });
            if (!assetLayerAlreadyExist) {
                const features = signals.map((item: ISignal) => {
                    const position: [number, number] = [0, 0];
                    position[0] = item.latitude;
                    position[1] = item.longitude;
                    if (JSON.stringify(position) !== JSON.stringify([0, 0])) {
                        return {
                            attributes: {
                                Address: item.address,
                                SignalId: item.id,
                                Signal: item.id,
                            },
                            geometry: {
                                type: "point",
                                // 0 = latitude, 1 = longitude
                                x: position[1],
                                y: position[0],
                                spatialReference: {
                                    wkid: 4326,
                                },
                            },
                        };
                    }
                });
                const signalTemplate = {
                    title: "Project",
                    content: [{
                        type: "fields",
                        fieldInfos: [{
                            fieldName: "Signal",
                            label: "Signal",
                            visible: true,
                        }],
                    }],
                };
                const fields = [
                    new loadedField({
                        name: "SignalId",
                        alias: "SignalId",
                        type: "oid",
                    }),
                    new loadedField({
                        name: "Signal",
                        alias: "Signal",
                        type: "string",
                    }), new loadedField({
                        name: "Address",
                        alias: "Address",
                        type: "string",
                    }),
                ];
                const customLayerSignal = new loadedFeatureLayer({
                    geometryType: "point",
                    renderer,
                    source: features,
                    title: layerTitle,
                    fields,
                    outFields: ["*"],
                    popupTemplate: signalTemplate,
                });
                if (parentLayer) {
                    parentLayer.add(customLayerSignal, 0);
                } else {
                    loadedMapView.map.add(customLayerSignal, 0);
                }
            }
        }
    };

const chooseConformityLayers = (view: any, layersIDs: Array<string | number>) => {
    const mainLayerConformity = view.map.allLayers.find((item: { title: string }) => {
        return conformityLayerTitle === item.title;
    });
    if (mainLayerConformity && layersIDs) {
        //updateVisibleLayer = false;
        mainLayerConformity.when().then(() => {
            mainLayerConformity.visible = layersIDs.includes(mainLayerConformity.title);
            const layers = mainLayerConformity.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
    const mainLayersMajorFaultTypes = view.map.allLayers.find((item: { title: string }) => {
        return majorFaultTypesLayerTitle === item.title;
    });
    if (mainLayersMajorFaultTypes && layersIDs) {
        //updateVisibleLayer = false;
        mainLayersMajorFaultTypes.when().then(() => {
            const layers = mainLayersMajorFaultTypes.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
};

const chooseBvLayers = (view: any, layersIDs: Array<string | number>) => {
    const mainLayerBv = view.map.allLayers.find((item: { title: string }) => {
        return bvGroupLayerTitle === item.title;
    });
    if (mainLayerBv) {
        //updateVisibleLayer = false;
        mainLayerBv.when().then(() => {
            mainLayerBv.visible = layersIDs.includes(mainLayerBv.title);
            const layers = mainLayerBv.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
    const mainBvCollectorLayer = view.map.allLayers.find((item: { title: string }) => {
        return bvCollectorLayerTitle === item.title;
    });
    if (mainBvCollectorLayer) {
        //updateVisibleLayer = false;
        mainBvCollectorLayer.when().then(() => {
            const layers = mainBvCollectorLayer.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
    const mainBvConformityLayer = view.map.allLayers.find((item: { title: string }) => {
        return bvLayerTitle === item.title;
    });
    if (mainBvConformityLayer) {
        //updateVisibleLayer = false;
        mainBvConformityLayer.when().then(() => {
            const layers = mainBvConformityLayer.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
    const mainBvSubLayer = view.map.allLayers.find((item: { title: string }) => {
        return bvSubLayerTitle === item.title;
    });
    if (mainBvSubLayer) {
        //updateVisibleLayer = false;
        mainBvSubLayer.when().then(() => {
            const layers = mainBvSubLayer.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
    const mainBvParcelLayer = view.map.allLayers.find((item: { title: string }) => {
        return bvParcelLayerTitle === item.title;
    });
    if (mainBvParcelLayer) {
        //updateVisibleLayer = false;
        mainBvParcelLayer.when().then(() => {
            const layers = mainBvParcelLayer.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
};

const choosePluLayer = (view: any, layersIDs: Array<string | number>) => {
    const mainLayerPlu = view.map.allLayers.find((item: { title: string }) => {
        return pluLayerTitle === item.title;
    });
    if (mainLayerPlu) {
        //updateVisibleLayer = false;
        mainLayerPlu.when().then(() => {
            mainLayerPlu.visible = layersIDs.includes(mainLayerPlu.title);
            const layers = mainLayerPlu.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
};
const chooseProjectLayer = (view: any, layersIDs: Array<string | number>) => {
    const mainLayerProject = view.map.allLayers.find((item: { title: string }) => {
        return projectLayerTitle === item.title;
    });
    if (mainLayerProject) {
        //updateVisibleLayer = false;
        mainLayerProject.when().then(() => {
            mainLayerProject.visible = layersIDs.includes(mainLayerProject.title);
            const layers = mainLayerProject.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
};

/*
const chooseSiteIndustrielLayer = (view: any, layersIDs: Array<string | number>) => {
  const mainLayerSiteIndustriel = view.map.allLayers.find((item: { title: string }) => {
    return siteIndustrielLayerTitle === item.title;
  });
  if (mainLayerSiteIndustriel) {
    //updateVisibleLayer = false;
    mainLayerSiteIndustriel.when().then(() => {
      mainLayerSiteIndustriel.visible = layersIDs.includes(mainLayerSiteIndustriel.title);
      const layers = mainLayerSiteIndustriel.layers.items
        .filter((item: any) => {
          return layersIDs.indexOf(item.title.toString()) === -1;
        });
      layers.forEach((layer: any) => (layer.visible = false));
    }).then(() => {
      //updateVisibleLayer = true;
    });
  }
};
*/

const chooseSignalLayer = (view: any, layersIDs: Array<string | number>) => {
    const mainLayerSignal = view.map.allLayers.find((item: { title: string }) => {
        return signalLayerTitle === item.title;
    });
    if (mainLayerSignal) {
        //updateVisibleLayer = false;
        mainLayerSignal.when().then(() => {
            mainLayerSignal.visible = layersIDs.includes(mainLayerSignal.title);
            const layers = mainLayerSignal.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
};

const chooseParcelLayer = (view: any, layersIDs: Array<string | number>) => {
    const mainLayerParcel = view.map.allLayers.find((item: { title: string }) => {
        return parcelLayerTitle === item.title;
    });
    if (mainLayerParcel) {
        //updateVisibleLayer = false;
        mainLayerParcel.when().then(() => {
            mainLayerParcel.visible = layersIDs.includes(mainLayerParcel.title);
            const layers = mainLayerParcel.layers.items
                .filter((item: any) => {
                    return layersIDs.indexOf(item.title.toString()) === -1;
                });
            layers.forEach((layer: any) => (layer.visible = false));
        }).then(() => {
            //updateVisibleLayer = true;
        });
    }
};
