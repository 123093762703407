import { IIndice } from "./indice.interfaces";

export const categories: {[key: string]: string} = {
    ASSET: "ASSET",
    DOC: "DOC",
    GEO: "GEO",
};

export const searchPrefix: {[key: string]: string} = {
    assets: "ASSET:",
    docs: "DOC:",
    map: "GEO:",
};

export const filterTypes: {[key: string]: string} = {
    facet: "facet",
    indice: "indice",
};

export const mapType2D: string = "2d";
export const mapType3D: string = "3d";

export const layers: { [key: string]: string } = {
    "Chemin_de_câbles_GAIA": "asset:chemin_de_cables_gaia",
    "2D_-_Chemin_de_câbles_GAIA": "asset:chemin_de_cables_gaia",
    "Chambre_à_sable": "asset:chambre_a_sable",
    "3D_-_Chambre_à_sable": "asset:chambre_a_sable",
    "Barrage_à_poutrelles": "asset:barrage_a_poutrelles",
    "Bassin_enterré": "asset:bassin_enterre",
    "Bassin_à_ciel_ouvert": "asset:bassin_a_ciel_ouvert",
    "Bouche_d'engouffrement": "asset:bouche_d_engouffrement",
    "Chute_-_Raccordement": "asset:chute_-_raccordement",
    "Chute_-_Regard": "asset:chute_-_regard",
    "Conduite_de_branchement": "asset:conduite_de_branchement",
    "Déversoir_d'orage": "asset:deversoir_d_orage",
    "Déversoir_d'orage_(déclaré_à_la_Police_de_l'eau)": "asset:deversoir_d_orage__declare_a_la_police_de_l_eau_",
    "Exutoire_(déclaré)": "asset:exutoire__declare_",
    "Pluviomètre": "asset:pluviometre",
    "Point_d'entrée_d'un_autre_maître_d'ouvrage": "asset:point_d_entree_d_un_autre_maitre_d_ouvrage",
    "Point_de_mesure": "asset:point_de_mesure",
    "Point_de_sortie_vers_autre_maître_d'ouvrage": "asset:point_de_sortie_vers_autre_maitre_d_ouvrage",
    "Point_haut_-_Raccordement": "asset:point_haut_-_raccordement",
    "Point_haut_-_Regard": "asset:point_haut_-_regard",
    "Porte_à_flot": "asset:porte_a_flot",
    "Poste_satellite": "asset:poste_satellite",
    "Tronçon_de_réseau": "Tronçon_de_réseau",
    "Tronçon_de_réseau_-_Collecteur": "asset:troncon_de_reseau_-_collecteur",
    "Tronçon_de_réseau_-_Galerie": "asset:troncon_de_reseau_-_galerie",
    "Tronçon_de_réseau_-_Raccordement": "asset:troncon_de_reseau_-_raccordement",
    "Tronçon_de_réseaux_partenaires_-_Collecteur": "asset:troncon_de_reseaux_partenaires_-_collecteur",
    "Tronçon_de_réseaux_partenaires_-_Galerie": "asset:troncon_de_reseaux_partenaires_-_galerie",
    "Tronçon_de_réseaux_partenaires_-_Raccordement": "asset:troncon_de_reseaux_partenaires_-_raccordement",
    "Galerie": "asset:troncon_de_reseau_-_galerie",
    "Raccordement": "asset:troncon_de_reseau_-_raccordement",
    "Raccordement_de_bouche_d'engouffrement": "asset:raccordement_de_bouche_d_engouffrement",
    "Regard_de_visite": "asset:regard_de_visite",
    "Regard_Zmnt": "asset:regard_de_visite",
    "Regard_Zsurf": "asset:regard_de_visite",
    "Regard_avec_une_profondeur_connue": "asset:regard_de_visite",
    "Regard_avec_une_profondeur_par_défaut": "asset:regard_de_visite",
    "Regard_de_branchement": "asset:regard_de_branchement",
    "Regard_de_branchement_avec_une_profondeur_connue": "asset:regard_de_branchement",
    "Regard_de_branchement_avec_une_profondeur_par_défaut": "asset:regard_de_branchement",
    "Regard_de_branchement_Zmnt": "asset:regard_de_branchement",
    "Regard_de_branchement_Zsurf": "asset:regard_de_branchement",
    "Regard_mixte": "asset:regard_mixte",
    "Rejet_vers_le_SIAAP": "asset:rejet_vers_le_siaap_ou_le_reseau_ville_de_paris",
    "Rejet_vers_le_SIAAP_ou_le_réseau_Ville_de_Paris": "asset:rejet_vers_le_siaap_ou_le_reseau_ville_de_paris",
    "Rejet_vers_le_milieu_naturel": "asset:rejet_vers_le_milieu_naturel",
    "Seuil_fixe": "asset:seuil_fixe",
    "Station_EP": "asset:station_ep",
    "Station_EU": "asset:station_eu",
    "Station_EU_et_Crue": "asset:station_eu_et_crue",
    "Station_anti_crue": "asset:station_anti_crue",
    "Tête_de_réseau_-_Raccordement": "asset:tete_de_reseau_-_raccordement",
    "Tête_de_réseau_-_Regard": "asset:tete_de_reseau_-_regard",
    "Vanne_asservie": "asset:vanne_asservie",
    "Vanne_non_asservie": "asset:vanne_non_asservie",
    "Vidange_de_siphon": "asset:vidange_de_siphon",
};

export const sceneLayers: { [key: string]: any } = {
    "2D_-_Chemin_de_câbles_GAIA": ["asset:chemin_de_cables_gaia"],
    "3D_-_Chambre_à_sable": ["asset:chambre_a_sable"],
    "Barrage_à_poutrelles": ["asset:barrage_a_poutrelles"],
    "Bassin_enterré": ["asset:bassin_enterre"],
    "Bassin_à_ciel_ouvert": ["asset:bassin_a_ciel_ouvert"],
    "Bouche_d'engouffrement": ["asset:bouche_d_engouffrement"],
    "Chambre_à_sable": ["asset:chambre_a_sable"],
    "Chemin_de_câbles_GAIA": ["asset:chemin_de_cables_gaia"],
    "Conduite_de_branchement": ["asset:conduite_de_branchement"],
    "Pluviomètre": ["asset:pluviometre"],
    "Point_de_mesure": ["asset:point_de_mesure"],
    "Porte_à_flot": ["asset:porte_a_flot"],
    "Poste_satellite": ["asset:poste_satellite"],
    "Regard_avec_une_profondeur_connue": ["asset:regard_de_visite"],
    "Regard_avec_une_profondeur_par_défaut": ["asset:regard_de_visite"],
    "Regard_de_branchement_avec_une_profondeur_connue": ["asset:regard_de_branchement"],
    "Regard_de_branchement_avec_une_profondeur_par_défaut": ["asset:regard_de_branchement"],
    "Regard_Zsurf": ["asset:regard_de_visite"],
    "Regard_Zmnt": ["asset:regard_de_visite"],
    "Regard_de_branchement_Zmnt": ["asset:regard_de_branchement"],
    "Regard_de_branchement_Zsurf": ["asset:regard_de_branchement"],
    "Rejet_vers_le_SIAAP": ["asset:rejet_vers_le_siaap_ou_le_reseau_ville_de_paris"],
    "Rejet_vers_le_milieu_naturel": ["asset:rejet_vers_le_milieu_naturel"],
    "Seuil_fixe": ["asset:seuil_fixe"],
    "Station_EP": ["asset:station_ep"],
    "Station_EU": ["asset:station_eu"],
    "Tronçon de réseau - Collecteur": ["asset:troncon_de_reseau_-_collecteur"],
    "Tronçon de réseau - Raccordement": ["asset:troncon_de_reseau_-_raccordement"],
    "Tronçon de réseau - Galerie": ["asset:troncon_de_reseau_-_galerie"],
    "Vanne_asservie": ["asset:vanne_asservie"],
    "Vanne_non_asservie": ["asset:vanne_non_asservie"],
};

export const mimeMap: { [key: string]: string } = {
    "video/3gpp2": "3g2",
    "video/3gp": "3gp",
    "video/3gpp": "3gp",
    "application/x-compressed": "7zip",
    "audio/x-acc": "aac",
    "audio/ac3": "ac3",
    "application/postscript": "ai",
    "audio/x-aiff": "aif",
    "audio/aiff": "aif",
    "audio/x-au": "au",
    "video/x-msvideo": "avi",
    "video/msvideo": "avi",
    "video/avi": "avi",
    "application/x-troff-msvideo": "avi",
    "application/macbinary": "bin",
    "application/mac-binary": "bin",
    "application/x-binary": "bin",
    "application/x-macbinary": "bin",
    "image/bmp": "bmp",
    "image/x-bmp": "bmp",
    "image/x-bitmap": "bmp",
    "image/x-xbitmap": "bmp",
    "image/x-win-bitmap": "bmp",
    "image/x-windows-bmp": "bmp",
    "image/ms-bmp": "bmp",
    "image/x-ms-bmp": "bmp",
    "application/bmp": "bmp",
    "application/x-bmp": "bmp",
    "application/x-win-bitmap": "bmp",
    "application/cdr": "cdr",
    "application/coreldraw": "cdr",
    "application/x-cdr": "cdr",
    "application/x-coreldraw": "cdr",
    "image/cdr": "cdr",
    "image/x-cdr": "cdr",
    "zz-application/zz-winassoc-cdr": "cdr",
    "application/mac-compactpro": "cpt",
    "application/pkix-crl": "crl",
    "application/pkcs-crl": "crl",
    "application/x-x509-ca-cert": "crt",
    "application/pkix-cert": "crt",
    "text/css": "css",
    "text/x-comma-separated-values": "csv",
    "text/comma-separated-values": "csv",
    "application/vnd.msexcel": "csv",
    "application/x-director": "dcr",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/x-dvi": "dvi",
    "message/rfc822": "eml",
    "application/x-msdownload": "exe",
    "video/x-f4v": "f4v",
    "audio/x-flac": "flac",
    "video/x-flv": "flv",
    "image/gif": "gif",
    "application/gpg-keys": "gpg",
    "application/x-gtar": "gtar",
    "application/x-gzip": "gzip",
    "application/mac-binhex40": "hqx",
    "application/mac-binhex": "hqx",
    "application/x-binhex40": "hqx",
    "application/x-mac-binhex40": "hqx",
    "text/html": "html",
    "image/x-icon": "ico",
    "image/x-ico": "ico",
    "image/vnd.microsoft.icon": "ico",
    "text/calendar": "ics",
    "application/java-archive": "jar",
    "application/x-java-application": "jar",
    "application/x-jar": "jar",
    "image/jp2": "jp2",
    "video/mj2": "jp2",
    "image/jpx": "jp2",
    "image/jpm": "jp2",
    "image/jpeg": "jpeg",
    "image/pjpeg": "jpeg",
    "application/x-javascript": "js",
    "application/json": "json",
    "text/json": "json",
    "application/vnd.google-earth.kml+xml": "kml",
    "application/vnd.google-earth.kmz": "kmz",
    "text/x-log": "log",
    "audio/x-m4a": "m4a",
    "application/vnd.mpegurl": "m4u",
    "audio/midi": "mid",
    "application/vnd.mif": "mif",
    "video/quicktime": "mov",
    "video/x-sgi-movie": "movie",
    "audio/mpeg": "mp3",
    "audio/mpg": "mp3",
    "audio/mpeg3": "mp3",
    "audio/mp3": "mp3",
    "video/mp4": "mp4",
    "video/mpeg": "mpeg",
    "application/oda": "oda",
    "audio/ogg": "ogg",
    "video/ogg": "ogg",
    "application/ogg": "ogg",
    "application/x-pkcs10": "p10",
    "application/pkcs10": "p10",
    "application/x-pkcs12": "p12",
    "application/x-pkcs7-signature": "p7a",
    "application/pkcs7-mime": "p7c",
    "application/x-pkcs7-mime": "p7c",
    "application/x-pkcs7-certreqresp": "p7r",
    "application/pkcs7-signature": "p7s",
    "application/pdf": "pdf",
    "application/octet-stream": "pdf",
    "application/x-x509-user-cert": "pem",
    "application/x-pem-file": "pem",
    "application/pgp": "pgp",
    "application/x-httpd-php": "php",
    "application/php": "php",
    "application/x-php": "php",
    "text/php": "php",
    "text/x-php": "php",
    "application/x-httpd-php-source": "php",
    "image/png": "png",
    "image/x-png": "png",
    "application/powerpoint": "ppt",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.ms-office": "ppt",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
    "application/x-photoshop": "psd",
    "image/vnd.adobe.photoshop": "psd",
    "audio/x-realaudio": "ra",
    "audio/x-pn-realaudio": "ram",
    "application/x-rar": "rar",
    "application/rar": "rar",
    "application/x-rar-compressed": "rar",
    "audio/x-pn-realaudio-plugin": "rpm",
    "application/x-pkcs7": "rsa",
    "text/rtf": "rtf",
    "text/richtext": "rtx",
    "video/vnd.rn-realvideo": "rv",
    "application/x-stuffit": "sit",
    "application/smil": "smil",
    "text/srt": "srt",
    "image/svg+xml": "svg",
    "application/x-shockwave-flash": "swf",
    "application/x-tar": "tar",
    "application/x-gzip-compressed": "tgz",
    "image/tiff": "tiff",
    "text/plain": "txt",
    "text/x-vcard": "vcf",
    "application/videolan": "vlc",
    "text/vtt": "vtt",
    "audio/x-wav": "wav",
    "audio/wave": "wav",
    "audio/wav": "wav",
    "application/wbxml": "wbxml",
    "video/webm": "webm",
    "audio/x-ms-wma": "wma",
    "application/wmlc": "wmlc",
    "video/x-ms-wmv": "wmv",
    "video/x-ms-asf": "wmv",
    "application/xhtml+xml": "xhtml",
    "application/excel": "xl",
    "application/msexcel": "xls",
    "application/x-msexcel": "xls",
    "application/x-ms-excel": "xls",
    "application/x-excel": "xls",
    "application/x-dos_ms_excel": "xls",
    "application/xls": "xls",
    "application/x-xls": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.ms-excel": "xlsx",
    "application/xml": "xml",
    "text/xml": "xml",
    "text/xsl": "xsl",
    "application/xspf+xml": "xspf",
    "application/x-compress": "z",
    "application/x-zip": "zip",
    "application/zip": "zip",
    "application/x-zip-compressed": "zip",
    "application/s-compressed": "zip",
    "multipart/x-zip": "zip",
    "text/x-scriptzsh": "zsh",
};

export const codeInseeToCity: { [key: string]: string } = {
    92002 : "Antony",
    94003 : "Arcueil",
    92004 : "Asnières-sur-Seine",
    92007 : "Bagneux",
    92009 : "Bois-Colombes",
    92012 : "Boulogne-Billancourt",
    92014 : "Bourg-la-Reine",
    94016 : "Cachan ",
    92019 : "Châtenay-Malabry",
    92020 : "Châtillon",
    92022 : "Chaville",
    92023 : "Clamart",
    92024 : "Clichy-la-Garenne",
    92025 : "Colombes",
    92026 : "Courbevoie",
    92032 : "Fontenay-aux-Roses",
    94034 : "Fresnes",
    92033 : "Garches",
    92036 : "Gennevilliers",
    92040 : "Issy-les-Moulineaux",
    94038 : "L'Haÿ-les-Roses",
    92035 : "La Garenne-Colombes",
    92060 : "Le Plessis-Robinson",
    92044 : "Levallois-Perret",
    92046 : "Malakoff",
    92047 : "Marnes-la-Coquette",
    91377 : "Massy",
    92048 : "Meudon",
    92049 : "Montrouge",
    92050 : "Nanterre",
    92051 : "Neuilly-sur-Seine",
    75114 : "Paris-14eme",
    75115 : "Paris-15eme",
    75116 : "Paris-16eme",
    75117 : "Paris-17eme",
    92062 : "Puteaux",
    92063 : "Rueil-Malmaison",
    92064 : "Saint-Cloud",
    93070 : "Saint-ouen",
    92071 : "Sceaux",
    92072 : "Sèvres",
    92073 : "Suresnes",
    92075 : "Vanves",
    92076 : "Vaucresson",
    78646 : "Versailles",
    92077 : "Ville-d'Avray",
    92078 : "Villeneuve-la-Garenne",
    78686 : "Viroflay",
};

export const indicesMapping: {[key: string]: IIndice} = {
    a_pumping_stations: {
        station_anti_crue: {
            indiceName: "asset:station_anti_crue",
            title: "indices.pumping_stations.station_anti_crue.title",
        },
        station_ep: {
            indiceName: "asset:station_ep",
            title: "indices.pumping_stations.station_ep.title",
        },
        station_eu: {
            indiceName: "asset:station_eu",
            title: "indices.pumping_stations.station_eu.title",
        },
        station_eu_et_crue: {
            indiceName: "asset:station_eu_et_crue",
            title: "indices.pumping_stations.station_eu_et_crue.title",
        },
        title: "indices.pumping_stations.title",
        vidange_de_siphon: {
            indiceName: "asset:vidange_de_siphon",
            title: "indices.pumping_stations.vidange_de_siphon.title",
        },
    },
    b_retention_pools: {
        bassin_a_ciel_ouvert: {
            indiceName: "asset:bassin_a_ciel_ouvert",
            title: "indices.retention_pools.bassin_a_ciel_ouvert.title",
        },
        bassin_enterre: {
            indiceName: "asset:bassin_enterre",
            title: "indices.retention_pools.bassin_enterre.title",
        },
        title: "indices.retention_pools.title",
    },
    c_sand_rooms: {
        chambre_a_sable: {
            indiceName: "asset:chambre_a_sable",
            title: "indices.sand_rooms.chambre_a_sable.title",
        },
        title: "indices.sand_rooms.title",
    },
    d_network: {
        chute_raccordement: {
            indiceName: "asset:chute_-_raccordement",
            title: "indices.network.chute_raccordement.title",
        },
        chute_regard: {
            indiceName: "asset:chute_-_regard",
            title: "indices.network.chute_regard.title",
        },
        deversoir_d_orage: {
            indiceName: "asset:deversoir_d_orage",
            title: "indices.network.deversoir_d_orage.title",
        },
        deversoir_d_orage__declare_a_la_police_de_l_eau_: {
            indiceName: "asset:deversoir_d_orage__declare_a_la_police_de_l_eau_",
            title: "indices.network.deversoir_d_orage__declare_a_la_police_de_l_eau_.title",
        },
        point_haut_raccordement: {
            indiceName: "asset:point_haut_-_raccordement",
            title: "indices.network.point_haut_raccordement.title",
        },
        point_haut_regard: {
            indiceName: "asset:point_haut_-_regard",
            title: "indices.network.point_haut_regard.title",
        },
        regard_de_visite: {
            indiceName: "asset:regard_de_visite",
            title: "indices.network.regard_de_visite.title",
        },
        regard_mixte: {
            indiceName: "asset:regard_mixte",
            title: "indices.network.regard_mixte.title",
        },
        tete_de_reseau_raccordement: {
            indiceName: "asset:tete_de_reseau_-_raccordement",
            title: "indices.network.tete_de_reseau_raccordement.title",
        },
        tete_de_reseau_regard: {
            indiceName: "asset:tete_de_reseau_-_regard",
            title: "indices.network.tete_de_reseau_regard.title",
        },
        title: "indices.network.title",
        troncon_de_reseau_collecteur: {
            indiceName: "asset:troncon_de_reseau_-_collecteur",
            title: "indices.network.troncon_de_reseau_-_collecteur.title",
        },
        troncon_de_reseau_galerie: {
            indiceName: "asset:troncon_de_reseau_-_galerie",
            title: "indices.network.troncon_de_reseau_-_galerie.title",
        },
        troncon_de_reseau_raccordement: {
            indiceName: "asset:troncon_de_reseau_-_raccordement",
            title: "indices.network.troncon_de_reseau_-_raccordement.title",
        },
        troncon_de_reseaux_partenaires_collecteur: {
            indiceName: "asset:troncon_de_reseaux_partenaires_-_collecteur",
            title: "indices.network.troncon_de_reseaux_partenaires_-_collecteur.title",
        },
        troncon_de_reseaux_partenaires_galerie: {
            indiceName: "asset:troncon_de_reseaux_partenaires_-_galerie",
            title: "indices.network.troncon_de_reseaux_partenaires_-_galerie.title",
        },
        troncon_de_reseaux_partenaires_raccordement: {
            indiceName: "asset:troncon_de_reseaux_partenaires_-_raccordement",
            title: "indices.network.troncon_de_reseaux_partenaires_-_raccordement.title",
        },
    },
    e_regulation_equipement: {
        barrage_a_poutrelles: {
            indiceName: "asset:barrage_a_poutrelles",
            title: "indices.regulation_equipement.barrage_a_poutrelles.title",
        },
        porte_a_flot: {
            indiceName: "asset:porte_a_flot",
            title: "indices.regulation_equipement.porte_a_flot.title",
        },
        seuil_fixe: {
            indiceName: "asset:seuil_fixe",
            title: "indices.regulation_equipement.seuil_fixe.title",
        },
        title: "indices.regulation_equipement.title",
        vanne_asservie: {
            indiceName: "asset:vanne_asservie",
            title: "indices.regulation_equipement.vanne_asservie.title",
        },
        vanne_non_asservie: {
            indiceName: "asset:vanne_non_asservie",
            title: "indices.regulation_equipement.vanne_non_asservie.title",
        },
    },
    f_entering: {
        bouche_d_engouffrement: {
            indiceName: "asset:bouche_d_engouffrement",
            title: "indices.entering.bouche_d_engouffrement.title",
        },
        conduite_de_branchement: {
            indiceName: "asset:conduite_de_branchement",
            title: "indices.entering.conduite_de_branchement.title",
        },
        point_d_entree_d_un_autre_maitre_d_ouvrage: {
            indiceName: "asset:point_d_entree_d_un_autre_maitre_d_ouvrage",
            title: "indices.entering.point_d_entree_d_un_autre_maitre_d_ouvrage.title",
        },
        raccordement_de_bouche_d_engouffrement: {
            indiceName: "asset:raccordement_de_bouche_d_engouffrement",
            title: "indices.entering.raccordement_de_bouche_d_engouffrement.title",
        },
        regard_de_branchement: {
            indiceName: "asset:regard_de_branchement",
            title: "indices.entering.regard_de_branchement.title",
        },
        title: "indices.entering.title",
    },
    g_outlet: {
        exutoire__declare_: {
            indiceName: "asset:exutoire__declare_",
            title: "indices.outlet.exutoire__declare_.title",
        },
        point_de_sortie_vers_autre_maitre_d_ouvrage: {
            indiceName: "asset:point_de_sortie_vers_autre_maitre_d_ouvrage",
            title: "indices.outlet.point_de_sortie_vers_autre_maitre_d_ouvrage.title",
        },
        rejet_vers_le_milieu_naturel: {
            indiceName: "asset:rejet_vers_le_milieu_naturel",
            title: "indices.outlet.rejet_vers_le_milieu_naturel.title",
        },
        rejet_vers_le_siaap_ou_le_reseau_ville_de_paris: {
            indiceName: "asset:rejet_vers_le_siaap_ou_le_reseau_ville_de_paris",
            title: "indices.outlet.rejet_vers_le_siaap_ou_le_reseau_ville_de_paris.title",
        },
        title: "indices.outlet.title",
    },
    h_metrology: {
        chemin_de_cables_gaia: {
            indiceName: "asset:chemin_de_cables_gaia",
            title: "indices.metrology.chemin_de_cables_gaia.title",
        },
        pluviometre: {
            indiceName: "asset:pluviometre",
            title: "indices.metrology.pluviometre.title",
        },
        point_de_mesure: {
            indiceName: "asset:point_de_mesure",
            title: "indices.metrology.point_de_mesure.title",
        },
        poste_satellite: {
            indiceName: "asset:poste_satellite",
            title: "indices.metrology.poste_satellite.title",
        },
        title: "indices.metrology.title",
    },
};
