import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzDatePicker, SzInput, SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import { bvCollectors, bvSectors, projectCities, siteConformities, siteDomains } from "../../../config/report";
import { defaultProperties } from "../../../manager/map/config";
import { goToHome, zoomToCoordinates } from "../../../manager/map/navigation";
import { reportTabs } from "../../../pages/Report";
import { Report as ReportService } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IGeoserverBVFilter,
  IReportData,
} from "../../../store/redux/types";
import AutoCompleteInput from "../../Utils/AutoCompleteInput/AutoCompleteInput";
import ListHeader from "./ListHeader";

const GeoserverBVListFilters = (props: any): ReactElement => {

  const { t } = useTranslation();
  const sectorOptions = Object.entries(bvSectors).map((item: any) => {
    return { label: item[1], value: item[0] };
  });
  const collectorOptions = Object.entries(bvCollectors).map((item: any) => {
    return { label: item[1], value: item[0] };
  });

  const autoCompleteMinLength = 3;
  const [autocompleteOptions, setAutocompleteOptions] = useState([""]);
  //console.log("props.geoserverBVFilters",props.geoserverBVFilters)
  const [localId, setId] = useState<string>(props.geoserverBVFilters.id);
  const [localSector, setSector] = useState<any[]>(props.geoserverBVFilters.sector);
  const [localCollector, setCollector] = useState<any[]>(props.geoserverBVFilters.collector);
  const [startDateClass, setstartDateClass] = useState<Date | null>(props.geoserverBVFilters.startDateClass);
  const [dateClasEndDate, setdateClasEndDate] = useState<Date | null>(props.geoserverBVFilters.endDateClass);


  const updatedateClasDate = (newDate: Date, startOrEnd: string) => {
    if (startDateClass && dateClasEndDate) {
      if ("start" === startOrEnd && newDate.getTime() <= dateClasEndDate.getTime()) {
        setstartDateClass(newDate);
      } else if (newDate.getTime() >= startDateClass.getTime()) {
        setdateClasEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setstartDateClass(newDate);
      } else {
        setdateClasEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (props.geoserverBVFilters.id) {
      setId(props.geoserverBVFilters.id);
    }
    if (props.geoserverBVFilters.sector) {
      const sectorValues = sectorOptions.filter((item: any) => {
        return props.geoserverBVFilters.sector.includes(item.value);
      });
      setSector(sectorValues);
    }
    if (props.geoserverBVFilters.collector) {
      const collectorValues = collectorOptions.filter((item: any) => {
        return props.geoserverBVFilters.collector.includes(item.value);
      });
      setCollector(collectorValues);
    }
    if (props.geoserverBVFilters.startDateClass) {
      if (typeof props.geoserverBVFilters.startDateClass !== "object") {
        const startingDate = new Date(props.geoserverBVFilters.startDateClass);
        setstartDateClass(startingDate);
      }
    }
    if (props.geoserverBVFilters.endDateClass) {
      if (typeof props.geoserverBVFilters.endDateClass !== "object") {
        const endingDate = new Date(props.geoserverBVFilters.endDateClass);
        setdateClasEndDate(endingDate);
      }
    }
  }, [props.currentGeoserverBVFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  const isFiltersEmpty = () => {
    return ((!localSector || localSector.length === 0) &&
        (!localId || localId === "") &&
        (!localCollector || localCollector.length === 0) &&
        (!startDateClass) && (!dateClasEndDate));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());

  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localId,
    localCollector,
    localSector,
    startDateClass,
    dateClasEndDate
  ]);

  useEffect(() => {
    if (areFiltersEmpty) {
      resetAction();
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    const geoserverBVFilters: IGeoserverBVFilter = {
      id: localId?.replaceAll(" ", "_"),
      sector: localSector?.map((item) => item.value),
      collector: localCollector?.map((item) => item.value),
      startDateClass: startDateClass,
      endDateClass: dateClasEndDate
    };
    props.updateGeoserverBVFilters(geoserverBVFilters);
  };

  const resetAction = () => {
    setId("");
    setSector([]);
    setCollector([]);
    setstartDateClass(null);
    setdateClasEndDate(null);
    const filters: IGeoserverBVFilter = {
      id: "",
      sector: [],
      collector: [],
      startDateClass: null,
      endDateClass: null
    };
    props.updateGeoserverBVFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset geoserver BV filters`, category: "Report" });
  };

  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:geoserverBV.id")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary name"
            placeholder={""}
            value={localId}
            onChange={(event: { target: { value: string } }) => setId(event.target.value)}
            handleClear={() => setId("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:geoserverBV.Secteur")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="sector"
            name="sector"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setSector);
            }}
            options={sectorOptions}
            value={localSector}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>
              {t("report:geoserverBV.Collecteur")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="collector"
            name="collector"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCollector);
            }}
            options={collectorOptions}
            value={localCollector}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:geoserverBV.PeriodeDateClassement")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
              dateRange
              label={"date"}
              endDate={dateClasEndDate}
              endMinDate={null}
              startDate={startDateClass}
              startMinDate={null}
              onChangeEndDate={(value: Date) => {
                value.setHours(12, 0, 0, 0);
                updatedateClasDate(value, "end");
              }}
              onChangeStartDate={(value: Date) => {
                value.setHours(12, 0, 0, 0);
                updatedateClasDate(value, "start");
              }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    currentGeoserverBVFavFilter: state.report.currentGeoserverBVFavFilter,
    geoserverBVCurrentPage: state.report.geoserverBVCurrentPage,
    currentTab: state.report.currentTab,
    geoserverBVFilters: state.report.geoserverBVFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeoserverBVListFilters);
