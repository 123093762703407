import i18next from "i18next";
import React, { ReactElement } from "react";
import { ASSET_MAPPING } from "../../config/asset-map";
import { IAssetDetailsTab, IAssetDetailsTabProperty } from "../../config/asset.interfaces";
import "./detail.scss";
import DetailsTable from "./detailsTable";

interface ISynthesis {
  item: any;
  getDisplayedValue: (item: any, property: IAssetDetailsTabProperty) => string;
  tabLabel: string;
}

const Synthesis = (props: ISynthesis): ReactElement => {
  const map = ASSET_MAPPING[props.item["index:name"]].find((item: IAssetDetailsTab) => item.label === props.tabLabel);
  if (!map || typeof map === "undefined") {
    return (<div>{i18next.t("no-data")}</div>);
  }
  const properties = map.properties;
  const values = properties.map((property: IAssetDetailsTabProperty) => {
    return [
      i18next.t(`asset:${props.tabLabel}.${property.label}`),
      props.getDisplayedValue(props.item, property),
    ];
  });

  return (
    <DetailsTable values={values} />
  );
};

export default Synthesis;
