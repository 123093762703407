import { API_ROUTES } from "../config/routes";
import { IUserMapPreferences } from "../store/redux/types";
import Request, { HTTPMethods } from "./Request";

export class User {

  public static getMapPreferences() {
    const url = API_ROUTES.USER_GET_SET_MAP_PREFERENCES;
    return Request.sendRequest(url, HTTPMethods.GET, {}, false, false)
      .then((res: { data: any }) => ( res.data));
  }

  public static setMapPreferences(userMapPreferences: IUserMapPreferences) {
    const url = API_ROUTES.USER_GET_SET_MAP_PREFERENCES;
    return Request.sendRequest(url, HTTPMethods.PUT, this.getRequestBody(userMapPreferences), true, false)
      .then((res: { data: any }) => ( res.data)).catch(e => {
          console.log(e);
        });
  }

  private static getRequestBody(userMapPreferences: IUserMapPreferences) {
    const body: { mapBackground?: number, forcedLayers?: string[], visibleLayers?: string[]} = {};
    if (userMapPreferences.baseMap || userMapPreferences.baseMap === 0) {
      body.mapBackground = userMapPreferences.baseMap;
    }
    if (userMapPreferences.forcedLayers) {
      body.forcedLayers = userMapPreferences.forcedLayers;
    }
    if (userMapPreferences.visibleLayers) {
      body.visibleLayers = userMapPreferences.visibleLayers;
    }
    return body;
  }
}
