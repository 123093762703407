import { USER } from "../constants";
import { IUser, UserActionTypes } from "../types";

export const mapOrigin: [number, number, number] = [48.815457026770694, 2.2898999999999616, 288895.277144];

const userReducerDefaultState: IUser = {
  authenticationToken: "",
  email: "",
  employeeType: "",
  firstName: "",
  homeMapPosition: mapOrigin,
  homePhone: "",
  lastName: "",
  listType: "control",
  logged: false,
  mapPreferences: {
    baseMap: NaN,
    forcedLayers: [],
    visibleLayers: [],
  },
  mobile: "",
  reportMapPosition: mapOrigin,
  thematicMapPosition: mapOrigin,
  userManualFileDownload: "",
};

const userReducer = (
  state = userReducerDefaultState,
  action: UserActionTypes,
): IUser => {
  switch (action.type) {
    case USER.LOG_IN:
      return {
        ...state,
        authenticationToken: action.payload.authenticationToken,
        email: action.payload.email,
        employeeType: action.payload.employeeType,
        firstName: action.payload.firstName,
        homePhone: action.payload.homePhone,
        lastName: action.payload.lastName,
        logged: action.payload.logged,
        mobile: action.payload.mobile,
        userManualFileDownload: action.payload.userManualFileDownload,
      };
    case USER.GET_MAP_PREFERENCES:
      return {
        ...state,
        mapPreferences: {
          baseMap: action.payload.baseMap ? action.payload.baseMap : NaN,
          forcedLayers: action.payload.forcedLayers ? action.payload.forcedLayers : [],
          visibleLayers: action.payload.visibleLayers ? action.payload.visibleLayers : [],
        },
      };
    case USER.SET_HOME_MAP_POSITION:
      return {
        ...state,
        homeMapPosition: action.payload,
      };
    case USER.SET_REPORT_MAP_POSITION:
      return {
        ...state,
        reportMapPosition: action.payload,
      };
    case USER.SET_REPORT_LIST_TYPE:
      return {
        ...state,
        listType: action.payload,
      };
    case USER.LOG_OUT:
    case USER.SET_MAP_PREFERENCES:
    default:
      return {...state};
  }
};

export default userReducer;
