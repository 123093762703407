import React, { Component, ReactElement, RefObject } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { ISearch } from "../../../store/redux/types";

interface IAssetItem {
  anchor: string;
  address: string;
  id: string;
  index: number;
  indexName: string;
  handleClick: (feature: any, tab: string) => void;
  handleZoom: (feature: any, index: number, anchor: string) => void;
  feature: any;
  selected: boolean;
  search?: ISearch;
}
var SetTagLabel: boolean = false;

const getDetailTitle = (object: any) => {
  let title = "";
  SetTagLabel = false;
  if (object["sig:couche"] == "CONDEB" || object["sig:couche"] == "DO_PDE") {
    title += " " + object["asset:etiquette_nom"] ?? "";
  } else if (object["index:name"].match(/station|vanne|bassin|tete/g) && object.ppv3dData && object.ppv3dData.tagLabel) {
    title += " " + object.ppv3dData.tagLabel;
  } else if (object["index:name"] === "asset:pluviometre") {
    title += " " + object["asset:idpluv"];
  } else if (object["index:name"] === "asset:point_de_mesure") {
    title += " " + object["asset:idpm"] + " " + object["asset:idps"];
  } else if (object["index:name"] === "asset:poste_satellite") {
    title += " " + object["asset:idps"];
  } else {
    title += object["asset:nomexplo"] ? " " + object["asset:nomexplo"] : " " + object["asset:id_sig"];
    SetTagLabel = (object.ppv3dData && object.ppv3dData.tagLabel) ? true : false;
  }
  return title;
};



class AssetItem extends Component<IAssetItem, any> {

  public anchor: string;
  public anchorRef?: RefObject<HTMLLIElement>;
  public address: string;
  public id: string;
  public index: number;
  public indexName: string;
  public feature: any;
  public parentHandleClick: (feature: any, tab: string) => void;
  public parentHandleZoom: (feature: any, index: number, anchor: string) => void;
  public search?: ISearch;
  public selected: boolean;
  public title: ReactElement;
  public show3d: boolean;
  public showitv: boolean;



  constructor(props: IAssetItem) {
    super(props);

    this.anchor = props.anchor;
    if (props.search && this.anchor === props.search.detail.shownDetailAnchor) {
      this.anchorRef = React.createRef<HTMLLIElement>();
    }
    this.address = props.address;
    this.id = props.id;
    this.index = props.index;
    this.indexName = props.indexName;
    this.feature = props.feature;
    this.parentHandleClick = props.handleClick;
    this.parentHandleZoom = props.handleZoom;
    this.search = props.search;
    this.selected = props.selected;
    this.title = <>
      <Trans>{this.feature["index:name"]}</Trans>
      {getDetailTitle(this.feature)}
    </>;
    this.show3d = this.feature["3dnetwork:3d_mesh_available"] || this.feature["3dstrucuture:3d_ifc_available"];
    this.showitv = this.feature["itv:itv_available"];

  }

  public handleClick(tab: string) {
    this.parentHandleClick(this.feature, tab);
  }

  public handleZoom() {
    this.parentHandleZoom(this.feature, this.index, this.anchor);
  }

  public shouldComponentUpdate(nextProps: any, nextState: any) {
    if (this.search && nextProps.search && (this.search !== nextProps.search)) {
      this.anchor = nextProps.anchor;
      this.address = nextProps.address;
      this.id = nextProps.id;
      this.index = nextProps.index;
      this.indexName = nextProps.indexName;
      this.feature = nextProps.feature;
      this.search = nextProps.search;
      this.selected = nextProps.selected;
      this.title = <>
        <Trans>{this.feature["index:name"]}</Trans>
        {getDetailTitle(this.feature)}
      </>;
      return true;
    }
    return false;
  }

  public componentDidMount() {
    setTimeout(() => {
      if (this.anchorRef && this.anchorRef.current) {
        this.anchorRef.current.scrollIntoView({
          behavior: "auto",
          block: "start",
        });
      }
    }, 500);
  }

  public render() {
    return (
      <li
        id={this.anchor}
        ref={this.search && this.anchor === this.search.detail.shownDetailAnchor ? this.anchorRef : undefined}
        className={`gaia-search-data__item ${this.selected ? "active" : ""}`}
        key={this.index}
      >
        <div className={"gaia-search-data__item-content"}>
          <span className="gaia-search-data__item-content-title">{this.title}</span>
          {SetTagLabel && <em className="gaia-search-data__item-content-more">{this.feature.ppv3dData.tagLabel}</em>}
          <em className="gaia-search-data__item-content-more">{this.address}</em>
        </div>
        <div className="gaia-search-data__item-links">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id="tooltip">
                <Trans>open_details</Trans>
              </Tooltip>
            }
          >
            <i
              className="gaia-search-data__item-links-icon material-icons clickable"
              onClick={() => this.handleClick("synthesis")}
            >format_list_bulleted</i>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id="tooltip">
                <Trans>see_asset_on_map</Trans>
              </Tooltip>
            }
          >
            <i
              className="gaia-search-data__item-links-icon clickable material-icons"
              onClick={() => this.handleZoom()}
            >gps_fixed</i>
          </OverlayTrigger>

          {this.props.feature["3dnetwork:3d_mesh_available"] || this.props.feature["3dstrucuture:3d_ifc_available"] ? (
              <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 200 }}
                  overlay={
                    <Tooltip id="tooltip">
                      <Trans>see_3D_model</Trans>
                    </Tooltip>
                  }
              >
                <i
                    className="gaia-search-data__item-links-icon material-icons"
                    onClick={() => this.handleClick("work3d")}
                >3d_rotation</i>
              </OverlayTrigger>
          ) : <i className="mdi mdi-transparent-icon" />}


          {this.props.feature["itv:itv_available"] ? (
              <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 200 }}
                  overlay={
                    <Tooltip id="tooltip">
                      <Trans>see_itv_video</Trans>
                    </Tooltip>
                  }
              >
                <i
                    className="gaia-search-data__item-links-icon material-icons"
                    onClick={() => this.handleClick("itvs")}
                >videocam</i>
              </OverlayTrigger>
          ) : <i className="mdi mdi-transparent-icon" />}

        </div>
      </li>
    );
  }
}

const mapStateToProps = (state: any, props: IAssetItem) => {
  return {
    search: state.search,
    ...props
  };
};

export default connect(mapStateToProps)(AssetItem);
