import { BREADCRUMB } from "../constants";
import { IBreadcrumb, IBreadcrumbAction, IBreadcrumbSearchKeyWorkdsAction } from "../types";
import Search from "./search";

export default {
  setBreadcrumb(breadcrumb: IBreadcrumb): IBreadcrumbAction {
    return {
      payload: { ...breadcrumb },
      type: BREADCRUMB.SET,
    };
  },
  setSearchKeyWordBreadcrumb(searchKeyWord: string): IBreadcrumbSearchKeyWorkdsAction {
    return {
      payload: searchKeyWord,
      type: BREADCRUMB.SET_SEARCH_KEYWORDS,
    };
  },
  backToSearch(categoryName: string): any {
    return (dispatch: any) => {
      dispatch({
        category: categoryName,
        type: BREADCRUMB.BACK_TO_SEARCH,
      });
      dispatch(Search.goBackToSearch());
    };
  },
};
