import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Container from "./components/Container";
import Profil from "./components/Profil/Profil";
import Historic from "./pages/Historic";
import Home from "./pages/Home";
import Report from "./pages/Report";
import { Authenticate  as AuthenticateService} from "./services/Authenticate";
import { store } from "./store";
import MatomoTracker from './MatomoTracker';
interface IAppProps {
  history: History;
}

const AppRouter = ({ history }: IAppProps) => {
  useEffect(() => {
      AuthenticateService.initialize();
  }, []);

  return (
    <>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MatomoTracker />
          <Container>
            <Switch>
              <Route exact={true} path="/" component={Home} />
              <Route exact={true} path="/profil" component={Profil} />
              {/*<Route exact={true} path="/dashboard" component={Dashboard} />*/}
              <Route exact={true} path="/report" component={Report} />
              <Route exact={true} path="/history" component={Historic} history={history} />
              {/*<Route exact={true} path="/favorite" component={Favorite} />*/}
              <Route render={() => <Redirect to={{pathname: "/"}} />} />
            </Switch>
          </Container>
        </ConnectedRouter>
      </Provider>
    </>
  );
};

export default AppRouter;
