import React, { ReactElement, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzModal } from "react-theme-components";
import SzButton from "react-theme-components/build/components/SzButton/SzButton";
import { bindActionCreators } from "redux";
import { Report } from "../../../services/Report";
import { ReportActions } from "../../../store/redux/actions";
import { IProjectFavFilter, IReportData, IUser } from "../../../store/redux/types";
import "./updateFavFilters.scss";

interface IUpdateFavFilters {
  email: string;
  projectFavFilters: IProjectFavFilter[];
  getProjectFav: (email: string) => void;
  handleCloseUpdateForm: () => void;
  visible: boolean;
}

const UpdateProjectFavFilters = (props: IUpdateFavFilters): ReactElement => {

  const { t } = useTranslation();
  const { projectFavFilters } = props;
  const currentDefault = projectFavFilters.find((filter: IProjectFavFilter) => {
    return filter.isDefault;
  });
  const [newDefault, setNewDefault] = useState(currentDefault && currentDefault.id ? currentDefault.id : 0);
  const [favToDelete, setFavToDelete] = useState([0]);

  const updateFavToDelete = (value: number) => {
    if (favToDelete.indexOf(value) !== -1) {
      setFavToDelete(favToDelete.filter((val: number) => val !== value));
    } else {
      setFavToDelete(favToDelete.concat([value]));
    }
  };

  const handleClose = () => {
    props.handleCloseUpdateForm();
  };

  const handleValidate = () => {
    updateFavToDelete(0);
    Report.updateProjectFavFilter(props.email, newDefault, favToDelete).then(
      () => {
        props.handleCloseUpdateForm();
        props.getProjectFav(props.email);
      });
  };

  return (<SzModal
    className="gaia-report-fav-filter_update-modal"
    title={t("report:modal.favFilterUpdateModalTitle")}
    show={props.visible}
    handleClose={handleClose}
  >
    {projectFavFilters && projectFavFilters.length > 0 &&
      projectFavFilters.map((filter: IProjectFavFilter) => {
        return <div className={"gaia-report-fav-filter_update-modal-filter"} key={filter.id}>
          <div className={"gaia-report-fav-filter_update-modal-filter-title"}>
            {filter.title}
          </div>
          <div className={"gaia-report-fav-filter_update-modal-filter-actions"}>
            <div key={`custom-inline`}>
              <Form.Check
                inline
                label={t("report:modal.deleteFav")}
                type={"checkbox"}
                id={`fav-${filter.id}-0`}
                value={filter.id}
                onChange={(event: any) => updateFavToDelete(event.target.value)}
              />
              <Form.Check
                defaultChecked={filter.id === newDefault}
                inline
                name="newDefault"
                label={t("report:modal.makeDefaultFav")}
                type={"radio"}
                id={`fav-${filter.id}-1`}
                value={filter.id}
                onChange={(event: any) => setNewDefault(event.target.value)}
              />
            </div>
          </div>
        </div>;
      })
    }
    <div className={"gaia-report-fav-filter_update-modal-footer"}>
      <Form.Check
        className={"no-default-fav"}
        defaultChecked={0 === newDefault}
        name="newDefault"
        label={t("report:modal.noDefaultFav")}
        type={"radio"}
        id={`fav-0-1`}
        value={0}
        onChange={(event: any) => setNewDefault(event.target.value)}
      />
      <SzButton onClick={handleValidate}>
        {t("report:modal.validateFavFilter")}
      </SzButton>
      <SzButton onClick={handleClose}>
        {t("report:modal.cancel")}
      </SzButton>
    </div>
  </SzModal>);
};

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    email: state.user.email,
    projectFavFilters: state.report.projectFavFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProjectFavFilters);
