// API Routes
export const REPORT_API_BASE_ROUTE = "/report";
export const FAVORITE_FILTERS_BASE_ROUTE = "/filters/favorite/";
export const FAVORITE_FILTERS_UPDATE_BASE_ROUTE = "/filters/favorite/update_filters/";
export const PROJECT_FAVORITE_FILTERS_BASE_ROUTE = "/project/filters/favorite/";
export const PROJECT_FAVORITE_FILTERS_UPDATE_BASE_ROUTE = "/project/filters/favorite/update_project_filters/";
export const SIGNAL_FAVORITE_FILTERS_BASE_ROUTE = "/signal/filters/favorite/";
export const SIGNAL_FAVORITE_FILTERS_UPDATE_BASE_ROUTE = "/signal/filters/favorite/update_signal_filters/";
export const SITE_INDUS_FAVORITE_FILTERS_BASE_ROUTE = "/siteIndustriel/filters/favorite/";
export const SITE_INDUS_FAVORITE_FILTERS_UPDATE_BASE_ROUTE = "/siteIndustriel/filters/favorite/update_site_indus_filters/";
export const GEOSERVER_BV_FAVORITE_FILTERS_BASE_ROUTE = "/geoserverBV/filters/favorite/";
export const GEOSERVER_BV_FAVORITE_FILTERS_UPDATE_BASE_ROUTE = "/geoserverBV/filters/favorite/update_geoserver_bv_filters/";
export const GET_ADDRESS_COORDINATES_BASE_ROUTE = "/search/address/coordinates";
export const GET_SEARCH_HISTORY_BASE_ROUTE = "/history/search/";
export const REPORT_ADDRESS_AUTOCOMPLETE_BASE_ROUTE = "/report/address/autocomplete";
export const PROJECT_ADDRESS_AUTOCOMPLETE_BASE_ROUTE = "/project/address/autocomplete";
export const SIGNAL_ADDRESS_AUTOCOMPLETE_BASE_ROUTE = "/signal/address/autocomplete";
export const SITE_INDUS_ADDRESS_AUTOCOMPLETE_BASE_ROUTE = "/siteIndustriel/address/autocomplete";
export const SIG_ADDRESS_AUTOCOMPLETE_BASE_ROUTE = "/sig/address/autocomplete";
export const USER_GET_SET_MAP_PREFERENCES_BASE_ROUTE = "/user/preferences/map";
export const GEOSERVER_GET_BVS_ROUTE = "/geoserverBV/";
export const PROJECT_API_BASE_ROUTE = "/project";
export const SIGNAL_API_BASE_ROUTE = "/signal";
export const SITE_INDUS_API_BASE_ROUTE = "/siteIndustriel";
export const PARCEL_API_BASE_ROUTE = "/parcel";
export const PLU_API_BASE_ROUTE = "/plu";
export const EQUIPEMENT_DETAIL_ROUTE = "/ppv3d";
export const SMARTSHAPE_ROUTE = "/smartshape";

export const API_ROUTES = {
  FAV_FILTERS: FAVORITE_FILTERS_BASE_ROUTE,
  FAV_FILTERS_UPDATE: FAVORITE_FILTERS_UPDATE_BASE_ROUTE,
  PROJECT_FAV_FILTERS: PROJECT_FAVORITE_FILTERS_BASE_ROUTE,
  PROJECT_FAV_FILTERS_UPDATE: PROJECT_FAVORITE_FILTERS_UPDATE_BASE_ROUTE,
  SIGNAL_FAV_FILTERS: SIGNAL_FAVORITE_FILTERS_BASE_ROUTE,
  SIGNAL_FAV_FILTERS_UPDATE: SIGNAL_FAVORITE_FILTERS_UPDATE_BASE_ROUTE,
  SITE_INDUS_FAV_FILTERS: SITE_INDUS_FAVORITE_FILTERS_BASE_ROUTE,
  SITE_INDUS_FAV_FILTERS_UPDATE: SITE_INDUS_FAVORITE_FILTERS_UPDATE_BASE_ROUTE,
  GEOSERVER_BV_FAV_FILTERS: GEOSERVER_BV_FAVORITE_FILTERS_BASE_ROUTE,
  GEOSERVER_BV_FAV_FILTERS_UPDATE: GEOSERVER_BV_FAVORITE_FILTERS_UPDATE_BASE_ROUTE,
  GET_ADDRESS_COORDINATES: GET_ADDRESS_COORDINATES_BASE_ROUTE,
  GET_SEARCH_HISTORY: GET_SEARCH_HISTORY_BASE_ROUTE,
  REPORT: REPORT_API_BASE_ROUTE,
  PROJECT: PROJECT_API_BASE_ROUTE,
  SIGNAL: SIGNAL_API_BASE_ROUTE,
  SITE_INDUS: SITE_INDUS_API_BASE_ROUTE,
  PARCEL: PARCEL_API_BASE_ROUTE,
  PLU: PLU_API_BASE_ROUTE,
  REPORT_ADDRESS_AUTOCOMPLETE: REPORT_ADDRESS_AUTOCOMPLETE_BASE_ROUTE,
  PROJECT_ADDRESS_AUTOCOMPLETE: PROJECT_ADDRESS_AUTOCOMPLETE_BASE_ROUTE,
  SIGNAL_ADDRESS_AUTOCOMPLETE: SIGNAL_ADDRESS_AUTOCOMPLETE_BASE_ROUTE,
  SITE_INDUS_ADDRESS_AUTOCOMPLETE: SITE_INDUS_ADDRESS_AUTOCOMPLETE_BASE_ROUTE,
  SIG_ADDRESS_AUTOCOMPLETE: SIG_ADDRESS_AUTOCOMPLETE_BASE_ROUTE,
  USER_GET_SET_MAP_PREFERENCES: USER_GET_SET_MAP_PREFERENCES_BASE_ROUTE,
  GEOSERVER_GET_BVS: GEOSERVER_GET_BVS_ROUTE,
  EQUIPEMENT_DETAIL: EQUIPEMENT_DETAIL_ROUTE,
  SMARTSHAPE: SMARTSHAPE_ROUTE,
};
