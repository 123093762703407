import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Detail from "../../components/Detail";
import Map from "../../components/Map";
import Search from "../../components/Search";
import Smartshape from "../../components/Smartshape";
import { ReportActions, SearchActions, UserActions } from "../../store/redux/actions";
import { IReport, IReportFilter, IUser } from "../../store/redux/types";
import Loading from "../Loading";
import "./home.scss";

interface IHomeProps {
  user: IUser;
  geoserverBVs: any;
  geoserverBVsColors: string[];
  showResultDetail: boolean;
  showSearchResult: boolean;
  showSmartshape: boolean;
  shownDetailItem: any;
  shown3dObjectOnSmartshapeIframe: {};
  isDisplayed: boolean;
  isMapDisplayedFullscreen: boolean;
  getGeoserverBV: (colors: string[]) => any;
  getSigObject: (itemID: string) => any;
  homeMapPosition: [number, number, number];
  setHomeMapPosition: (coordinates: [number, number, number]) => void;
  reports: IReport;
  reportFilters: IReportFilter;
  reportSortDirection: string;
  reportSortField: string;
  displaySmartshape?:any
}

const filters = {
  address: "",
  city: [],
  controlType: [],
  conformity: [],
  veConformity: [],
  vptConformity: [],
  conformityCheckType: [],
  connectionNumber: "",
  currentState: [],
  emOperatingSector: [],
  endDate: null,
  generatedMailEndDate: null,
  generatedMailStartDate: null,
  majorFaultType: [],
  networkOperatingSector: [],
  propertyType: [],
  startDate: null,
};

class Home extends React.Component<IHomeProps> {

  public componentWillMount() {
    this.props.getGeoserverBV(this.props.geoserverBVsColors);
  }

  public render() {
    if (!this.props.user.logged) {
      return (
        <div className="map">
          <Loading />
        </div>
      );
    } else {
      return (
        <>
          {this.props.showSearchResult && (
            <div className="no-padding gaia-search-result-container">
              <Search />
            </div>
          )}
          {this.props.showResultDetail && (
            <div className="no-padding gaia-search-detail-container">
              <Detail />
            </div>
          )}
          <div className="display-container">
            <div className={`no-padding map ${this.props.isDisplayed && this.props.showSmartshape ? "hidden" : ""}`}>
              <Map
                defaultCoordinates={this.props.homeMapPosition}
                showDetailObject={this.props.getSigObject}
                setMapPosition={this.props.setHomeMapPosition}
                filters={filters}
                reports={this.props.reports}
                sortDirection={this.props.reportSortDirection}
                sortField={this.props.reportSortField}
                geoserverBVs={this.props.geoserverBVs}
                displayBVLayers={false}
                displayProjectLayers={false}
                displaySignalLayers={false}
                displaySiteIndustrielLayers={false}
                displayParcelLayers={false}
                displayPluLayers={false}
                showSmartshape={this.props.showSmartshape}
                displaySmartshape={this.props.displaySmartshape}
              />
            </div>
           {this.props.showSmartshape && (
              <div className={`no-padding smartshape ${this.props.showSmartshape ? "" : "hidden"} ${!this.props.isMapDisplayedFullscreen ? "" : "hidden"}`} >
                <Smartshape shownDetailItem={this.props.shownDetailItem}
                            shown3dObjectOnSmartshapeIframe={this.props.shown3dObjectOnSmartshapeIframe}
                            displaySmartshape={this.props.displaySmartshape}
                            />
              </div>
            )}
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    geoserverBVs: state.report.geoserverBVs,
    geoserverBVsColors: state.report.geoserverBVsColors,
    homeMapPosition: state.user.homeMapPosition,
    isDisplayed: state.search.isDisplayed,
    isMapDisplayedFullscreen: state.search.isMapDisplayedFullscreen,
    reportFilters: state.report.filters,
    reportSortDirection: state.report.sortDirection,
    reportSortField: state.report.sortField,
    reports: state.report.reports,
    showResultDetail: state.search.flag.showResultDetail,
    shownDetailItem: state.search.detail.shownDetailItem,
    shown3dObjectOnSmartshapeIframe: state.search.detail.shown3dObjectOnSmartshapeIframe,
    showSearchResult: state.search.flag.showSearchResult,
    showSmartshape: state.search.flag.showSmartshape,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...SearchActions, ...UserActions, ...ReportActions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
