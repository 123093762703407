import AwesomeDebouncePromise, { AwesomeDebounceOptions } from "awesome-debounce-promise";
import React, { useState } from "react";
import { useAsync } from "react-async-hook";
import useConstant from "use-constant";

const useDebouncedSearch = (searchFunction: any, debounceDelay: number) => {

  // Handle the input text state
  const [inputText, setInputText] = useState("");

  // Debounce the original search async function
  const debouncedSearchFunction = useConstant(() =>
    AwesomeDebouncePromise(searchFunction, debounceDelay),
  );

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  useAsync(
    async () => {
      if (inputText.length > 0) {
        return debouncedSearchFunction(inputText);
      }
    },
    [debouncedSearchFunction, inputText],
  );

  // Return everything needed for the hook consumer
  return {
    setInputText,
  };
};

export default useDebouncedSearch;
