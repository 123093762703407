import { categories, filterTypes } from "../config/constants";
import { IFilter, ISearchData } from "../store/redux/types";

export const isIndicesAndFacetsEmpty = (resultData?: ISearchData) => {
  return (
    resultData &&
    resultData.data.selectedIndices &&
    resultData.data.selectedIndices.length === 0 &&
    resultData.data.selectedFacets &&
    Object.entries(resultData.data.selectedFacets).length === 0
  );
};

export const initSelectedIndiceFromSearch = (prefix: string, resultData?: ISearchData): IFilter[] => {
  const filters: IFilter[] = [];
  if (resultData && resultData.data.selectedIndices && resultData.data.selectedIndices.length > 0) {
    resultData.data.selectedIndices.forEach((indice) => {
      filters.push({ isChecked: true, key: `${prefix}${indice}`, type: filterTypes.indice, value: "1"});
    });
  }
  return filters;
};

export const initSelectedFacetFromSearch = (prefix: string, resultData?: ISearchData): IFilter[] => {
  const filters: IFilter[] = [];
  const obj = resultData ? resultData.data.selectedFacets : ({} as any);
  if (obj && Object.entries(obj).length > 0) {
    for (const [key, value] of Object.entries(obj)) {
      filters.push({ isChecked: true, key: `${prefix}${key}`, type: filterTypes.facet, value: `${value}` });
    }
  }
  return filters;
};

export const getAssetsData = (searchData: ISearchData[]) =>
  searchData.find((data: ISearchData) => data.category === categories.ASSET);
export const getDocsData = (searchData: ISearchData[]) =>
  searchData.find((data: ISearchData) => data.category === categories.DOC);
