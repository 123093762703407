import { filterTypes } from "../../../config/constants";
import { FILTERS } from "../constants";
import { FiltersActionTypes, IFilter } from "../types";

const initialState: IFilter[] = [];

const filtersReducers = (
  state = initialState,
  action: FiltersActionTypes,
): IFilter[] => {
  switch (action.type) {
    case FILTERS.SET:
      return action.payload;
    case FILTERS.UPDATE:
      let newState = state;
      if (!action.payload.isChecked) {
        return state.filter((item: IFilter) => {
          return item.key.localeCompare(action.payload.key) !== 0;
        });
      }
      if (action.payload.type === filterTypes.facet) {
        newState = state.filter((item: IFilter) => {
          return item.key.localeCompare(action.payload.key) !== 0;
        });
      }
      return [
        ...newState,
        {
          isChecked: action.payload.isChecked,
          key: action.payload.key,
          type: action.payload.type,
          value: action.payload.value,
        },
      ];
    case FILTERS.RESET:
      return initialState;
    default:
      return state;
  }
};

export default filtersReducers;
