import { API_ROUTES } from "../config/routes";
import Request from "./Request";
import {Token as TokenService} from "./Token";

export class Smartshape {
    public static getNodesByEquipement(fileId: string, equipementId: string,jsonwebtoken:string,XSmartShapeSession:string) {

        const url = `${API_ROUTES.SMARTSHAPE}/${fileId}/nodes/${equipementId}/${jsonwebtoken}/${XSmartShapeSession}`;
        return Request.get(url, true).then((res: any) => res.data);
    }

    public static getNodesById(fileId: string, nodeId: string,jsonwebtoken:string,XSmartShapeSession:string) {

        const url = `${API_ROUTES.SMARTSHAPE}/${fileId}/${nodeId}/${jsonwebtoken}/${XSmartShapeSession}`;
        return Request.get(url, true).then((res: any) => res.data);
    }

/*    public static  updateSmartshapeSession(jsonwebtoken: string) {

        const url = `${API_ROUTES.SMARTSHAPE}/updateSession/${jsonwebtoken}`;
        return Request.get(url, true,false).then((res: any) => res.data);
    }*/

    public static  deleteSmartshapeSession(jsonwebtoken: string,XSmartShapeSession:string) {

        const url = `${API_ROUTES.SMARTSHAPE}/deleteSession/${jsonwebtoken}/${XSmartShapeSession}`;
        return Request.get(url, true,false).then((res: any) => res.data);
    }

    public static logoutSmartshape() {

        const url = `${API_ROUTES.SMARTSHAPE}/logout`;
        return Request.get(url, true,false).then((res: any) => res.data);
    }


/*    public static  async setXSmartShapeSession(jsonwebtoken: string) {

        await this.updateSmartshapeSession(jsonwebtoken)
            .then((response: any) => {
                console.log("update-Smartshape-Session-Response", response)

                if (response.success) {
                    TokenService.setCookie("XSmartShapeSession", response.jwt, 1)
                    localStorage.setItem("XSmartShapeSession", response.jwt)
                }
            })
    }*/



}
