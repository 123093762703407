import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { SzIcon } from "react-theme-components";
import { config } from "../../config";
import { addItvGraphics, clearItvGraphics, loadedMapView } from "../../manager/map/loader";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";

interface IObject {
    "asset:nomexplo": string;
    "sig:line": string[][];
    "asset:ident": string;
    "itv:itv_available": string;
    "asset:am_idapic": string;
    "asset:av_idapic": string;
    "itv:itv_list": any[];
}

const Itv = (props: { object: IObject }) => {
    // enable or disable itv icon
    const ITV_GRAPHICS_ENABLED = false;

    const isObjectLine = useMemo((): boolean => {
        if (props.object && props.object["sig:line"] && props.object["sig:line"].length === 2) {
            return true;
        }
        return false;
    }, [props.object]);
    // compute the  Marker coordinate
    const computeCoordinate = (point1: number[], point2: number[], percentage: number) => {
        const newLat = point1[0] + ((point2[0] - point1[0]) * (percentage / 100));
        const newLong = point1[1] + ((point2[1] - point1[1]) * (percentage / 100));
        return [newLat, newLong];
    };
    const handleMessage = (event: any) => {
        if (event.origin !== config.webComponentUrl) {
            return;
        }
        switch (event.data.eventId) {
            case "ITV_TIME_CHANGE":
                const point1 = props.object["sig:line"][0].map(Number);
                const point2 = props.object["sig:line"][1].map(Number);
                const newPoint = computeCoordinate(point1, point2, event.data.percentage);
                addItvGraphics(newPoint[0], newPoint[1]);
                break;
        }
    };

    const [activeKey, setActiveKey] = useState("itv-0");
    const [activeVidKey, setActiveVidKey] = useState("vid-0-0");

    useEffect(() => {
        if (loadedMapView) {
            clearItvGraphics();
        }
        if (props.object && isObjectLine && ITV_GRAPHICS_ENABLED) {
            window.addEventListener("message", handleMessage, false);
        }
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [props.object]);

    if (
        props.object &&
        props.object["itv:itv_available"] &&
        props.object["itv:itv_list"].length > 0
    ) {
        const mediaServerHost = config.mediaServerHost;
        const wfsBaseUrl = config.wfsBaseUrl;
        const sigToken = localStorage.getItem("sigToken");
        return (
            <>
                <Accordion defaultActiveKey={activeKey}>
                    {props.object["itv:itv_list"].map((element: any, key: any) => {
                        return (
                            <>
                                <Card className="container-bordered">
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey={"itv-" + key}
                                        onClick={() =>
                                            activeKey === "itv-" + key ? setActiveKey("") : setActiveKey("itv-" + key)
                                        }
                                    >
                                        ITV - {element.date}
                                        {activeKey === "itv-" + key ?
                                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                                            <SzIcon variant="bold" icon="arrow-down-1" />
                                        }
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"itv-" + key}>

                                        <Card.Body>
                                            <Accordion defaultActiveKey={activeVidKey}>
                                                {element.videos.map((video: any, vidKey: any) => {
                                                    const videoUuid = video.uuid;
                                                    const videoUrl = `${config.webComponentUrl}?mediaServerHost=${mediaServerHost}&wfsBaseUrl=${wfsBaseUrl}&videoUuid=${videoUuid}&token=${sigToken}`;
                                                    return (
                                                        <>
                                                            <Card className="container-bordered">
                                                                <Accordion.Toggle
                                                                    as={Card.Header}
                                                                    eventKey={"vid-" + key + "-" + vidKey}
                                                                    onClick={() =>
                                                                        activeVidKey === "vid-" + key + "-" + vidKey ?
                                                                            setActiveVidKey("") :
                                                                            setActiveVidKey("vid-" + key + "-" + vidKey)
                                                                    }
                                                                >
                                                                    Video - {video.num}
                                                                    {
                                                                    activeVidKey === "vid-" + key + "-" + vidKey ?
                                                                        <SzIcon variant="bold" icon="arrow-up-1" /> :
                                                                        <SzIcon variant="bold" icon="arrow-down-1" />
                                                                    }
                                                                </Accordion.Toggle>
                                                                <Accordion.Collapse
                                                                    eventKey={"vid-" + key + "-" + vidKey}
                                                                >
                                                                    <Card.Body>
                                                                        <iframe
                                                                            key={videoUuid}
                                                                            allowFullScreen
                                                                            scrolling="no"
                                                                            height="450px"
                                                                            width="100%"
                                                                            frameBorder="none;"
                                                                            src={videoUrl}
                                                                        />
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                        </>
                                                    );
                                                })
                                                }
                                            </Accordion>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </>
                        );
                    })
                    }
                </Accordion>
            </>
        );
    } else {
        return (
            <>
                <DataNotReady />
            </>
        );
    }
};

export default Itv;
