import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ASSET_MAPPING } from "../../config/asset-map";
import { IAssetDetailsTabProperty } from "../../config/asset.interfaces";
import { ISearch } from "../../store/redux/types";
import Curage from "./curage";
import Documents from "./documents";
import History from "./history";
import Itv from "./itv";
import RelatedAssets from "./related-assets";
import SiteIndustriel from "./siteIndustriel";
import Specifics from "./specifics";
import Synthesis from "./synthesis";
import Work3d from "./work3d";

interface IDetailContainer {
  shownItem: any;
  shownEquipementId?: string;
  detail?: any;
}

const DetailContainer = (props: IDetailContainer) => {
  const { shownItem, shownEquipementId, detail} = props;
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState(detail.defaultTab ? detail.defaultTab : "synthesis");
  const itemId = shownItem["asset:idApid"] || 0;
  const ifcFileId = shownItem["3dstrucuture:3d_ifc_list"] ? shownItem["3dstrucuture:3d_ifc_list"][0]["3dstrucuture:3d_ifc_file_id"] : null;
  const meshList = shownItem["3dnetwork:3d_mesh_list"] ? shownItem["3dnetwork:3d_mesh_list"] : null;
  var itemMap = ASSET_MAPPING[shownItem["index:name"]];
  
  if(shownItem['asset:visitabl'] && shownItem['asset:visitabl']=="O" && shownItem["index:name"].toUpperCase().includes("TRONCON")) {
    itemMap = itemMap.filter(obj => obj.label !== "itvs");
  }
  if(shownItem.sites && shownItem.sites[0]==undefined) {
    itemMap = itemMap.filter(obj => obj.label !== "siteIndustriel");
  }

  useEffect(() => {
    if (shownEquipementId && shownItem.ppv3dData) {
      setTabKey("work3d");
    } else if (detail.defaultTab) {
      setTabKey(detail.defaultTab);
    }
  }, [detail.defaultTab, shownEquipementId]);


  const unique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  const getConformityStatus = (a: any) => {
    switch (a["control:control_type"]) {
      case "CONFORMITE":
        return t(`asset:conformity.${a["control:controlStatus_id"]}`);
        break;

      default:
        if (a["control:result"] && a["control:result"] === "1") {
          return t(`asset:conformity.conforme`);
        } else {
          return t(`asset:conformity.non-conforme`);
        }
        break;
    }
  };
  const getSiteIndusNumber = (a: any) => {
    const siteIndusNumber: any = [];
    a.forEach((site: any) => {
      siteIndusNumber.push(site.ID_CD92);
    });
    return siteIndusNumber.filter(unique).join("|");
  };
  const getSiteIndusConformity = (a: any) => {
    const siteIndusConformity: any = [];
    a.forEach((site: any) => {
      siteIndusConformity.push(site.NOMETAB + ":" + t(`asset:siteIndustriel.conformity.${site.conformity}`));
    });
    return siteIndusConformity.filter(unique).join("|");
  };

  const getDisplayedValue = (item: any, property: IAssetDetailsTabProperty): string => {
    let val = "";
    switch (property.type) {
      case "calculated":
        val =
          typeof property.callback !== "undefined" &&
            typeof property.value === "string" &&
            typeof property.attribute === "undefined" ? i18next.t(property.callback(item[property.value]))
            : (
              typeof property.callback !== "undefined" &&
                typeof property.value === "string" &&
                typeof property.attribute !== "undefined" &&
                typeof item[property.value][0] !== "undefined" ?
                i18next.t(
                  property.callback(item[property.value][0][property.attribute] !== "" ?
                    item[property.value][0][property.attribute] :
                    "",
                  ),
                )
                : ""
            );
        break;
      case "conformity":
        val = item.controls[0] ? getConformityStatus(item.controls[0]) : "";
        break;
      case "siteIndusNumber":
        val = item.sites[0] ? getSiteIndusNumber(item.sites) : "";
        break;
      case "siteIndusConformity":
        val = item.sites[0] ? getSiteIndusConformity(item.sites) : "";
        break;
      case "string":
        val =
          typeof property.value === "string"
              ? ( typeof property.callback !== "undefined" ? i18next.t(property.callback(item[property.value])) : i18next.t(item[property.value]))
              : property.value.map((str: string) => i18next.t(`asset:${item[str]}`)).join(" ");
        break;
      default:
        break;
    }
    if (val === "") {
      if(property.label=="length")  return i18next.t("inconnu");
      return i18next.t("no-data");
    }
    return (
      (typeof property.prefix !== "undefined" ? property.prefix + " " : "") +
      val +
      (typeof property.suffix !== "undefined" ? " " + property.suffix : "")
    );
  };

  const handleSelect = (eventKey: string, test: any) => {
    ReactGA.event({
      action: `Voir onglet: ${eventKey} pour l'objet ${itemId} `,
      category: "Search",
    });
  };

  const getDetailTitle = () => {
    let title = "";
    title = t(shownItem["index:name"]);
    if (shownItem["sig:couche"]=="CONDEB" || shownItem["sig:couche"]=="DO_PDE") {
      title += " " + shownItem["asset:etiquette_nom"]??"" ;
    }
    else if (shownItem["index:name"] === "asset:pluviometre") {
      title += " " + shownItem["asset:idpluv"];
    } else if (shownItem["index:name"] === "asset:point_de_mesure") {
      title += " " + shownItem["asset:idpm"] + " " + shownItem["asset:idps"];
    } else if (shownItem["index:name"] === "asset:poste_satellite") {
      title += " " + shownItem["asset:idps"];
    } else {
      title += shownItem["asset:nomexplo"] ? " " + shownItem["asset:nomexplo"] : " " + shownItem["asset:id_sig"];
    }
    return title;
  };

  return (<>
    <div className={"gaia-detail-result--header"}>
      <div className={"item-header"}>
        {!shownItem.ppv3dData && shownItem["index:name"].match(/station/g) && (
          <h1 className="gaia-detail-result--header--primary-title primary-title-category">
            {shownItem["asset:nom"]}
          </h1>
        )}
        {shownItem.ppv3dData && shownItem["index:name"].match(/station|vanne|bassin/g) && (
          <h1 className="gaia-detail-result--header--primary-title primary-title-category">
            {shownItem.ppv3dData.tagLabel}
          </h1>
        )}
        <h1 className="gaia-detail-result--header--primary-title primary-title">
          {getDetailTitle()}
        </h1>
        <h3 className="gaia-detail-result--header--secondary-title secondary-title">
          {shownItem["sig:adressecomplete"]}
        </h3>
      </div>
      <div className={"item-type"}>
        {(shownItem["3dnetwork:3d_mesh_available"] || shownItem["3dstrucuture:3d_ifc_available"]) &&
          <i
            className="gaia-search-data__item-links-icon material-icons"
          >3d_rotation</i>
        }
        {shownItem["itv:itv_available"] &&
          <i
            className="gaia-search-data__item-links-icon material-icons"
          >videocam</i>
        }
      </div>
    </div>
    <Tab.Container
      id="gaia-category"
      activeKey={tabKey}
      onSelect={(k) => {
        setTabKey(k);
      }}
    >
      <Nav className="sz-aside__list gaia-detail-result--nav" onSelect={handleSelect}>
        {itemMap &&
          itemMap.map(({ label }: { label: string }, key: number) => (
            <Nav.Item key={key} className="gaia-search-category text-decoration-none py-2">
              <Nav.Link eventKey={label}>
                <span className="gaia-search-category__item-title">{t(`asset:tabs.${label}`)}</span>
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
      <Tab.Content>
        {itemMap &&
          itemMap.map(({ label }: { label: string }, key: number) => (
            <Tab.Pane key={key} eventKey={label}>
              {(() => {
                switch (label) {
                  case "synthesis":
                    return (
                      <Synthesis
                        item={shownItem}
                        getDisplayedValue={getDisplayedValue}
                        tabLabel={label}
                      />
                    );
                  case "specifics":
                    return (
                      <Specifics
                        item={shownItem}
                        getDisplayedValue={getDisplayedValue}
                        tabLabel={label}
                      />
                    );
                  case "history":
                    return (
                      <History
                        objectType={shownItem["index:name"]}
                        controls={shownItem.controls}
                        interventions={shownItem.interventions}
                        siteIndustriels={shownItem.sites}
                        curages={shownItem.curages}
                        notes={shownItem.notes}
                      />
                    );
                  case "documents":
                    return (
                      <Documents
                        objectType={shownItem["index:name"]}
                        documents={shownItem.documents}
                        siteDocuments={shownItem.siteDocuments}
                        vicrDocuments={shownItem.vicrDocuments}
                        itvDocuments={shownItem.itvDocuments}
                        industriel={shownItem["industriel:id_cd92"]??[]}
                      />
                    );
                  case "curage":
                    return <Curage interventions={shownItem.interventions} />;
                  case "relatedAsset":
                    return <RelatedAssets />;
                  case "itvs":
                    return <Itv object={shownItem} />;
                  case "work3d":
                    return (
                      <Work3d
                        ppv3dData={shownItem.ppv3dData}
                        ifcFileId={ifcFileId}
                        meshList={meshList}
                        feature={shownItem}
                      />
                    );
                  case "siteIndustriel":
                    return (
                      <SiteIndustriel
                        siteIndustriels={shownItem.sites}
                      />
                    );
                  default:
                    return "";
                }
              })()}
            </Tab.Pane>
          ))}
        {!itemMap && i18next.t("no-data")}
      </Tab.Content>
    </Tab.Container>
  </>
  );
};

const mapStateToProps = (state: { search: ISearch }) => {
  return {
    shownEquipementId: state.search.detail.shownEquipementId,
    detail: state.search.detail,
  };
};

export default connect(mapStateToProps)(DetailContainer);
