import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzDatePicker, SzInput, SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import {
  calculateCityCentroid,
  citiesBoundingBoxes,
  projectCities,
  projectStatuses,
} from "../../../config/report";
import { defaultProperties } from "../../../manager/map/config";
import { goToHome, zoomToCoordinates } from "../../../manager/map/navigation";
import { reportTabs } from "../../../pages/Report";
import { Report as ReportService } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IProjectFilter,
  IReportData,
} from "../../../store/redux/types";
import AutoCompleteInput from "../../Utils/AutoCompleteInput/AutoCompleteInput";
import ListHeader from "./ListHeader";

const ProjectListFilters = (props: any): ReactElement => {

  const { t } = useTranslation();

  const autoCompleteMinLength = 3;
  const [autocompleteOptions, setAutocompleteOptions] = useState([""]);

  const projectStatusesOptions = Object.entries(projectStatuses).map((item: any) => {
    return { label: item[1], value: item[0] };
  });

  const cityOptions = Object.entries(projectCities).map((item: any) => {
    return { label: item[1], value: item[1] };
  });

  const [localAddress, setAddress] = useState<string>(props.projectFilters.address);
  const [localName, setName] = useState<string>(props.projectFilters.name);
  const [localStatus, setStatus] = useState<any[]>(props.projectFilters.status);
  const [localCity, setCity] = useState<any[]>(props.projectFilters.city);
  const [permitStartDate, setPermitStartDate] = useState<Date | null>(props.projectFilters.permitStartDate);
  const [permitEndDate, setPermitEndDate] = useState<Date | null>(props.projectFilters.permitEndDate);
  const [localZac, setZac] = useState<string>(props.projectFilters.zac);

  const updatePermitDate = (newDate: Date, startOrEnd: string) => {
    if (permitStartDate && permitEndDate) {
      if ("start" === startOrEnd && newDate.getTime() <= permitEndDate.getTime()) {
        setPermitStartDate(newDate);
      } else if (newDate.getTime() >= permitStartDate.getTime()) {
        setPermitEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setPermitStartDate(newDate);
      } else {
        setPermitEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (props.projectFilters.address) {
      setAddress(props.projectFilters.address);
    }
    if (props.projectFilters.zac) {
      setZac(props.projectFilters.zac);
    }
    if (props.projectFilters.name) {
      setAddress(props.projectFilters.name);
    }
    if (props.projectFilters.permitStartDate) {
      if (typeof props.projectFilters.permitStartDate !== "object") {
        const startingDate = new Date(props.projectFilters.permitStartDate);
        setPermitStartDate(startingDate);
      }
    }
    if (props.projectFilters.permitEndDate) {
      if (typeof props.projectFilters.permitEndDate !== "object") {
        const endingDate = new Date(props.projectFilters.permitEndDate);
        setPermitEndDate(endingDate);
      }
    }
    if (props.projectFilters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.projectFilters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.projectFilters.status) {
      const statusValues = projectStatusesOptions.filter((item: any) => {
        return props.projectFilters.status.includes(item.value);
      });
      setStatus(statusValues);
    }
  }, [props.currentProjectFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  const isFiltersEmpty = () => {
    return ((!localCity || localCity.length === 0) &&
      (!localStatus || localStatus.length === 0) &&
      (!localName || localName === "") &&
      (!localAddress || localAddress === "") &&
      (!localZac || localZac === "") &&
      (!permitStartDate) && (!permitEndDate));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());

  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
      localAddress,
      localName,
      localStatus,
      localCity,
      permitStartDate,
      permitEndDate,
      localZac,
  ]);

  useEffect(() => {
    if (areFiltersEmpty) {
      resetAction();
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    const filters: IProjectFilter = {
      address: localAddress,
      name: localName,
      status: localStatus.map((item) => item.value),
      city: localCity.map((item) => item.value),
      permitStartDate,
      permitEndDate,
      zac: localZac,
    };
    if (filters.city && filters.city.length === 1) {
      const cityValue = Object.entries(projectCities).filter((item: any) => {
        if (filters.city && filters.city.length === 1 && item[1] === filters.city[0]) {
          return item[0];
        }
      });
      const coordinates = calculateCityCentroid(citiesBoundingBoxes[cityValue[0][0]].extent);
      props.setReportMapPosition([coordinates.latitude, coordinates.longitude, 10000]);
      if (props.currentTab === reportTabs.map) {
        zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
      }
    } else if (filters.city && filters.city.length > 1) {
      props.setReportMapPosition(mapOrigin);
      if (props.currentTab === reportTabs.map) {
        zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
      }
    } else if (filters.address && (
      (!filters.city || filters.city.length === 0) &&
      (!filters.status || filters.status.length === 0) &&
      (!filters.name || filters.name === "") &&
      (!filters.zac || filters.zac === "")
    )
    ) {
      SearchService.getAddressCoordinates(filters.address).then((res: any) => {
        if (res.coordinates && res.cityCode.match(/92/g)) {
          props.setReportMapPosition([res.coordinates[1], res.coordinates[0], defaultProperties.scale]);
          if (props.currentTab === reportTabs.map) {
            zoomToCoordinates(res.coordinates[1], res.coordinates[0]);
          }
        } else {
          props.setReportMapPosition(mapOrigin);
          if (props.currentTab === reportTabs.map) {
            zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
          }
        }

      });
    }
    props.updateProjectFilters(filters);
  };

  const resetAction = () => {
    setAddress("");
    setName("");
    setStatus([]);
    setCity([]);
    setPermitStartDate(null);
    setPermitEndDate(null);
    setZac("");
    const filters: IProjectFilter = {
      address: "",
      name: "",
      status: [],
      city: [],
      permitStartDate: null,
      permitEndDate: null,
      zac: "",
    };
    props.updateProjectFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset project filters`, category: "Report" });
  };

  const autocompleteAddress = (address: string) => {
    return ReportService.getProjectAddressAutocomplete(address)
      .then((data: Array<{ address: string }>) => {
        const options = data.map((item: { address: string }) => {
          return item.address;
        });
        setAutocompleteOptions(options);
      });
  };

  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:project.name")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary name"
            placeholder={""}
            value={localName}
            onChange={(event: { target: { value: string } }) => setName(event.target.value)}
            handleClear={() => setName("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:project.status")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary status"
            isSearchable
            isClearable
            isMulti
            id="status"
            name="status"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setStatus);
            }}
            options={projectStatusesOptions}
            value={localStatus}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:project.address")}</span>
          </div>
          <AutoCompleteInput
            autoCompleteOptions={autocompleteOptions}
            className={"address"}
            debounce={500}
            handler={setAddress}
            placeholder={""}
            minLength={autoCompleteMinLength}
            searchHandler={autocompleteAddress}
            value={localAddress}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:project.pcDate")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            label={"date"}
            endDate={permitEndDate}
            endMinDate={null}
            startDate={permitStartDate}
            startMinDate={null}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updatePermitDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updatePermitDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:project.zac")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary zac"
            placeholder={""}
            value={localZac}
            onChange={(event: { target: { value: string } }) => setZac(event.target.value)}
            handleClear={() => setZac("")}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    currentProjectFavFilter: state.report.currentProjectFavFilter,
    projectCurrentPage: state.report.projectCurrentPage,
    currentTab: state.report.currentTab,
    projectFilters: state.report.projectFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListFilters);
