import React from "react";
import { useTranslation } from "react-i18next";
import { SzCardCollapse, SzCheckbox } from "react-theme-components";
import { filterTypes } from "../../config/constants";
import { IFilter } from "../../store/redux/types";
import "./filters.scss";

interface IFilterRender {
  facets: IFilter[];
  isChecked: (category: string, subCategory?: string, prefix?: string, type?: string) => boolean;
  onChange: (event: any, category: string, subcategory: string, filterType?: string) => void;
  resetCategoryFilter: (category: string) => void;
  disabled?: boolean;
  prefix: string;
}

interface ISubCategoryParam {
  key: string;
  doc_count: number;
}

function FiltersRender(props: IFilterRender) {
  const { t } = useTranslation();
  const { onChange, isChecked, resetCategoryFilter, facets, disabled, prefix } = props;

  return (
    <>
      {Object.entries(facets)
        .filter((facet: [string, IFilter]) => facet[0] !== "_index")
        .map((facet: any, index: number) => {
          const mainCategory = facet[0];
          const subCategories = facet[1];

          const header = (
            <>
              <span className="gaia-search-filters__category-title">{t(`filters:${mainCategory}`)}</span>
            </>
          );

          const body = (
            <>
              {subCategories.buckets && subCategories.buckets.length ? (
                <ul className="gaia-search-filters__subcategory">
                  <span
                    className={isChecked(`${prefix}${mainCategory}`) ? "reset-button active" : "reset-button"}
                    onClick={() => isChecked(`${prefix}${mainCategory}`) ? resetCategoryFilter(`${prefix}${mainCategory}`) : ""}
                  >
                    {t("filters:reset")}
                  </span>
                  {subCategories.buckets.map((subCategory: ISubCategoryParam, indexSubCategory: number) => {
                    return (
                      <li className="gaia-search-filters__subcategory-item" key={indexSubCategory}>
                        <SzCheckbox
                          label={t(`filters:${subCategory.key}`) + ` (${subCategory.doc_count})`}
                          type="radio"
                          checked={isChecked(`${prefix}${mainCategory}`, subCategory.key, prefix, filterTypes.facet)}
                          name={t(`filters:${mainCategory}`)}
                          onChange={(event: any) => onChange(event, `${prefix}${mainCategory}`, subCategory.key)}
                          className="radio-filter"
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : (
                  <ul className="gaia-search-filters__subcategory">
                    <li className="gaia-search-filters__subcategory-item">{t("no-facets-available")}</li>
                  </ul>
                )}
            </>
          );
          return (
            <div
              className={
                subCategories.buckets && subCategories.buckets.length
                  ? "gaia-search-filters__category"
                  : "gaia-search-filters__no-subcategories"
              }
              key={index}
            >
              <SzCardCollapse
                header={header}
                body={body}
                isDisabled={disabled}
                isActive={isChecked(`${prefix}${mainCategory}`)}
              />
            </div>
          );
        })}
    </>
  );
}

export default FiltersRender;
