import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { categories } from "../../../config/constants";
import { ISearch } from "../../../store/redux/types";
import Filters from "../../Filters";
import CountMessage from "../../Utils/messages/countMessage";
import NoResults from "../../Utils/messages/noResults";
import AssetItem from "./AssetItem";

interface IAssetProps {
  result: any;
  handleClick: any;
  handleTargetClick: any;
  search?: ISearch;
}

function Asset(props: IAssetProps) {
  const { t } = useTranslation();
  const { search, result } = props;
  const [selectedAnchor, setSelectedAnchor] = useState(props.search ? props.search.detail.shownDetailAnchor : "");

  const handleClick = (feature: any, tab: string) => {
    if (props.search) {
      props.search.detail.shownDetailCategory = categories.ASSET;
      props.search.detail.shownDetailId = feature["asset:layer_id"];

      /*if (feature["3dnetwork:3d_mesh_available"] || feature["3dstrucuture:3d_ifc_available"]) {
        props.search.flag.showSmartshape = feature["3dnetwork:3d_mesh_available"] || feature["3dstrucuture:3d_ifc_available"];
      } else {
        props.search.flag.showSmartshape = false;
      }*/
      const itemName = t(feature["index:name"]) + " " + feature["asset:id_sig"];
      const name = t("category.asset");
      props.handleClick(feature["asset:layer_id"], props.search, itemName, name, tab,feature["index:name"]);
    }
  };

  const handleZoom = (feature: any, index: number, anchor: string) => {
    props.handleTargetClick(feature, index);
    setSelectedAnchor(anchor);
  };

  return (
    <Row className="category-tab gaia-search__main-tab-result">
      {search && result.data.facets && (
        <Col sm={5} className="no-padding category-filters">
          <Filters facets={result.data.facets} filters={result.data.filters} searchCategory={categories.ASSET} />
        </Col>
      )}
      <Col sm={result.data.facets ? 7 : 12} className="no-padding category-tab">
        {search && (
          <CountMessage
            maxResult={search.maxResult}
            nbResult={props.result.data.objects.length}
            total={props.result.data.totalCount}
          />
        )}
        <ul className="gaia-search-data">
          {props.result.data.objects.length > 0 &&
            props.result.data.objects.map((feature: any, index: any) => {
              const anchor = `${feature["index:name"]}-${feature["asset:layer_id"]}`;
              return (
                <AssetItem
                  key={`${anchor === selectedAnchor}-${index}`}
                  anchor={anchor}
                  address={feature["sig:adressecomplete"]}
                  id={feature["asset:id_sig"]}
                  index={index}
                  indexName={feature["index:name"]}
                  handleClick={handleClick}
                  handleZoom={handleZoom}
                  feature={feature}
                  selected={anchor === selectedAnchor}
                />
              );
            })}
          {props.result.data.objects.length === 0 && (
            <>
              <li>
                <NoResults />
              </li>
            </>
          )}
        </ul>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state: any, props: IAssetProps) => {
  return { search: state.search, ...props };
};

export default connect(mapStateToProps)(Asset);
