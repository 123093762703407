import i18next from "i18next";
import React from "react";
import { Nav, Tab } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import SiteIndustrielDocuments from "./siteIndustrielDocuments";
import SiteIndustrielSamplingPoint from "./siteIndustrielSamplingPoint";
import SiteIndustrielSynthesis from "./siteIndustrielSynthesis";

interface IDetailContainer {
  shownItem: any;
}

const SiteIndustrielDetailContainer = (props: IDetailContainer) => {

  const { shownItem } = props;
  const { t } = useTranslation();
  const itemId = shownItem.id || 0;

  const handleSelect = (eventKey: string, test: any) => {
    ReactGA.event({
      action: `Voir onglet: ${eventKey} pour l'objet ${itemId} `,
      category: "Search",
    });
  };

  const getDistinctConnections = (data: any) => {
    return data.filter((a: any, i: any) => data.findIndex((s: any) => a.NOMEXPLO === s.NOMEXPLO) === i);
  };

  return (<>
    <div className={"siteIndustriel"}>
      <div className={"gaia-detail-result--header siteIndustriel-header"}>
        <h1 className="gaia-detail-result--header--primary-title primary-title">
          {shownItem.NOMETAB}
        </h1>
        <h3 className="gaia-detail-result--header--secondary-title secondary-title">
          {shownItem.ADRESSE}
        </h3>
      </div>
      <Tab.Container id="gaia-category" defaultActiveKey="synthesis">
        <Nav className="sz-aside__list gaia-detail-result--nav" onSelect={handleSelect}>
          <Nav.Item key={0} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"synthesis"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.synthesis`)}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item key={1} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"samplingPoint"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.samplingPoint`)}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item key={2} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"documents"}>
              <span className="gaia-search-category__item-title">{t(`report:tabs.documments`)}</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane key={0} eventKey={"synthesis"}>
            <SiteIndustrielSynthesis shownItem={shownItem} />
          </Tab.Pane>
          <Tab.Pane key={0} eventKey={"samplingPoint"}>
            <SiteIndustrielSamplingPoint
              shownItem={shownItem}
              connections={getDistinctConnections(shownItem.branchements)}
            />
          </Tab.Pane>
          <Tab.Pane key={1} eventKey={"documents"}>
            <SiteIndustrielDocuments
              documents={shownItem.documents}
              connectionDocuments={shownItem.connectionDocuments}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </>
  );
};

export default SiteIndustrielDetailContainer;
