import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzDatePicker, SzInput, SzSelect } from "react-theme-components";
import { bindActionCreators } from "redux";
import {
  calculateCityCentroid,
  cities, citiesBoundingBoxes,
  signalCategories,
  signalOrigins,
  signalStatuses,
} from "../../../config/report";
import { defaultProperties } from "../../../manager/map/config";
import { goToHome, zoomToCoordinates } from "../../../manager/map/navigation";
import { reportTabs } from "../../../pages/Report";
import { Report as ReportService } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IProjectFilter,
  IReportData,
  ISignalFilter,
} from "../../../store/redux/types";
import AutoCompleteInput from "../../Utils/AutoCompleteInput/AutoCompleteInput";
import ListHeader from "./ListHeader";

const SignalListFilters = (props: any): ReactElement => {

  const { t } = useTranslation();

  const autoCompleteMinLength = 3;
  const [autocompleteOptions, setAutocompleteOptions] = useState([""]);

  const signalStatusesOptions = Object.entries(signalStatuses).map((item: any) => {
    return { label: t(`report:signalFilter.status.${item[1]}`), value: item[1] };
  });

  const signalCategoriesOptions = Object.entries(signalCategories).map((item: any) => {
    return { label: t(`report:signalFilter.category.${item[1]}`), value: item[1] };
  });

  const signalOriginOptions = Object.entries(signalOrigins).map((item: any) => {
    return { label: t(`report:signalFilter.origin.${item[1]}`), value: item[1] };
  });

  const cityOptions = Object.entries(cities).map((item: any) => {
    return { label: item[1], value: item[1] };
  });

  const [localAddress, setAddress] = useState<string>(props.signalFilters.address);
  const [localCategory, setCategory] = useState<any[]>(props.signalFilters.category);
  const [localStatus, setStatus] = useState<any[]>(props.signalFilters.status);
  const [localCity, setCity] = useState<any[]>(props.signalFilters.city);
  const [localOrigin, setOrigin] = useState<any[]>(props.signalFilters.origin);
  const [startDate, setStartDate] = useState<Date | null>(props.signalFilters.startDate);
  const [endDate, setEndDate] = useState<Date | null>(props.signalFilters.sndDate);

  const updateDate = (newDate: Date, startOrEnd: string) => {
    if (startDate && endDate) {
      if ("start" === startOrEnd && newDate.getTime() <= endDate.getTime()) {
        setStartDate(newDate);
      } else if (newDate.getTime() >= startDate.getTime()) {
        setEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (props.signalFilters.address) {
      setAddress(props.signalFilters.address);
    }
    if (props.signalFilters.startDate) {
      if (typeof props.signalFilters.startDate !== "object") {
        const startingDate = new Date(props.signalFilters.startDate);
        setStartDate(startingDate);
      }
    }
    if (props.signalFilters.endDate) {
      if (typeof props.signalFilters.endDate !== "object") {
        const endingDate = new Date(props.signalFilters.endDate);
        setEndDate(endingDate);
      }
    }
    if (props.signalFilters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.signalFilters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.signalFilters.status) {
      const statusValues = signalStatusesOptions.filter((item: any) => {
        return props.signalFilters.status.includes(item.value);
      });
      setStatus(statusValues);
    }
    if (props.signalFilters.category) {
      const categoryValues = signalCategoriesOptions.filter((item: any) => {
        return props.signalFilters.category.includes(item.value);
      });
      setCategory(categoryValues);
    }
    if (props.signalFilters.origin) {
      const originValues = signalOriginOptions.filter((item: any) => {
        return props.signalFilters.origin.includes(item.value);
      });
      setOrigin(originValues);
    }
  }, [props.currentSignalFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  const isFiltersEmpty = () => {
    return ((!localCity || localCity.length === 0) &&
      (!localStatus || localStatus.length === 0) &&
      (!localCategory || localCategory.length === 0) &&
      (!localOrigin || localOrigin.length === 0) &&
      (!localAddress || localAddress === "") &&
      (!startDate) && (!endDate));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());

  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localAddress,
    localCategory,
    localOrigin,
    localStatus,
    localCity,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (areFiltersEmpty) {
      resetAction();
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    const filters: ISignalFilter = {
      address: localAddress,
      category: localCategory.map((item) => item.value),
      origin: localOrigin.map((item) => item.value),
      status: localStatus.map((item) => item.value),
      city: localCity.map((item) => item.value),
      startDate,
      endDate,
    };
    if (filters.city && filters.city.length === 1) {
      const cityValue = Object.entries(cities).filter((item: any) => {
        if (filters.city && filters.city.length === 1 && item[1] === filters.city[0]) {
          return item[0];
        }
      });
      const coordinates = calculateCityCentroid(citiesBoundingBoxes[cityValue[0][0]].extent);
      props.setReportMapPosition([coordinates.latitude, coordinates.longitude, 10000]);
      if (props.currentTab === reportTabs.map) {
        zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
      }
    } else if (filters.city && filters.city.length > 1) {
      props.setReportMapPosition(mapOrigin);
      if (props.currentTab === reportTabs.map) {
        zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
      }
    } else if (filters.address && (
      (!filters.city || filters.city.length === 0) &&
      (!filters.status || filters.status.length === 0) &&
      (!filters.category || filters.category.length === 0) &&
      (!filters.origin || filters.origin.length === 0)
    )
    ) {
      SearchService.getAddressCoordinates(filters.address).then((res: any) => {
        if (res.coordinates && res.cityCode.match(/92/g)) {
          props.setReportMapPosition([res.coordinates[1], res.coordinates[0], defaultProperties.scale]);
          if (props.currentTab === reportTabs.map) {
            zoomToCoordinates(res.coordinates[1], res.coordinates[0]);
          }
        } else {
          props.setReportMapPosition(mapOrigin);
          if (props.currentTab === reportTabs.map) {
            zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
          }
        }

      });
    }
    props.updateSignalFilters(filters);
  };

  const resetAction = () => {
    setAddress("");
    setCategory([]);
    setOrigin([]);
    setStatus([]);
    setCity([]);
    setStartDate(null);
    setEndDate(null);
    const filters: ISignalFilter = {
      address: "",
      category: [],
      origin: [],
      status: [],
      city: [],
      startDate: null,
      endDate: null,
    };
    props.updateSignalFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset signal filters`, category: "Report" });
  };

  const autocompleteAddress = (address: string) => {
    return ReportService.getSignalAddressAutocomplete(address)
      .then((data: Array<{ address: string }>) => {
        const options = data.map((item: { address: string }) => {
          return item.address;
        });
        setAutocompleteOptions(options);
      });
  };
  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:signalFilter.category.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary category"
            isSearchable
            isClearable
            isMulti
            id="category"
            name="category"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCategory);
            }}
            options={signalCategoriesOptions}
            value={localCategory}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:signalFilter.status.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary status"
            isSearchable
            isClearable
            isMulti
            id="status"
            name="status"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setStatus);
            }}
            options={signalStatusesOptions}
            value={localStatus}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:address")}</span>
          </div>
          <AutoCompleteInput
            autoCompleteOptions={autocompleteOptions}
            className={"address"}
            debounce={500}
            handler={setAddress}
            placeholder={""}
            minLength={autoCompleteMinLength}
            searchHandler={autocompleteAddress}
            value={localAddress}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:signalFilter.date")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            label={"date"}
            endDate={endDate}
            endMinDate={null}
            startDate={startDate}
            startMinDate={null}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:signalFilter.origin.label")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary origin"
            isSearchable
            isClearable
            isMulti
            id="origin"
            name="origin"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setOrigin);
            }}
            options={signalOriginOptions}
            value={localOrigin}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    currentSignalFavFilter: state.report.currentSignalFavFilter,
    signalCurrentPage: state.report.signalCurrentPage,
    currentTab: state.report.currentTab,
    signalFilters: state.report.signalFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignalListFilters);
