import React from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";

interface IIntervention {
    "libelleActe": string;
    "libelleFamilleIntervention": string;
    "numeroIntervention": string;
    "dateFinIntervention": string;
}

const Curage = (props: { interventions?: IIntervention[] }) => {

    function compareInterventions(a: IIntervention, b: IIntervention) {
        return new Date(b.dateFinIntervention).getTime() - new Date(a.dateFinIntervention).getTime();
    }

    const { t } = useTranslation();

    if (props.interventions) {
        const orderedInterventions: IIntervention[] = props.interventions.sort(compareInterventions);

        if (orderedInterventions && orderedInterventions[0]) {
            return (
                <>
                    <Table size="sm">
                        <tbody>
                            <tr>
                                <td className="gaia-td-align-right cell-width">
                                    {t("asset:intervention.type")}:
                                </td>
                                <td className="gaia-td-align-left cell-width">
                                    {orderedInterventions[0].libelleActe.includes("Erreur") ?
                                    t("no-data") :
                                    decodeURIComponent(escape(orderedInterventions[0].libelleActe.replace(/ÃŽ/g, "o")))
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="gaia-td-align-right cell-width">
                                    {t("asset:intervention.family")}:
                                </td>
                                <td className="gaia-td-align-left cell-width">
                                    {decodeURIComponent(
                                        escape(orderedInterventions[0].libelleFamilleIntervention.replace(/ÃŽ/g, "o")),
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="gaia-td-align-right cell-width">
                                    {t("asset:intervention.curageDate")}:
                                </td>
                                <td className="gaia-td-align-left cell-width">
                                    {new Date(orderedInterventions[0].dateFinIntervention).toLocaleDateString()}
                                </td>
                            </tr>
                            <tr>
                                <td className="gaia-td-align-right cell-width">
                                    {t("asset:intervention.intervenant")}:
                                </td>
                                <td className="gaia-td-align-left cell-width">
                                    {t("no-data")}
                                </td>
                            </tr>
                            <tr>
                                <td className="gaia-td-align-right cell-width">{t("asset:intervention.number")}:</td>
                                <td className="gaia-td-align-left cell-width">
                                    {orderedInterventions[0].numeroIntervention}
                                </td>
                            </tr>
                            <tr>
                                <td className="gaia-td-align-right cell-width">
                                    {t("asset:intervention.quantity")}:
                                </td>
                                <td className="gaia-td-align-left cell-width">
                                    {t("no-data")}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </>
            );
        }
    }
    return <DataNotReady />;
};

export default Curage;
